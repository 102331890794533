<div class="container-fluid">
  <h1 class="page-header">Payments Past Due Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <div class="col-3">
          <mat-form-field>
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="picker_daterange_start_end">
                  <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
                  <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
              </mat-date-range-input>
              <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle matSuffix [for]="picker_daterange_start_end"></mat-datepicker-toggle>
              <mat-date-range-picker #picker_daterange_start_end></mat-date-range-picker>
          </mat-form-field>
      </div>

        <!-- Enrollment Autocomplete -->
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="field_lesson_type" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>

      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">


      <!-- field_payment_date_value Column -->
      <ng-container matColumnDef="field_payment_date_value">
        <th mat-header-cell mat-sort-header *matHeaderCellDef sortActionDescription="Sort by field_payment_date_value">
          <!-- field_payment_date_value -->
          Due Date
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_payment_date | date}} </td>
      </ng-container>

      <!-- field_student_target_id Column -->
      <ng-container matColumnDef="field_student_target_id">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_target_id">
          Student
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.title_2 }} </td>
      </ng-container>

      <!-- enrollment_title Column -->
      <ng-container matColumnDef="title">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by title">
          Enrollment
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.enrollment_title }} </td>
      </ng-container>

      <!-- field_balance_due_value Column -->
      <ng-container matColumnDef="field_balance_due_value">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_balance_due_value">
          Total Balance
          <!-- Amount Due Payment Amount -->
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_enrollment_total_price }} </td>
      </ng-container>

      <!-- field_enrollment_lesson_count Column -->
      <ng-container matColumnDef="field_lesson_available_value">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_available_value">
          Lessons Remaining
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_lesson_available }} </td>
      </ng-container>

      <!-- Amount Due Column -->
      <ng-container matColumnDef="field_balance_due">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_available_value">
          Remaining Balance
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_balance_due }} </td>
      </ng-container>

      <!-- Amount Paid Column -->
      <ng-container matColumnDef="field_total_payments_made">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_lesson_available_value">
          Amount Due
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_total_payments_made }} </td>
      </ng-container>

      <!-- field_instructor_target_id Column -->
      <ng-container matColumnDef="field_instructor_target_id">
        <th mat-sort-header mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_instructor_target_id">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name }} {{ element.field_last_name }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
