<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
        class="mt-4">

        <!-- enrollmentID Column -->
        <ng-container matColumnDef="enrollmentID">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by enrollmentID">
                ID
            </th>
            <td mat-cell *matCellDef="let element"> {{element.id}}
            </td>
        </ng-container>

        <!-- field_contacts Column -->
        <ng-container matColumnDef="field_contacts">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_contacts">
                Student
            </th>
            <td mat-cell *matCellDef="let element"> {{element.field_contacts}}
            </td>
        </ng-container>

        <!-- field_sale_date Column -->
        <ng-container matColumnDef="field_sale_date">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_sale_date">
                Sale Date
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_sale_date
                }} </td>
        </ng-container>

        <!-- field_instructor Column -->
        <ng-container matColumnDef="field_instructor">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_instructor">
                Instructor
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_instructor
                }} </td>
        </ng-container>

        <!-- field_category Column -->
        <ng-container matColumnDef="field_category">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_category">
                Category
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_category }}
            </td>
        </ng-container>

        <!-- field_package_name Column -->
        <ng-container matColumnDef="field_package_name">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_package_name">
                Package Name
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_package_name }} </td>
        </ng-container>

        <!-- field_enrollment_lesson_count Column -->
        <ng-container matColumnDef="field_enrollment_lesson_count">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_enrollment_lesson_count">
                # of Lessons
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_enrollment_lesson_count }} </td>
        </ng-container>

        <!-- field_enrollment_total_price Column -->
        <ng-container matColumnDef="field_enrollment_total_price">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_enrollment_total_price">
                Total Price
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_enrollment_total_price }} </td>
        </ng-container>

        <!-- field_total_paid Column -->
        <ng-container matColumnDef="field_total_paid">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_total_paid">
                Total Paid
            </th>
            <td mat-cell *matCellDef="let element"> <ng-container *ngIf="element.field_total_payments_made">${{ element.field_total_payments_made }}</ng-container> </td>
        </ng-container>

        <!-- field_additional_notes Column -->
        <ng-container matColumnDef="field_additional_notes">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_additional_notes">
                Last Paid
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_last_paid_date != "" ? (element.field_last_paid_date | date) : element.field_additional_notes | date }} </td>
        </ng-container>

        <!-- field_lesson_available Column -->
        <ng-container matColumnDef="field_lesson_available">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_lesson_available">
                Lessons Available
            </th>
            <td mat-cell *matCellDef="let element">
                {{ element.field_lesson_available }} </td>
        </ng-container>

        <!-- field_lesson_available Column -->
        <ng-container matColumnDef="isDropped">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_lesson_available">
                Drop
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_dropped_date ? '✓' : ''  }} </td>
        </ng-container>

        <mat-menu #enrollmentMenu="matMenu">
            <ng-template matMenuContent let-enrollmentID="enrollmentID" let-enrollment="element">
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', '0', enrollmentID)"
                    >
                    View
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'delete', enrollmentID)"
                    >
                    Delete
                </button>
                <button mat-menu-item
                    (click)="openEntityComponent(EnrollmentDropComponent, 'packages', 'enrollment', 'drop', enrollmentID)"
                    >
                    Drop
                </button>
            </ng-template>
        </mat-menu>

        <!-- nothing_1 Column -->
        <ng-container matColumnDef="nothing_1">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by nothing_1">
                <!-- Actions -->
                View
            </th>
            <td mat-cell *matCellDef="let element">
                <button mat-flat-button color="accent"
                (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'view', element.id)">
                View
                </button>
            </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator
        (page)="pageChanged($event)"
        [pageSizeOptions]="onPageSizeOptions()"
        [length]="30"
        [pageSize]="paginationTotalPages"
        [pageIndex]="paginationIndex"
        aria-label="Select page of view"></mat-paginator>

</div>
