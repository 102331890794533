<app-entity-mat-dialog-title title="Payment"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group class="paymentFormTabs" [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab label="{{
                (this.initActionType == 'create' ? 'Create'
                : this.initActionType == 'edit' ? 'Edit'
                : this.initActionType == 'refund' ? 'Refund'
                : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <!-- Display a message saying it's already been refunded and can't be refunded twice. -->
                <ng-container *ngIf="this.entityData?.field_status == 'Refund' && this.initActionType == 'refund'">
                    <app-success-message message="This payment has already been refunded."></app-success-message>
                </ng-container>

                <form [formGroup]="paymentForm" (ngSubmit)="onSubmitPayment(f)" #f="ngForm" class="form">
                    <div class="container">
                        <div class="row">

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Payment ID</mat-label>
                                    <input
                                    [ngModel]="this.paymentID"
                                    formControlName="field_payment_id" name="field_payment_id" matInput>
                                </mat-form-field>
                            </div>

                            <!-- <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Customer Name</mat-label>
                                    <input formControlName="field_customer_name" name="field_customer_name" matInput>
                                </mat-form-field>
                            </div> -->

                            <!-- Sale Date || Refund Date -->
                            <div class="col-6">
                                <mat-form-field>
                                    <mat-label>{{
                                        (this.initActionType == 'create' ? 'Payment'
                                        : this.initActionType == 'edit' ? 'Payment'
                                        : this.initActionType == 'refund' ? 'Refund'
                                        : 'Edit')}} Date</mat-label>
                                <input
                                ngModel
                                formControlName="field_date_and_time"
                                name="field_date_and_time"
                                matInput
                                [ngxMatDatetimePicker]="picker_field_date_time_payment_form"
                                placeholder="">
                                <mat-datepicker-toggle matSuffix [for]="picker_field_date_time_payment_form">
                                </mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker_field_date_time_payment_form
                                    [startAt]="todaysDate"
                                    [hideTime]="true"
                                    [enableMeridian]="true"
                                    [showSpinners]="true"
                                    [showSeconds]="false"
                                    [stepHour]="1" [stepMinute]="15"
                                    [touchUi]="false">
                                </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>

                            <!-- Payment Amount || Refund Amount -->
                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>{{
                                        (this.initActionType == 'create' ? 'Payment'
                                        : this.initActionType == 'edit' ? 'Payment'
                                        : this.initActionType == 'refund' ? 'Refund'
                                        : 'Edit')}} Amount</mat-label>
                                    <input type="text" formControlName="field_gross_tuition" name="field_gross_tuition" matInput>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Student Name</mat-label>
                                    <input
                                        [readonly]="this.initActionType == 'refund'"
                                        formControlName="field_student_name"
                                        name="field_student_name"
                                        (input)="onAutocompleteStudent($event)"
                                        matInput
                                        (ngModelChange)="onStudentNameSelectThenLoadEnrollmentName($event, true);onStudentNameSelectThenLoadStripePaymentMethods($event);"
                                        #field_student_name
                                        [matAutocomplete]="autoCompleteStudent"
                                        >
                                    <button type="button" matSuffix mat-icon-button
                                        aria-label="manage_accounts">
                                        <mat-icon>manage_accounts</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-autocomplete #autoCompleteStudent="matAutocomplete"
                                    (optionSelected)="onStudentNameSelectThenLoadEnrollmentName($event, true)">
                                    <mat-option
                                        *ngFor="let option of autoCompleteStudentOptions"
                                        [value]="option.label + ' (' + option.value + ')'">
                                        {{option.label}} ({{option.value}})
                                    </mat-option>
                                </mat-autocomplete>
                            </div>

                            <!-- <input
                                class=""
                                type="text"
                                [(ngModel)]="field_enrollment_name"
                                name="field_enrollment_name"
                                > -->

                            <!-- Enrollment Dropdown -->
                            <div *ngIf="!(this.initActionType == 'create')" formGroupName="field_enrollment_name" class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Enrollment Name</mat-label>
                                    <mat-select
                                        [attr.disabled]="this.initActionType == 'refund' ? 'disabled' : null"
                                        matInput
                                        (ngModelChange)="onEnrollmentSelectThenLoadScheduledPayments($event)"
                                        formControlName="target_id"
                                        name="target_id"
                                        >
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of enrollmentsForUser"
                                            [value]="element.id[0].value">
                                            {{ element.title[0]?.value }}
                                            (Available: {{ element.field_lesson_available[0]?.value }},
                                                Price: {{ element.field_enrollment_lesson_price[0]?.value }},
                                                Paid: {{ element.field_total_paid[0]?.value }},
                                                Due: {{ element.field_balance_due[0]?.value }})
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <!-- Support for multiple enrollments in a single payment -->
                            <!-- [disabled]="!enrollmentForm.get('field_instructor_percentages').valid" -->
                            <div class="full-width-field">
                                <div *ngIf="(this.initActionType == 'create')" formArrayName="field_enrollment_name" style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius: 10px;">
                                    <h3>Enrollment(s)</h3>
                                    <ng-container *ngIf="this.paymentForm.controls['field_enrollment_name']">
                                        <div *ngFor="let enrollmentControl of paymentForm.get('field_enrollment_name').controls; let i = index">
                                            <div [formGroupName]="i" class="row">
                                                <div class="col-11">
                                                    <mat-form-field class="full-width-field">
                                                        <mat-label>Enrollment Name</mat-label>
                                                        <mat-select
                                                            matInput
                                                            (ngModelChange)="onEnrollmentSelectThenLoadScheduledPayments($event)"
                                                            formControlName="target_id"
                                                            name="target_id"
                                                            >
                                                            <mat-option>None</mat-option>
                                                            <mat-option
                                                                *ngFor="let element of enrollmentsForUser"
                                                                [value]="element.id[0].value">
                                                                {{ element.title[0]?.value }}
                                                                (Available: {{ element.field_lesson_available[0]?.value }},
                                                                    Price: {{ element.field_enrollment_lesson_price[0]?.value }},
                                                                    Paid: {{ element.field_total_paid[0]?.value }},
                                                                    Due: {{ element.field_balance_due[0]?.value }})
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>

                                                <div class="col-1">
                                                    <div>
                                                        <mat-icon
                                                            class="me-2 mt-2"
                                                            (click)="enrollmentPaymentRemove(i)"
                                                            aria-hidden="false"
                                                            aria-label="trash">cancel</mat-icon>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <button type="button"
                                        mat-flat-button
                                        class="enrollment me-1"
                                        (click)="enrollmentPaymentIncrement()">
                                        Add Enrollment
                                    </button>
                                </div>
                            </div>

                            <!-- Scheduled Payments Dropdown; Kept here for testing purposes. -->
                            <!-- <div formGroupName="field_scheduled_payment" class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Scheduled Payment</mat-label>
                                    <mat-select
                                        matInput
                                        formControlName="target_id"
                                        name="target_id"
                                        >
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of field_scheduled_payments"
                                            [value]="element.id">
                                            {{ element.title }} -- {{element.id}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div> -->

                            <!-- Payment Type Dropdown -->
                            <div class="col-12 mt-3">
                                <!-- <mat-form-field class="full-width-field">
                                    <mat-label>Payment Type</mat-label>
                                    <mat-select matInput name="field_payment_type" formControlName="field_payment_type">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._taxonomyService.payment_type;"
                                            [value]="element.id">
                                            {{ element.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field> -->
                            </div>

                            <!-- Payment Type Dropdown -->
                            <!-- <div class="full-width-field">
                                <mat-radio-group class="row" name="field_payment_type" formControlName="field_payment_type" aria-label="Select an option">
                                    <ng-container *ngFor="let element of this._taxonomyService.payment_type">
                                        <div class="col-4">
                                            <mat-radio-button [value]="element.id">{{ element.name }}</mat-radio-button>
                                        </div>
                                    </ng-container>
                                </mat-radio-group>
                            </div> -->

                            <div class="full-width-field">
                                <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius:10px;">
                                    <label id="example-radio-group-label"><h3>Payment Method<!--Payment Type--></h3></label>
                                    <!-- <div class="col-12 mb-3">
                                        <button
                                        type="button"
                                        class="me-1"
                                        mat-flat-button
                                        [color]="this.paymentForm?.value?.field_payment_type == '71' ? 'accent' : 'basic'">Cash Card</button>
                                        <button
                                        type="button"
                                        class="me-1"
                                        mat-flat-button
                                        [color]="this.paymentForm?.value?.field_payment_type == '661' ? 'accent' : 'basic'">Check</button>
                                        <button
                                        type="button"
                                        class="me-1"
                                        mat-flat-button
                                        (click)="this.paymentForm.value.field_payment_type = '1407'"
                                        [color]="this.paymentForm?.value?.field_payment_type == '1407' ? 'accent' : 'basic'">Stripe</button>
                                    </div> -->
                                    <mat-radio-group class="row" name="field_payment_type" formControlName="field_payment_type" aria-label="Select an option">
                                        <ng-container *ngFor="let element of this._taxonomyService.payment_type">
                                            <div class="col-4">
                                                <mat-radio-button [value]="element.id">{{ element.name }}</mat-radio-button>
                                            </div>
                                        </ng-container>
                                    </mat-radio-group>

                                    <div class="col-12 full-width-field">
                                        <!-- Stripe Integration -->
                                        <ng-container *ngIf="this.paymentForm?.value?.field_payment_type == '1407' && this.initActionType != 'refund'">
                                            <div class="row pr-5 pl-5" style="padding-left:33px;padding-right:33px;">
                                                <mat-radio-group formControlName="field_payments_use_card">
                                                    <ng-container>
                                                        <ng-container *ngFor="let element of payment_methods">
                                                            <mat-radio-button
                                                            name="field_payment_type" [value]="element.id" class="stripe_saved_payments col-12 mt-1 pt-1 pb-1 ps-2 display-edit-field">
                                                                <div class="container">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            <mat-icon *ngIf="element.brand == 'MasterCard'" fontSet="fa-brands" fontIcon="fa-cc-mastercard" style="color:#000;font-size: 24px;"></mat-icon>
                                                                            <mat-icon *ngIf="element.brand == 'Visa'" fontSet="fa-brands" fontIcon="fa-cc-visa" style="color:#000;font-size: 24px;"></mat-icon>
                                                                            <mat-icon *ngIf="element.brand == 'Amex'" fontSet="fa-brands" fontIcon="fa-cc-amex" style="color:#000;font-size: 24px;"></mat-icon>
                                                                            <mat-icon *ngIf="element.brand == 'Discover'" fontSet="fa-brands" fontIcon="fa-cc-discover" style="color:#000;font-size: 24px;"></mat-icon>
                                                                        </div>
                                                                        <div class="col-7" style="padding-top:3px;">
                                                                            {{element.brand}} ending in <strong>{{element.last_4}}</strong>
                                                                        </div>
                                                                        <div class="col-2" style="padding-top:3px;">
                                                                            {{element.exp_month}}/{{element.exp_year}}
                                                                        </div>
                                                                    </div>

                                                                    <div class="row">
                                                                        <div class="text-end">
                                                                            <button (click)="delete_stripe_payment_method(element.id);" style="background:none; border:none;" type="button" class="btn-link">Delete</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </mat-radio-button>
                                                        </ng-container>
                                                    </ng-container>
                                                    <mat-radio-button [value]="'add_new_card'" class="stripe_saved_payments col-12 mt-1 pt-1 pb-1 ps-2 display-edit-field">
                                                        <div class="container">
                                                            <div class="row">
                                                                <div class="col-3">
                                                                    <!-- <mat-icon fontSet="fa-solid" fontIcon="fa-plus" style="color:#000;font-size: 24px;"></mat-icon> -->
                                                                </div>
                                                                <div class="col-9" style="padding-top:3px;">
                                                                    Add New Card
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                                <div class="col-6">
                                                        <mat-form-field class="full-width-field">
                                                            <input
                                                            [ngModel]=this.cc_data?.cc_number
                                                            formControlName="cc_number"
                                                            (change)="this.cc_data.cc_card_type = getCardType($event.target.value); paymentForm.controls['field_payments_use_card'].setValue('add_new_card');"
                                                            name="cc_number"
                                                            matInput
                                                            placeholder="1111 1111 1111 1111"
                                                            >
                                                            <mat-label><mat-icon>credit_card</mat-icon> <b> Credit Card</b></mat-label>
                                                        </mat-form-field>
                                                </div>

                                                <div class="col-3">
                                                    <mat-form-field class="full-width-field">
                                                        <input
                                                        [ngModel]="this.cc_data?.cc_expiration"
                                                        (change)="paymentForm.controls['field_payments_use_card'].setValue('add_new_card');"
                                                        formControlName="cc_expiration"
                                                        name="cc_expiration"
                                                        matInput
                                                        placeholder="MM/YY"
                                                        >
                                                        <mat-label><mat-icon>date_range</mat-icon> <b> Expiration</b></mat-label>
                                                    </mat-form-field>

                                                    <!-- hidden fields -->
                                                    <input [ngModel]="this.cc_data?.cc_expiration_month" class="d-none" ngModel formControlName="cc_expiration_month" matInput>
                                                    <input [ngModel]="this.cc_data?.cc_expiration_year" class="d-none" ngModel formControlName="cc_expiration_year" matInput>
                                                </div>

                                                <div class="col-3">
                                                    <mat-form-field class="full-width-field">
                                                        <input
                                                        formControlName="cc_cvv"
                                                        name="cc_cvv"
                                                        matInput
                                                        placeholder="111"
                                                        >
                                                        <mat-label><mat-icon>lock</mat-icon> <b> CVV</b></mat-label>
                                                    </mat-form-field>
                                                </div>

                                                <!-- <div class="col-6">
                                                    <mat-form-field class="full-width-field">
                                                        <input
                                                        formControlName="cc_postal_code"
                                                        name="cc_postal_code"
                                                        matInput
                                                        placeholder="11111"
                                                        >
                                                        <mat-label><mat-icon>location_on</mat-icon> <b> Postal Code</b></mat-label>
                                                    </mat-form-field>
                                                </div> -->

                                                <div class="col-12" style="font-size: 40px; margin-bottom: 13px;" *ngIf="this.cc_data?.cc_card_type != ''">
                                                    <div>
                                                        <mat-icon *ngIf="this.cc_data?.cc_card_type == 'MasterCard'" fontSet="fa-brands" fontIcon="fa-cc-mastercard" style="color:#000"></mat-icon>
                                                        <mat-icon *ngIf="this.cc_data?.cc_card_type == 'Visa'" fontSet="fa-brands" fontIcon="fa-cc-visa" style="color:#000"></mat-icon>
                                                        <mat-icon *ngIf="this.cc_data?.cc_card_type == 'Amex'" fontSet="fa-brands" fontIcon="fa-cc-amex" style="color:#000"></mat-icon>
                                                        <mat-icon *ngIf="this.cc_data?.cc_card_type == 'Discover'" fontSet="fa-brands" fontIcon="fa-cc-discover" style="color:#000"></mat-icon>
                                                    </div>
                                                </div>

                                                <div class="col-3">
                                                    <button
                                                    class="mb-3"
                                                    mat-stroked-button color="primary"
                                                    type="button"
                                                    (click)="openCreditCardInputComponent(CreditCardReadInputComponent); paymentForm.controls['field_payments_use_card'].setValue('add_new_card');"
                                                    mat-button>
                                                    Capture
                                                </button>
                                                <!-- {{this.cc_data | json}} -->
                                                <!-- <button
                                                    type="button"
                                                    class="me-1 mb-3"
                                                        mat-flat-button
                                                        [color]="recurringMode == 'Days' ? 'accent' : 'basic'">Capture</button> -->
                                                </div>


                                            </div>
                                        </ng-container>

                                        <!-- Stripe Refund Integration -->
                                        <ng-container *ngIf="this.paymentForm?.value?.field_payment_type == 1407 && this.initActionType == 'refund' && this.entityData?.field_status != 'Refund'">
                                            <input ngModel class="d-none" formControlName="field_stripe_charge_id" value="this.entityData?.field_stripe_charge_id" matInput>
                                            <div class="col-6">
                                                <mat-form-field class="full-width-field">
                                                    <mat-label>Reason to refund</mat-label>
                                                    <mat-select matInput ngModel formControlName="reason_to_refund"
                                                        name="reason_to_refund">
                                                        <mat-option
                                                            *ngFor="let element of this._fieldsService.reasons_to_refund"
                                                            [value]="element.key">
                                                            {{ element.value }}</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </ng-container>
                                    </div>

                                </div>

                            </div>

                            <!-- {{this.paymentForm.value | json}} -->

                        </div>
                    </div>
                </form>

            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
                <ng-container>
                    <!-- Must specify a reason why they are deleting. -->
                    <form [formGroup]="paymentDeleteForm" (ngSubmit)="onSubmitDeleteForm(f)" #f="ngForm" class="form">
                        <div class="container">
                            <div class="row">
                                <div class="col-6">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Reason to delete</mat-label>
                                        <input type="text" formControlName="field_reason_to_delete" name="field_reason_to_delete" matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </form>
                </ng-container>

            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<div>
    <!-- <p> -->
    <!-- {{findInvalidControlsRecursive(paymentForm) | json}} -->
    <!-- </p> -->
    <!-- {{this.paymentForm.value | json}} -->
    <!-- paymentForm.valid: {{paymentForm.valid}} -->
    <!-- get why the form isn't valid. -->
    <!-- paymentForm.errors: {{paymentForm.errors | json}} -->
</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create' && paymentForm">
        <button [disabled]="!paymentForm.valid" type="button" (click)="onSubmitPayment(f);" mat-raised-button color="accent">{{this.paymentForm?.value?.field_payment_type == '1407' ? 'Run' : 'Save'}} Payment</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit' || this.actionType === 'refund'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save Payment</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save Payment</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'refund' && this.entityData?.field_status != 'Refund'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button [disabled]="!paymentForm.valid" type="button" (click)="onSubmitPayment(f);" mat-raised-button color="accent">Refund Payment</button>
            <button [disabled]="!paymentForm.valid" type="button" (click)="onSubmitPayment(f, true);" mat-raised-button color="accent">Refund Payment & Drop</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button [disabled]="!paymentDeleteForm.valid" (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
