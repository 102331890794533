import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  current_user: Object = {};
  csrf_token: string = "";
  logout_token: string = "";
  user_id: number;
  userAuthenticated: boolean = false;

  is_user_authenticated() {
    return this.userAuthenticated;
  }

  set_user_authenticated(boolean: boolean) {
    this.userAuthenticated = boolean;
  }
}
