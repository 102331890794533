import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-royalty-report',
  templateUrl: './royalty-report.component.html',
  styleUrls: ['./royalty-report.component.css']
})
export class RoyaltyReportComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'receipt_number',
    'date_paid',
    'student_name',
    'executive',
    'instructor',
    'sale_code',
    'package_name',
    'lesson_count',
    'total_price',
    'cash',
    'miscellaneous_services',
    'subject_to_royalty',
    'sundry',
    'total_receipts'
  ];

  currentReport: string = 'studioBusiness';
  week: string;
  year: string;
  royaltyPercentage;
  royaltyDataRefunds;

  override ngOnInit(): void {
    // Pull royalty percentage.
    this._fieldsService.getRoyaltyPercentageConfig().subscribe(data => {
      this.setRoyaltyPercentageBasedOnDate(data);
    });
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      // console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    })
  }

  override getData(params: { parameter: string; value: string; }[]) {
    // Disable buttons if it's the current week.
    let paramWeek = moment().day("Sunday").year(+this.year).week(+this.week).add(1, 'weeks');
    this.isDisabled = paramWeek.isAfter();

    this.clearFormTableData();

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getRoyaltyReport(
      this.queryParams
    )
      .subscribe((data) => {

        // ShowProgressBar
        this.ShowProgressBar = false;
        // console.log(data['#royalty_content_table']?.staff_data_4_system['#rows']);

        let rows = data['#royalty_content_table']?.staff_data_4_system['#rows'];

        // No rows? Don't continue...
        if (!rows) { return; }

        // Modify data to work in Angular.
        rows.forEach((element, index) => {
          if (element.student_name) {
            rows[index]['student_name'] = element.student_name.replace(/<[^>]*>?/gm, '');
          }
        });

        // Extract the refunds from the rows to be displayed seperately.
        this.filterRefunds(rows);

        this.data = data;
        this.dataSource = new MatTableDataSource(rows);

        // Recalculate royalty percentage based on the new data
        this._fieldsService.getRoyaltyPercentageConfig().subscribe(royaltyData => {
          this.setRoyaltyPercentageBasedOnDate(royaltyData);
        });

        // Pagination configuration.
        this.updatePaginationConfig(rows);
      },
        error => this.handleError(error)
      )
  }

  filterRefunds(rows) {
    this.royaltyDataRefunds = rows.filter(object => {
      // let isRefund = (+object.total_receipts.replace('$', '') < 0);
      let isRefund = (+object.total_receipts < 0);

      return (isRefund);
    });

    // console.log('refunds', this.royaltyDataRefunds);
  }

  calcRoyaltyPercentage(amount) {
    amount = this._filterDigits(amount+'');
    return (amount * (this.royaltyPercentage / 100));
  }

  setRoyaltyPercentageBasedOnDate(data) {
    // Extract start and end dates of the specified week.
    const startDate = moment().year(+this.year).isoWeek(+this.week).startOf('isoWeek').utc().startOf('day').toDate();
    const endDate = moment().year(+this.year).isoWeek(+this.week).endOf('isoWeek').utc().endOf('day').toDate();

    let foundMatchingPeriod = false;
    let lowestPercentage = Infinity;

    // Check if 'field_royalty_period' exists and has data.
    if (data.field_royalty_period && data.field_royalty_period.length > 0) {
      // Ensure 'field_royalty_period' is an array, or convert it into one.
      const periods = Array.isArray(data.field_royalty_period) ? data.field_royalty_period : [data.field_royalty_period];

      for (const period of periods) {
        // Extract period start and end dates and check if they exist.
        const periodStartDate = moment(period.field_start_date[0].value).utc().startOf('day').toDate();
        const periodEndDate = moment(period.field_end_date[0].value).utc().endOf('day').toDate();

        // If start and end dates exist, check for matching period.
        if (periodStartDate && periodEndDate && startDate >= periodStartDate && endDate <= periodEndDate) {
          const percentage = parseFloat(period.field_royalty_percentage[0].value);
          if (percentage < lowestPercentage) {
            lowestPercentage = percentage;
            this.royaltyPercentage = percentage;
            foundMatchingPeriod = true;
          }
        }
      }
    }
    // If no matching period is found, set the default royalty percentage
    if (!foundMatchingPeriod && data.field_royalty_percentage) {
      console.log('no matching period found, using default royalty percentage', data.field_royalty_percentage[0].value)
      this.royaltyPercentage = parseFloat(data.field_royalty_percentage[0].value);
    }
  }

}
