<div mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle >
    <h1>Appointment Details</h1>
    <button mat-button mat-dialog-close>
        <mat-icon aria-hidden="false" aria-label="Example home icon">close
        </mat-icon>
    </button>
</div>

<mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="Details">
            <div class="">
                <div class="">
                    <table class="">
                        <!-- <tr>
                    <td>
                        ID
                    </td>
                    <td>
                        {{this.injectedDialogData?.event?.id}}
                    </td>
                </tr> -->
                        <tr>
                            <td colspan="2" class="no-wrap">
                                <strong>Student:</strong> {{ this.injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentName }}
                            </td>
                            <td>
                                <strong class="text-nowrap">Appointment Type:</strong>
                            </td>
                            <td colspan="2">
                                {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.eventType }}
                            </td>
                        </tr>
                        <tr>
                            <td class="text-nowrap">
                                <strong>Instructor:</strong> {{this.injectedDialogData?.event?._def?.extendedProps?.instructorName}}
                            </td>
                            <td>
                            </td>

                            <td>
                                <strong class="text-nowrap">Account:</strong>
                            </td>

                            <td class="text-nowrap">
                                <div class="d-flex justify-content-between">
                                    <div style="width:52px" class="col-2 text-center">
                                        <div style="" class="summary-hero-appt-details">
                                            {{ this.injectedDialogData?.event?._def?.extendedProps?.studentInfo?.field_total_lessons_enrolled }}
                                        </div>
                                        <div class="text-center"><small>Enrolled</small></div>
                                    </div>

                                    <div style="width:52px"  class="col-2 text-center">
                                        <div style="" class="summary-hero-appt-details">
                                            {{ this.injectedDialogData?.event?._def?.extendedProps?.studentInfo?.field_total_lessons_taken_all }}
                                        </div>
                                        <div class="text-center"><small>Taken</small></div>
                                    </div>

                                    <div style="width:52px"  class="col-2 text-center">
                                        <div style="" class="summary-hero-appt-details">
                                            {{ this.injectedDialogData?.event?._def?.extendedProps?.studentInfo?.field_total_lessons_paid_ahead }}
                                        </div>
                                        <div class="text-center"><small>Remaining</small></div>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <!-- <tr>
                            <td>
                                &nbsp;
                            </td>
                            <td>
                                &nbsp;
                            </td>
                            <td class="text-nowrap">
                                <div class="d-flex justify-content-between">
                                    <div style="width:50px" class="col-2 text-center">
                                        <div style="border: 12px solid #68ff94;" class="summary-hero-appt-details">
                                            {{ 5 }}
                                        </div>
                                        <div class="text-center"><small>Enrolled</small></div>
                                    </div>

                                    <div style="width:50px"  class="col-2 text-center">
                                        <div style="border: 12px solid #68ff94;" class="summary-hero-appt-details">
                                            {{ 5 }}
                                        </div>
                                        <div class="text-center"><small>Taken</small></div>
                                    </div>

                                    <div style="width:50px"  class="col-2 text-center">
                                        <div style="border: 12px solid #FCC761;" class="summary-hero-appt-details">
                                            {{ 5 }}
                                        </div>
                                        <div class="text-center"><small>Remaining</small></div>
                                    </div>
                                </div>
                            </td>
                        </tr> -->

                        <tr>
                            <td colspan="4">
                                <div class="d-flex justify-content-between">
                                    <span class="col-4">
                                        <strong>Date:</strong>
                                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.date }}
                                    </span>
                                    <span class="col-4">
                                        <strong>Start Time:</strong>
                                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.startTime }}
                                    </span>
                                    <span class="col-4">
                                        <strong class="text-nowrap">End Time:</strong>
                                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.endTime }}
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'">
                            <td colspan="4">
                                <div class="d-flex justify-content-between">
                                    <span class="col-4">
                                        <strong class="text-nowrap">Lesson Attached To:</strong>
                                        #{{ this.injectedDialogData?.event?._def?.extendedProps?.enrollmentInfo?.id }}
                                    </span>
                                    <span class="col-4">
                                        <strong class="text-nowrap">Payment Due:</strong>
                                        <span *ngFor="let scheduled_payment of nextPaymentOnly(this.injectedDialogData?.event?._def?.extendedProps?.enrollmentInfo?.field_scheduled_payments)">
                                            ${{ scheduled_payment?.field_payment_amount }}
                                        </span>
                                        <!-- {{ this.injectedDialogData?.event?._def?.extendedProps?.enrollmentInfo?.field_scheduled_payments | json }} -->
                                        <!-- ${{ this.injectedDialogData?.event?._def?.extendedProps?.enrollmentInfo?.field_balance_due }} -->
                                    </span>
                                    <span class="col-4">
                                        <strong class="text-nowrap">Date Due:</strong>
                                        <span *ngFor="let scheduled_payment of nextPaymentOnly(this.injectedDialogData?.event?._def?.extendedProps?.enrollmentInfo?.field_scheduled_payments)">
                                            {{ (scheduled_payment?.field_payment_date | date) }}
                                        </span>
                                    </span>
                                </div>
                            </td>
                        </tr>

                        <tr *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'">
                            <td>
                                <strong class="text-nowrap">Next Lesson(s):</strong>
                            </td>
                            <td colspan="3">
                                <ng-container *ngFor="let upcomingLesson of this.injectedDialogData?.event?._def?.extendedProps?.upcomingEventList; last as isLast;">
                                    {{upcomingLesson | date:'short'}}<ng-container *ngIf="!isLast">,&nbsp;</ng-container>
                                </ng-container>
                            </td>
                        </tr>

                        <tr *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'">
                            <td>
                                <strong class="text-nowrap">Standing Appointment:</strong>
                            </td>
                            <td>
                                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.isStanding == true">Yes</ng-container>
                                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.isStanding == false">No</ng-container>
                            </td>
                        </tr>

                        <tr *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'">
                            <td>
                                <strong class="text-nowrap">Confirm Appointment:</strong>
                            </td>
                            <td>
                                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.isConfirmed == true">Yes</ng-container>
                                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.isConfirmed == false">No</ng-container>
                            </td>
                            <td colspan="2" class="no-wrap">
                                <div class="">
                                    <div class="col-12">
                                        <button matTooltip="Disabled for demo" class="confirmAppointment me-1" mat-flat-button
                                        (click)="return;">
                                        Email
                                        </button>
                                        <button matTooltip="Disabled for demo" class="confirmAppointment me-1" mat-flat-button
                                        (click)="return;">
                                        <mat-icon>call</mat-icon> Call
                                        </button>
                                        <button matTooltip="Disabled for demo" class="confirmAppointment me-1" mat-flat-button
                                        (click)="return;">
                                        <mat-icon>sms</mat-icon> Text
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </table>

                    <div class="d-flex justify-content-evenly align-items-center p-2" style="background:#F2F2F2;color:#000;border-radius:6px;">
                        <div class="align-middle">
                            Future Appointments:
                        </div>
                        <div class="align-middle">
                            <button mat-flat-button class=""
                            (click)="openDialog(CancelFutureAppointmentComponent, null, {
                                    entityID: this.entityID,
                                    parentID: this.injectedDialogData?.event?._def?.extendedProps?.['parentID']
                                })">Cancel Future Appointments</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container">

                <div class="row mt-3 mb-3">
                    <mat-divider></mat-divider>
                </div>

                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'schedules'">
                    <div class="row">
                        <div class="col-4 mt-1 d-grid">
                            <!-- <button class="btn btn-success">Add Lesson</button> -->
                            <button class="addLesson" mat-flat-button
                                (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', null, {
                                    'field_instructor': this.injectedDialogData?.event?._def?.extendedProps?.instructorID,
                                    'field_type': this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.eventTypeID,
                                    'field_duration': this.injectedDialogData?.event?._def.extendedProps?.eventDetails?.data?.eventDuration,
                                    '__field_students_inline_form': {
                                        'type': 'attendees',
                                        'bundle': 'attendance',
                                        'field_student_account': (this._injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentName + ' (' + this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId + ')')
                                    }
                                })">
                                Add Lesson
                            </button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button class="addService" mat-flat-button
                                (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
                                    '__field_students_inline_form': {
                                        'type': 'attendees',
                                        'bundle': 'attendance',
                                        'field_student_account': (this._injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentName + ' (' + this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId + ')')
                                    }
                                })">
                                Add Service
                            </button>
                        </div>
                        <!-- (click)="openEntityComponent(PostLessonComponent, '', '', '', '', {
                            'eventData': this.Events,
                            'entityID': this.entityID,
                            'field_instructor': 'this.currentSelectedInstructorID',
                            'field_duration': 'this.currentSelectedDuration',
                            'field_date_and_time': 'this.currentSelectedStartTime'
                        });">Post Lesson</button> -->
                        <div class="col-4 mt-1 d-grid">
                            <button class="postLesson" mat-flat-button
                            [disabled]="isEventPassed || this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'group_lesson'"
                            (click)="openDialog(PostLessonComponent, null, {
                                    entityID: this.entityID,
                                    eventData: this.eventData
                                })">Post Lesson</button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button mat-flat-button
                                [disabled]="!this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentTargetId"
                                class="studentDashboard text-nowrap"
                                (click)="openDialog(StudentDashboardWrapperComponent, 'largerScreenDashboard', {
                                    student_id: this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentTargetId
                                })">
                                Student Dashboard
                            </button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button mat-flat-button class="enrollment"
                            (click)="openEntityComponent(EnrollmentEntityComponent, 'packages', 'enrollment', 'create', null, {
                                field_student: (this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentName + ' (' + this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId + ')')
                            })" mat-button>
                                Enrollment
                            </button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button mat-flat-button class="payment"
                                (click)="openEntityComponent(PaymentEntityComponent, 'payments', 'payment', 'create', '', {
                                    'field_student_name': (this._injectedDialogData?.event?._def?.extendedProps?.studentDetails?.studentName + ' (' + this.injectedDialogData?.event?._def?.extendedProps?.studentDetails.studentAccountId + ')')
                                })">
                                Payment
                            </button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'" mat-flat-button class="editLesson"
                                (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', this.entityID)">
                                Edit Lesson
                            </button>
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'group_lesson'" mat-flat-button class="editLesson"
                                (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'edit', this.entityID)">
                                Edit Group Lesson
                            </button>
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'services'" mat-flat-button class="editLesson"
                                (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', this.entityID)">
                                Edit Service
                            </button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <button class="cancelReschedule" mat-flat-button (click)="openDialog(CancelRescheduleComponent, null, {
                                            entityID: this.entityID,
                                            appointmentType: this.injectedDialogData?.event?._def?.extendedProps?.appointmentType,
                                            eventData: this.eventData
                                        })">Cancel/RS</button>
                        </div>
                        <div class="col-4 mt-1 d-grid">
                            <!-- <button class="btn btn-warning">Delete</button> -->
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'" mat-flat-button color="accent"
                                (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'delete', this.entityID);">
                                Delete
                            </button>
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'group_lesson'" mat-flat-button color="accent"
                                (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'delete', this.entityID);">
                                Delete
                            </button>
                            <button *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'services'" mat-flat-button color="accent"
                                (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'delete', this.entityID);">
                                Delete
                            </button>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'schedules'">

                    <button mat-flat-button color="accent" class="me-1"
                        (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'edit', this.entityID)">
                        Edit Service
                    </button>

                    <button [disabled]="this.entityID == undefined" mat-flat-button
                        color="accent"
                        (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'delete', this.entityID)">
                        Delete
                    </button>
                </ng-container>

            </div>

        </mat-tab>

        <!-- Edit Lesson -->
        <mat-tab
            *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'schedules'
            && this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'group_lesson'
            && this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'schedules'" label="Edit Status">
            <app-update-status
            [appointmentType]="this.appointmentType"
            [status]="this.status"
            [attendeesID]="this.attendeesID"
            [studentName]="this.studentName"
            [eventID]="this.eventID"></app-update-status>
        </mat-tab>

        <!-- Edit Status Group Lesson -->
        <mat-tab
            *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'schedules'
            && this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'group_lesson'
            && this.injectedDialogData?.event?._def?.extendedProps?.appointmentType != 'schedules'" label="Edit Status">

            <app-update-status-group-lesson
            [eventData]="this.eventData"
            [appointmentType]="this.appointmentType"
            [status]="this.status"
            [studentName]="this.studentName"
            [eventID]="this.entityID"></app-update-status-group-lesson>
        </mat-tab>

        <!-- Lesson Blocks -->
        <mat-tab *ngIf="this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson'" label="Lesson Blocks">

        <form [formGroup]="this.lessonBlockForm" (ngSubmit)="lessonBlockFormSubmit(form)" #form="ngForm" class="form">
            <div class="container">
                <div class="row">
                    <div class="col-4">
                        <strong>Student:</strong> {{ this.studentName }}
                    </div>
                    <div class="col-4">
                        <strong>Instructor:</strong> {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.data?.instructor }}
                    </div>
                    <div class="col-4">
                        <strong>Studio:</strong> {{ this.studioInformationConfig?.field_studio_display_name?.[0].value }}
                    </div>
                </div>


                <div class="row pb-2 pt-2">
                    <div class="col-4">
                        <strong class="text-nowrap">Appointment Type:</strong>
                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.eventType }}
                    </div>
                    <div class="col-4">
                        <strong>Start Time:</strong>
                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.startTime }}
                    </div>
                    <div class="col-4">
                        <strong class="text-nowrap">End Time:</strong>
                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.endTime }}
                    </div>
                </div>

                <div class="row pb-2">
                    <span class="col-4">
                        <strong>Date:</strong>
                        {{ this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.date }}
                    </span>
                </div>

                <div class="row pb-1 pt-1">
                    <div class="col-12 text-center">
                        <mat-form-field class="full-width-field">
                            <mat-label>Material Covered</mat-label>
                            <textarea ngModel formControlName="field_material_covered"
                                matInput placeholder=""></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row pb-1 pt-1">
                    <div class="col-12 text-center">
                        <mat-form-field class="full-width-field">
                            <mat-label>Comments</mat-label>
                            <textarea ngModel formControlName="field_comments"
                                matInput placeholder=""></textarea>
                        </mat-form-field>
                    </div>
                </div>

                <div class="row pb-3 pt-3">
                    <strong class="text-nowrap">Lesson Rating:</strong>
                    <div class="col-12">
                        <div style="float:left;">
                            <ngx-star-rating formControlName="field_lesson_rating" [id]="'rating'"></ngx-star-rating>
                        </div>
                    </div>
                </div>

                <div class="row pb-3 pt-3">
                    <div class="col-6">
                        <strong>Teacher Initials:</strong>&nbsp;<a class="cursor-pointer" (click)="signatureClearInstructor()">CLEAR</a>
                        <canvas *ngIf="this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] == null" width="250px" height="125px" class="signature-canvas" #SignatureInstructor>

                        </canvas>
                        <div *ngIf="this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] != null" class="col-6">
                            <img class="img-fluid" [src]="this.eventEntityData?.['field_instructor_signature']?.[0]?.['url']" />
                        </div>
                        <!-- <button class="mt-1" type="button" mat-stroked-button (click)="signatureClearInstructor()">Clear</button> -->
                    </div>

                    <div class="col-6">
                        <strong>Student Initials:</strong>&nbsp;<a class="cursor-pointer" (click)="signatureClearStudent()">CLEAR</a>
                        <canvas *ngIf="this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] == null" width="250px" height="125px" class="signature-canvas" #SignatureStudent>

                        </canvas>
                        <div *ngIf="this.eventEntityData?.['field_signature_student']?.[0]?.['url'] != null" class="col-6">
                            <img class="img-fluid" [src]="this.eventEntityData?.['field_signature_student']?.[0]?.['url']" />
                        </div>
                        <!-- <button class="mt-1" type="button" mat-stroked-button (click)="signatureClearStudent()">Clear</button> -->
                    </div>
                </div>

                <div class="row pb-3 pt-3">
                    <div class="col-6 text-center">
                        <button
                        (click)="lessonBlockFormSubmit(f);"
                        type="button"
                        mat-button>Save & Email</button>
                    </div>
                    <div class="col-6 text-center">
                        <button
                        (click)="lessonBlockFormSubmit(f);"
                        type="button"
                        mat-button>Save & Exit</button>
                    </div>
                </div>
            </div>
        </form>

        </mat-tab>
    </mat-tab-group>
</mat-dialog-content>
