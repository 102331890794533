import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-appointment-sheet',
  templateUrl: './appointment-sheet.component.html',
  styleUrls: ['./appointment-sheet.component.css']
})
export class AppointmentSheetComponent extends ViewTableComponent implements OnInit {

  currentPage: number;

  override displayedColumns: string[] = [
    'field_date_and_time_value',
    'time',
    'event_title',
    'enrollment_category',
    'field_total_lessons_taken_value',
    'field_total_lessons_remaining_value',
    'payment_due',
    'field_upcoming_lesson_value'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/reports/appointment-sheet";

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }
}
