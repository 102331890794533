import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-cancel-future-appointment',
  templateUrl: './cancel-future-appointment.component.html',
  styleUrls: ['./cancel-future-appointment.component.css']
})
export class CancelFutureAppointmentComponent extends BaseDialogComponent implements OnInit {

  override entityId = this._injectedDialogData['data']['EntityID'];

  override ngAfterContentInit(): void {
    console.log('injectedDialogData', this._injectedDialogData['data']);
    console.log(this._injectedDialogData['data']);

    this.onSubmit(this.f);
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    // Build request...
    let params: any;

    // Add params the endpoint is expecting.
    params = {
      parentID!: this._injectedDialogData['data']['parentID'],
      eventID!: this._injectedDialogData['data']['entityID']
    };

    this._drupalRESTService.httpPOST('/api/v1/cancelFutureStandingAppts', params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log(data)
        if (data?.['success']) {
          this._dialog.closeAll();
        }
        if (data?.['message']) {
          this.errorMessage = data['message'];
        }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem canceling future lessons."; // Default error message.
          this.handleError(error);
        });
  }

}
