<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

<div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
        class="mt-4">

        <!-- field_date_and_time Column -->
        <ng-container matColumnDef="field_date_and_time">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_date_and_time">
                Date
            </th>
            <td mat-cell *matCellDef="let element"> {{element.field_date_and_time}}
            </td>
        </ng-container>

        <!-- field_date_and_time_1 Column -->
        <ng-container matColumnDef="field_date_and_time_1">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_date_and_time_1">
                Time
            </th>
            <td mat-cell *matCellDef="let element"> {{element.field_date_and_time_1}}
            </td>
        </ng-container>

        <!-- field_type Column -->
        <ng-container matColumnDef="field_type">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_type">
                Lesson Type
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_type }} </td>
        </ng-container>

        <!-- field_duration Column -->
        <ng-container matColumnDef="field_duration">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_duration">
                Duration
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_duration }} </td>
        </ng-container>

        <!-- field_instructor Column -->
        <ng-container matColumnDef="field_instructor">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_instructor">
                Instructor
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_instructor }}
            </td>
        </ng-container>

        <!-- field_status_1 Column -->
        <ng-container matColumnDef="field_status_1">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by field_status_1">
                Status
            </th>
            <td mat-cell *matCellDef="let element"> {{ element.field_status_1 }} </td>
        </ng-container>

				<!-- Edit  -->
				<ng-container matColumnDef="edit">
					<th mat-header-cell *matHeaderCellDef>Edit</th>
					<td mat-cell *matCellDef="let element">
                        <!-- <button *ngIf="element[1] !== 'Enrollment'" mat-flat-button color="accent"
                                (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.event_id)">
                                Edit Lesson
                        </button> -->
                        <ng-container *ngIf="element[1] !== 'Enrollment'" mat-flat-button color="accent">
                            <mat-icon
                            class="cursor-pointer"
                            (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.event_id)">
                                edit_square
                            </mat-icon>
                        </ng-container>
					</td>
			</ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator
        (page)="pageChanged($event)"
        [pageSizeOptions]="onPageSizeOptions()"
        [length]="30"
        [pageSize]="paginationTotalPages"
        [pageIndex]="paginationIndex"
        aria-label="Select page of view"></mat-paginator>

</div>
