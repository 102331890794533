<h1 class="page-header">Payments Made Report (Sundry)</h1>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filter
    </mat-panel-title>
    <mat-panel-description>
      <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
    <div class="row">

      <!-- Enrollment Autocomplete -->
      <div class="col-4">
        <mat-form-field class="full-width-field">
          <mat-label>Enrollment</mat-label>
          <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
            [matAutocomplete]="autoCompleteEnrollment">
          <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
            <mat-icon>manage_accounts</mat-icon>
          </button>
        </mat-form-field>
        <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
          <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
            {{option.label}} ({{option.value}})
          </mat-option>
        </mat-autocomplete>
      </div>


      <div class="col-4">
          <mat-form-field class="full-width-field">
            <mat-label>Select Department...</mat-label>
            <mat-select
              name="field_department_name"
              ngModel
              (selectionChange)="onSubmit(f)">
              <mat-option value="all">All</mat-option>
              <mat-option
              *ngFor="let department of departmentData"
              [value]="department.key">{{ department.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      <section class="section">
        <div class="float-end bring-inline">
          <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
          </button>
        </div>

      </section>

    </div>

    <section class="section">
    </section>

  </form>

</mat-expansion-panel>

<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="row">
  <div class="col-md-12 mt-3">
    <div class="float-end">
      <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
      <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
    </div>
  </div>
</div>

<div class="table-container">

  <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">


    <!-- id Column -->
    <ng-container matColumnDef="id">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by id">
        Receipt #
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
    </ng-container>

    <!-- field_date_and_time Column -->
    <ng-container matColumnDef="field_date_and_time">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_date_and_time">
        Date Paid
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_date_and_time }} </td>
    </ng-container>

    <!-- field_student_name Column -->
    <ng-container matColumnDef="field_student_name">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_name">
        Student Name
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_student_name }} </td>
    </ng-container>

    <!-- field_student_department Column -->
    <ng-container matColumnDef="field_student_department">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_department">
        Student Department
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_student_department }} </td>
    </ng-container>

    <!-- field_enrollment_package_name_label Column -->
    <ng-container matColumnDef="field_enrollment_package_name_label">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_enrollment_package_name_label">
        Enrollment
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_enrollment_package_name_label }} </td>
    </ng-container>

    <!-- field_payment_type_label Column -->
    <ng-container matColumnDef="field_payment_type_label">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_payment_type_label">
        Payment Type
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_payment_type_label }} </td>
    </ng-container>

    <!-- field_instructor_label Column -->
    <ng-container matColumnDef="field_instructor_label">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_instructor_label">
        Instructor
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_instructor_label }} </td>
    </ng-container>

    <!-- REPLACE Column -->
    <ng-container matColumnDef="closer">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by closer">
        Closer
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_junior_executive_label }} </td>
    </ng-container>

    <!-- REPLACE Column -->
    <!-- <ng-container matColumnDef="REPLACE">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
        REPLACE
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
    [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

</div>
