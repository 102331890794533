import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-event-services-entity',
  templateUrl: './event-services-entity.component.html',
  styleUrls: ['./event-services-entity.component.css']
})
export class EventServicesEntityComponent extends BaseDialogComponent implements OnInit {
  override eckType = "events";
  override bundle = "services";
  override entityId = this._injectedDialogData['data']['EntityID'];

  override ngAfterViewInit() {
    // Set default value(s) for services.
    setTimeout(() => {
      this.f.form.patchValue({
        field_status: "113" // "Pending Status"
      });
    });

    // Special handling for loading an attendee.
    if (typeof(this.injectedDialogData?.['data']?.['fieldsData']?.['___field_student']) != 'undefined') {
      this.field_student.push(this.injectedDialogData['data']['fieldsData']['___field_student']);
    }

    // console.log('this.fieldstudent')
    // console.log(this.field_student)
  }

  entityType = "attendees";
  entityBundle = "attendance";

  studentIncrement(attendee: any) {

    // Append type & bundle to model.
    attendee = {
      ...attendee,
      type: 'attendees',
      bundle: 'attendance',
    }

    console.log('attendee', attendee);

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_student)) { this.field_student = [] }

    this.field_student.push(attendee);

    this.resetFieldStudent();
  }

  studentRemove = (index: number): void => {
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;
    this.field_student.splice(index, 1);

    this.resetFieldStudent();
  }

  studentUpdateLoad = (index: number): void => {
    console.log('UpdateLoad called...')
    this.resetFieldStudent();

    // Load the student enrollments.
    this.lookupStudentAccountContact({ option: { value: this.field_student[index].field_student_account } });
    // Load the student contact(s).
    this.onStudentNameSelectThenLoadEnrollmentName({ option: { value: this.field_student[index].field_student_account } })

    // Append type & bundle to model.
    this.field_student[index]['type'] = 'attendees';
    this.field_student[index]['bundle'] = 'attendance';

    this.editIndex = index;
    this.editMode = true;

    // If field_student is a couple.
    this.field_student[index]['field_students'] = this.field_student[index]['field_students'].toString();

    Promise.resolve().then(() => {
      this.f.controls['__field_students_inline_form'].patchValue(this.field_student[index])
    })
  }

  studentUpdateSubmit(number: any, attendee: any) {
    this.field_student[this.editIndex] = attendee;
    this.f.form.controls['__field_students_inline_form'].reset();
    this.editMode = false;

    this.resetFieldStudent();
  }

  resetFieldStudent() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.f.form.controls['__field_students_inline_form'].reset();
  }
}

