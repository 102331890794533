import { Injectable } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigPagesService {

  constructor(private _drupalRESTService: DrupalRESTService) { }

  get_config(type, field) {
    return this._drupalRESTService.httpGET("/api/v1/configPages?type=" + type + '&field=' + field);
  }

}
