import { Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseFormComponent } from '../base-form/base-form.component';
import { MatStepper } from '@angular/material/stepper';
import _ from 'lodash';
import moment from 'moment';
import { EventLessonEntityComponent } from '../event-lesson-entity/event-lesson-entity.component';
import { environment } from 'src/environments/environment';
import { BaseEnrollmentComponent } from '../../enrollment/base-enrollment/base-enrollment.component';

@Component({
  selector: 'app-enrollment-entity',
  templateUrl: './enrollment-entity.component.html',
  styleUrls: ['./enrollment-entity.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentEntityComponent extends BaseEnrollmentComponent implements OnInit {

  override eckType = "packages";
  override bundle = "enrollment";
  override entityId = this._injectedDialogData['data']['EntityID'];

  field_enrollment_name: any;

  scheduledPayment_list = [];
  scheduledPaymentEditMode: boolean;
  scheduledPaymentEditIndex: number;
  // executives = [];
  // executiveEditMode: boolean;
  instructor_percentage_list = [];
  instructorPercentageEditMode: boolean;
  instructorPercentageEditIndex: number;

  is_pay_in_full: boolean;
  is_use_installments: boolean;
  override interval:number = 1;

  discountTotal;

  eventCount;
  lessonCountDisabled: boolean;
  isMiscOrSundry: boolean = false;
  studioAddress: any;
  studioPhone: any;
  studioEmail: any;
  studioWebsite: any;
  studioName: any;
  console: any;

  onSubmitPayment(f: NgForm, drop = false) {
    this.displayProgressSpinner(true);

    // Support for single or multiple enrollments.
    if (this.initActionType == "create" || this.initActionType == "refund") {
      this.errorMessage = "";

      // Copy the object to not modify binded data.
      let values = _.cloneDeep(f.value);
      this.__alterValues(values);

      if (this.initActionType == "create") {
        // Change "field_enrollment_name" to "enrollment_ids"
        values.enrollment_ids = values.field_enrollment_name;
      } else {
        // Refund
        values.enrollment_ids = [ values.field_enrollment_name ];
        values.field_gross_tuition = -(Math.abs(values.field_gross_tuition));
        values.field_stripe_charge_id = this.entityData?.field_stripe_charge_id;
      }

      // Support for refunds.
      if (this.initActionType == "refund") {
        this.paymentForm.value.field_gross_tuition = -(Math.abs(this.paymentForm.value.field_gross_tuition));
        this.actionType == "refund"
        // this.onSubmit(this.paymentForm);
      }

      let body = {
        ...values,
      }

      this._drupalRESTService.httpPOST('/api/v1/postPayment', body).subscribe(data => {
        console.log('data', data)

        this.displayProgressSpinner(false);
        this.setStepperIndex(3);
      },
        error => this.handleError(error)
      )
    } else {
        // Otherwise submit as normal to eckEntityWrapper.
        this.onSubmit(this.paymentForm);
    }
  }

  calcScheduledPayments() {
    // console.log('calcScheduledPayments called...');
    this.enrollmentForm.get('interval').value;
    this.enrollmentForm.get('field_payments_structure').value;

    let interval = this.enrollmentForm.get('interval').value;
    let startIntervalDate = moment();

    let totalPayments = this.calcNewTotal();
    let totalPaymentsDivideByInterval = totalPayments / this.enrollmentForm.get('interval').value;

    // The start date.
    let newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value, 'DD');

    if (!this.enrollmentForm.get('field_recurrent_month_day').value) {alert('Please select a start date.'); return;}

      (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

      let i;
      let o = 0;
      if (this.enrollmentForm.get('field_payments_structure_type').value == 'monthly' || this.enrollmentForm.get('field_payments_structure_type').value == 'weekly') {
        for (i = 0; i < interval; i++) {
          // Calculate next payment date based on monthly and day settings.
          if (this.enrollmentForm.get('field_payments_structure_type').value == 'monthly') {
            newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value, 'DD').add(o, 'months');

            let controlsConfig = {
              'id': [null],
              'field_amount_paid': [null],
              'field_enrollment': [null],
              'field_paid_in_full': [false],
              'field_down_payment': [false, Validators.required],
              'field_payment_amount': [totalPaymentsDivideByInterval, Validators.required],
              'field_payment_date': [newGeneratedDate.format('YYYY-MM-DD'), Validators.required],
              'field_number_lesson_remaining': [null],
              'bundle': ['scheduled_payments', Validators.required],
              'type': ['scheduled_payments', Validators.required],
            };

            this.scheduledPaymentIncrement(controlsConfig);

            // Check for steps payment_builder_date_step
            this.enrollmentForm.get('payment_builder_date_step').value.forEach(element => {
              i = i + 1;
              // Don't allow more than the interval.
              if (i < interval) {
                // Use the newGeneratedDate to set the newGeneratedDateInterval.
                let newGeneratedDateInterval = moment(newGeneratedDate).set("date", element.field_recurrent_month_day);

                let controlsConfig = {
                  'id': [null],
                  'field_amount_paid': [null],
                  'field_enrollment': [null],
                  'field_paid_in_full': [false],
                  'field_down_payment': [false, Validators.required],
                  'field_payment_amount': [totalPaymentsDivideByInterval, Validators.required],
                  'field_payment_date': [newGeneratedDateInterval.format('YYYY-MM-DD'), Validators.required],
                  'field_number_lesson_remaining': [null],
                  'bundle': ['scheduled_payments', Validators.required],
                  'type': ['scheduled_payments', Validators.required],
                };

                this.scheduledPaymentIncrement(controlsConfig);
              }
            });

            o++;
          }

          // Calculate next payment date based on weekly and day settings.
          if (this.enrollmentForm.get('field_payments_structure_type').value == 'weekly') {
            let weekdays = [];
            if (this.enrollmentForm.get('field_recurrent_weekday_su').value) {weekdays.push(0);}
            if (this.enrollmentForm.get('field_recurrent_weekday_mo').value) {weekdays.push(1);}
            if (this.enrollmentForm.get('field_recurrent_weekday_tu').value) {weekdays.push(2);}
            if (this.enrollmentForm.get('field_recurrent_weekday_we').value) {weekdays.push(3);}
            if (this.enrollmentForm.get('field_recurrent_weekday_th').value) {weekdays.push(4);}
            if (this.enrollmentForm.get('field_recurrent_weekday_fr').value) {weekdays.push(5);}
            if (this.enrollmentForm.get('field_recurrent_weekday_sa').value) {weekdays.push(6);}

            // Validation
            if (weekdays.length == 0) { return; }

            let foundNextDate = false;
            while(foundNextDate == false) {
              newGeneratedDate.add('1', 'day')
              if (weekdays.includes(newGeneratedDate.day())) {
                foundNextDate = true;
              }
            }

            let controlsConfig = {
              'id': [null],
              'field_amount_paid': [null],
              'field_enrollment': [null],
              'field_paid_in_full': [false],
              'field_down_payment': [false, Validators.required],
              'field_payment_amount': [totalPaymentsDivideByInterval, Validators.required],
              'field_payment_date': [newGeneratedDate.format('YYYY-MM-DD'), Validators.required],
              'field_number_lesson_remaining': [null],
              'bundle': ['scheduled_payments', Validators.required],
              'type': ['scheduled_payments', Validators.required],
            };

            this.scheduledPaymentIncrement(controlsConfig);
          }
        }
      }


      if (this.enrollmentForm.get('field_payments_structure_type').value == 'by_the_lesson') {
        let interval = this.enrollmentForm.get('interval').value;
        let field_enrollment_lesson_count = this.enrollmentForm.get('field_enrollment_lesson_count').value;
        let newGeneratedDate = moment(this.enrollmentForm.get('field_recurrent_month_day').value);
        // Get the new payment amount by newIncrement by interval.
        let totalPaymentsDivideByInterval = totalPayments / this.enrollmentForm.get('interval').value;

        // Find the split of payments.
        let paymentCounter = 0;
        for (i = field_enrollment_lesson_count; 0 < i; i = i - interval) {
          paymentCounter++;
        }
        totalPayments = totalPayments / paymentCounter;

        // Create the payments.
        for (i = field_enrollment_lesson_count; 0 < i; i = i - interval) {

          let controlsConfig = {
            'id': [null],
            'field_amount_paid': [null],
            'field_enrollment': [null],
            'field_paid_in_full': [false],
            'field_down_payment': [false, Validators.required],
            'field_payment_amount': [totalPayments, Validators.required],
            'field_payment_date': [(i == field_enrollment_lesson_count) ? newGeneratedDate.format('YYYY-MM-DD'): null],
            'field_number_lesson_remaining': [i],
            'bundle': ['scheduled_payments', Validators.required],
            'type': ['scheduled_payments', Validators.required],
          };

          this.scheduledPaymentIncrement(controlsConfig);

          // Check for steps payment_builder_date_step
          this.enrollmentForm.get('payment_builder_date_step').value.forEach(element => {
            i = i + 1;
            // Don't allow more than the interval.
            if (i < interval) {
              // // Use the newGeneratedDate to set the newGeneratedDateInterval.
              // let newGeneratedDateInterval = moment(newGeneratedDate).set("date", element.field_recurrent_month_day);

              let controlsConfig = {
                'field_amount_paid': [null],
                'field_enrollment': [null],
                'field_paid_in_full': [false],
                'field_down_payment': [false, Validators.required],
                'field_payment_amount': [totalPaymentsDivideByInterval, Validators.required],
                'field_payment_date': [null, Validators.required],
                'field_number_lesson_remaining': [field_enrollment_lesson_count],
                'bundle': ['scheduled_payments', Validators.required],
                'type': ['scheduled_payments', Validators.required],
              };

              this.scheduledPaymentIncrement(controlsConfig);
            }
          });

          o++;
        }
      }
  }

  scheduledPaymentIncrement(controlsConfig?) {
    let control = this._formBuilder.group(controlsConfig ?? {
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [false],
      'field_down_payment': [false, Validators.required],
      'field_payment_amount': [null, Validators.required],
      'field_payment_date': [null, Validators.required],
      'field_number_lesson_remaining': [null],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control)
  }

  scheduledPaymentRemove(index: number) {
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).removeAt(index)
  }

  instructorPercentageIncrement() {
    // Default the first added percentage to 100.
    let instructor_percentage = (this.enrollmentForm.get('field_instructor_percentages')?.['controls'] == 0) ? 100 : 0;

    let control = this._formBuilder.group({
      'id': [null],
      'field_amount': [null, Validators.required],
      // 'field_enrollment': [null, Validators.required],
      'field_instructor': [null, Validators.required],
      'field_percentage': [instructor_percentage, Validators.required],
      'bundle': ['instructor_percentages', Validators.required],
      'type': ['instructor_percentages', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_instructor_percentages')).push(control)

    this.fromPercentCalcTotal();
  }

  validationErrors = [];

  instructorPercentageRemove(index: number) {
    (<FormArray>this.enrollmentForm.get('field_instructor_percentages')).removeAt(index)
  }

  resetInstructorFormGroup() {
    this.enrollmentMessage = "";
    this.autoCompleteStudentContactOptions = null;
    this.enrollmentsForUser = null;
    this.enrollmentForm.controls['__field_students_inline_form'].reset();
  }

  onPackageLookup($event: { option: { value: any; }; }) {
    // console.log($event.option.value);

    this._entityRESTService.getEntity('packages', 'package', this.regexParamID($event.option.value))
    .subscribe(response => {
      // console.log('response', response)

      this.enrollmentForm.patchValue({
        field_enrollment_total_price: response['field_total_price'],
        field_enrollment_lesson_price: response['field_lesson_price'],
        field_enrollment_lesson_count: response['field_lesson_count'],
      });

      this.calcNewTotal();
    })

  }

  paid_in_full() {
    // console.log('paid_in_full called...')
    this.is_pay_in_full = true;
    this.is_use_installments = false;

    // Clear the current form.
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

    // Calculate data needed.

    // Add the newly calculated data.
    let control = this._formBuilder.group({
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [true],
      'field_down_payment': [true, Validators.required],
      'field_payment_amount': [this.enrollmentForm.get('field_enrollment_total_price').value, Validators.required],
      'field_payment_date': [this.enrollmentForm.get('field_sale_date').value ?? moment().format('YYYY-MM-DD'), Validators.required],
      'field_scheduled_payments': [null],
      'field_number_lesson_remaining': [null],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control);
  }

  use_installments() {
    this.is_use_installments = true;
    this.is_pay_in_full = false;

    // Clear the current form.
    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).clear();

    // Add the newly calculated data.
    let control = this._formBuilder.group({
      'id': [null],
      'field_amount_paid': [null],
      'field_enrollment': [null],
      'field_paid_in_full': [false],
      'field_down_payment': [false, Validators.required],
      'field_payment_amount': [null, Validators.required],
      'field_payment_date': [null, Validators.required],
      'field_scheduled_payments': [null],
      'field_number_lesson_remaining': [null],
      'bundle': ['scheduled_payments', Validators.required],
      'type': ['scheduled_payments', Validators.required],
    });

    (<FormArray>this.enrollmentForm.get('field_scheduled_payments')).push(control);
  }

  paymentPlanGeneratorIncreaseStep() {
    // Add the newly calculated data.
    let control = this._formBuilder.group({
      field_recurrent_month_day: 'first',
    });

    (<FormArray>this.enrollmentForm.get('payment_builder_date_step')).push(control);
  }

  paymentPlanGeneratorRemoveStep(index) {
    (<FormArray>this.enrollmentForm.get('payment_builder_date_step')).removeAt(index)
  }

  onSubmitEnrollment(f: NgForm, mode) {
    this.errorMessage = "";

    // Calculate instructor percentenages.
    this.fromPercentCalcTotal();

    this.displayProgressSpinner(true);
    // console.log('onSubmitEnrollment called...');

    // Copy the object to not modify binded data.
    let values = _.cloneDeep(f.value);

    if (this.actionType == "create") {
      this.__alterValues(values);

      // console.log(this.actionType + " called...");

      this._entityRESTService.createEntity(this.eckType, this.bundle, values)
        .subscribe(data => {
          this._entityRESTService.getEntity('packages', 'enrollment', data['id'][0].value)
            .subscribe(data => {
              // console.log('packages enrollment data', data);
              this.entityData = data;
              this.enrollmentEntityData = data;
            });
          this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
            .subscribe(data => {
              // console.log('student_accounts student_account data', data);
              this.studentAccountEntityData = data;
            });

            this.displayProgressSpinner(false);

            this.setStepperIndex(1);
        },
          error => this.handleError(error)
        )
    }

    // Edit enrollments.
    if (mode == "edit") {
      values = _.transform(values, function(res, v, k) {
        if (v && v != "") res[k] = v;
      });

      this.__alterValues(values);

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, values)
        .subscribe(data => {
          this.displayProgressSpinner(false);
          this._dialog.closeAll();
        },
          error => this.handleError(error)
        )
    }
  }

  calcDiscount() {
    let formValues = this.enrollmentForm.value;

    // Calulate a percentage discount.
    if (formValues.field_discount_type == 'percentage') {
      this.discountTotal = ((formValues.field_enrollment_lesson_price * formValues.field_enrollment_lesson_count) * (formValues.field_discount / 100));
    }

    // Calculate a flat discount.
    if (formValues.field_discount_type == 'flat') {
      this.discountTotal = formValues.field_discount;
    }

    // Default to flat if undefined.
    if (!formValues.field_discount_type) {
      this.discountTotal = formValues.field_discount;
    }

    return this.discountTotal;
  }

  calcLessonTotal() {
    let formValues = this.enrollmentForm.value;

    // If the category is misc (5) or sundry (67), return the lesson price.
    if (formValues.field_category == '5' || formValues.field_category == '67') {
      // Update whatever lesson count is to 0
      this.enrollmentForm.patchValue({
        field_enrollment_lesson_count: 0,
      });

      return (formValues.field_enrollment_lesson_price);
    } else {
      return (formValues.field_enrollment_lesson_price * formValues.field_enrollment_lesson_count);
    }
  }

  calcTotalWithDiscount() {
    let formValues = this.enrollmentForm.value;
    return this.calcLessonTotal() - this.calcDiscount();
  }

  calcTotalWithTaxes(totalBeforeTaxes: number) {
    let total = 0
    this.taxRate = this.enrollmentForm.get("field_tax_percentage").value ?? 0;
    // console.log(this.taxRate);
    total = totalBeforeTaxes + (totalBeforeTaxes * (this.taxRate / 100))
    return total;
  }

  calcNewTotal() {
    let formValues = this.enrollmentForm.value;
    let total;
    let totalBeforeTaxes;
    let lessonPriceTotal;

    // Really simple calculations.
    lessonPriceTotal = this.calcLessonTotal();
    totalBeforeTaxes = this.calcTotalWithDiscount();
    total = this.calcTotalWithTaxes(totalBeforeTaxes);

    // Round totals.
    lessonPriceTotal = Number.parseFloat(lessonPriceTotal).toFixed(2);
    totalBeforeTaxes = Number.parseFloat(totalBeforeTaxes).toFixed(2);
    total = Number.parseFloat(total).toFixed(2);

    // Check for non real numbers.
    if (isNaN(totalBeforeTaxes) || isNaN(total)) {
    } else {
      this.enrollmentForm.patchValue({
        field_enrollment_total_price: total ?? 0,
        field_total_lessons_price: lessonPriceTotal ?? 0,
        field_taxes: (totalBeforeTaxes * (this.taxRate / 100)) ?? 0
      });
    }

    // Determine is enrollment is misc or sundry item.
    this.isMiscOrSundry = (formValues.field_category == 5 || formValues.field_category == 67) ?? false;

    // Calculate instructor percentage when total update.
    this.fromPercentCalcTotal();

    return total;
  }

  fromPercentCalcTotal() {
    let formValues = this?.enrollmentForm?.value;
    let total = this.calcTotalWithDiscount();
    let amountPercentage:number;

    let field_instructor_percentages_array = [];
    if (formValues?.field_instructor_percentages) {
      formValues?.field_instructor_percentages?.forEach((values => {
        let amountPercentage = (values.field_percentage  / 100);
        values.field_percentage = amountPercentage * total;

        field_instructor_percentages_array.push({
          field_amount: values.field_percentage
        })
      }));
    }

    this.enrollmentForm.patchValue({
      field_instructor_percentages: field_instructor_percentages_array
    });
  }

  updateCount($count) {
    // console.log('updateCount called...', $count)
    this.eventCount = $count;
  }

  onEnrollmentSaleDate($event) {
    // console.log('onEnrollmentSaleDate called...', $event)
    if ($event) {
      // Default the expiration date to 6 months from now.
      let date: string = $event?.target?.value ?? $event;
      if (this.enrollmentForm.get("field_expiration_date").value) {
      } else {
        this.enrollmentForm.get("field_expiration_date").setValue(moment(date).add(6, 'months'));
      }
    }
  }

  getOriginalPackageCost(): number {
    if (!this.entityData?.field_enrollment_total_price || !this.entityData?.field_discount_type || !this.entityData?.field_discount) {
      return 0; // Return 0 or another appropriate default value if data is not available
    }

    const packageCost = this.entityData.field_enrollment_total_price;
    const discount = this.entityData.field_discount;

    if (this.entityData.field_discount_type === 'flat') {
      return packageCost + discount;
    } else if (this.entityData.field_discount_type === 'percentage') {
      return packageCost / (1 - (discount / 100));
    } else {
      return packageCost; // Assuming no discount if type is neither 'flat' nor 'percentage'
    }
  }

}
