import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild, VERSION } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { FormControl, FormGroup, NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";

@Component({
  selector: 'app-studio-business-summary',
  templateUrl: './studio-business-summary.component.html',
  styleUrls: ['./studio-business-summary.component.css']
})
export class StudioBusinessSummaryComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'column_1',
    'column_2',
  ];

  consumable:any;

  currentReport: string = 'studioBusiness';
  week: string;
  year: string;

  Math: Math = Math;

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      // console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      this.getData([
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ]);
    })
  }

  override getData(params) {
    // Disable buttons if it's the current week.
    let paramWeek = moment().day("Sunday").year(+this.year).week(+this.week).add(1, 'weeks');
    this.isDisabled = paramWeek.isAfter();

    this.clearFormTableData();

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._utilityService.getStudioBusinessReport(
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;
        // console.log(data['#studio_content_table']['studio_content_table']['#rows']);
        let rows = data['#studio_content_table']['studio_content_table']['#rows'];
        this.consumable = data['#studio_content_table']['studio_content_table']['#consumable'];

        // Modify data to work in Angular.
        rows.forEach((element, index) => {
          // rows[index]['name'] = element.name.replace(/<[^>]*>?/gm, '');
        });

        this.data = data;
        this.dataSource = new MatTableDataSource(rows);

        this.pageSize = rows.length;

        // Pagination configuration.
        this.updatePaginationConfig(rows);
      },
        error => this.handleError(error)
      )
  }

}
