import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseFormComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends BaseFormComponent {

  override onSubmit(f: NgForm) {
    let values = f.form.value;

    this.displayProgressSpinner(true);

    this._authRESTService.forgot_password(values.name)
      .subscribe(data => {
        console.log('data');
        console.log(data);

        this._authService.set_user_authenticated(true);
        console.log('this._authenticateService.is_user_authenticated()');
        console.log(this._authService.is_user_authenticated());
        // Navigate to dashboard.
        this._router.navigate(['/splash/']);

        this.displayProgressSpinner(false);
      },
        error => this.handleError(error)
    )

    // this._authRESTService.login(values.name, values.pass)
    //   .subscribe(
    //     (response: any) => {
    //       console.log('response advanced')
    //       console.log(response)
    //       let header = response.headers.get('X-Token');
    //       console.log('header');
    //       console.log(header);
    //     },
    //     error => this.handleError(error)
    //   )

  }

}
