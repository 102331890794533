<div class="container-fluid">
  <h1 class="page-header">Enrollments Tracking Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Date Range Filter -->
        <div class="col-3">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
              <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field class="full-width-field">
                <mat-label>Instructor</mat-label>
                <mat-select (selectionChange)="onSubmit(f)" required matInput ngModel name="field_instructor_target_id">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let element of this._fieldsService?.instructorData()"
                        [value]="element.uid">
                        {{ element.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3">
            <mat-form-field class="full-width-field">
                <mat-label>Closer</mat-label>
                <mat-select (selectionChange)="onSubmit(f)" matInput ngModel name="field_executive_target_id">
                    <mat-option>None</mat-option>
                    <mat-option *ngFor="let element of this._fieldsService.instructorsExecutive()"
                        [value]="element.uid">
                        {{ element.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- Enrollment Autocomplete -->
        <!-- <div class="col-4">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div> -->


          <!-- <div class="col-4">
            <mat-form-field class="full-width-field">
              <mat-label>Select Department...</mat-label>
              <mat-select
                name="field_department_name"
                ngModel
                (selectionChange)="onSubmit(f)">
                <mat-option value="all">All</mat-option>
                <mat-option
                *ngFor="let department of departmentData"
                [value]="department.key">{{ department.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div> -->

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>

      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

      <!-- student_first_name Column -->
      <ng-container matColumnDef="field_sale_date">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_sale_date">
          Date
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_sale_date | date:'shortDate' }} </td>
      </ng-container>

      <!-- title_student_account Column -->
      <ng-container matColumnDef="title_student_account">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by title_student_account">
          Student
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.title_student_account }} </td>
      </ng-container>

      <!-- field_student_department Column -->
      <ng-container matColumnDef="field_student_department">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_department">
          Dept.
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_student_department }} </td>
      </ng-container>

      <!-- title_enrollment Column -->
      <ng-container matColumnDef="title_enrollment">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by title_enrollment">
          Enrollment
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.title_enrollment }} </td>
      </ng-container>

      <!-- field_total_lessons_remaining Column -->
      <ng-container matColumnDef="field_total_lessons_remaining">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_total_lessons_remaining">
          Remaining
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_total_lessons_remaining }} </td>
      </ng-container>

      <!-- field_total_lessons_paid_ahead Column -->
      <ng-container matColumnDef="field_total_lessons_paid_ahead">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_total_lessons_paid_ahead">
          Paid Ahead
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_total_lessons_paid_ahead }} </td>
      </ng-container>

      <!-- field_first_name_teacher Column -->
      <ng-container matColumnDef="field_first_name_teacher">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_first_name_teacher">
          Teacher
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_teacher }} {{ element.field_last_name_teacher }} </td>
      </ng-container>

      <!-- field_first_name_executive Column -->
      <ng-container matColumnDef="field_first_name_executive">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_first_name_executive">
          Closer
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_executive }} {{ element.field_last_name_executive }} </td>
      </ng-container>

      <!-- field_upcoming_lesson Column -->
      <ng-container matColumnDef="field_upcoming_lesson">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_upcoming_lesson">
          Next Lesson
        </th>
        <td mat-cell *matCellDef="let element"> {{  element.field_upcoming_lesson | date:'shortDate' }} </td>
      </ng-container>

      <!-- field_notes Column -->
      <ng-container matColumnDef="field_notes">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_notes">
          Notes
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_notes }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>

</div>
