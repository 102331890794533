<div mat-dialog-title>
	<h1>Enrollment Lessons View</h1>
	<button mat-button mat-dialog-close>
			<mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon>
	</button>
</div>

<div [ngClass]="{
			'loading-shade': showProgressSpinner
	 }">

	<div mat-dialog-content>
		<app-enrollment-lesson-list-view [enrollmentID]="this.entityId"></app-enrollment-lesson-list-view>
	</div>
</div>
