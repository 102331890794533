<h1 class="page-header">Dashboard</h1>

<!-- <img src="assets/images/dashboard-static.png" style="display: block; margin: auto;" />
<mat-grid-list cols="2" rowHeight="2:1">
    <mat-grid-tile>1</mat-grid-tile>
    <mat-grid-tile>2</mat-grid-tile>
    <mat-grid-tile>3</mat-grid-tile>
    <mat-grid-tile>4</mat-grid-tile>
</mat-grid-list> -->

<!-- Student Summary  -->
<div *ngIf="dashboardData" id="student-summary" class="row
  justify-content-center">
  <h3 class="text-center"><b>Inquiry Data</b></h3>

  <div class="col-2 text-center">
    <p style="border: 12px solid #68BC94;" class="summary-hero">

      {{ this.dashboardData['#contact'].current }}
    </p>
    <div>Contact</div>
    <div>
      <span>{{ this.dashboardData?.['#contact']?.current }} YTD</span> |
      <span>{{ this.dashboardData?.['#contact']?.previous }} YTD</span>
    </div>
  </div>

  <div class="col-2 text-center">
    <p style="border: 12px solid #0077B7;" class="summary-hero">

      {{ this.dashboardData['#booked'].current }}
    </p>
    <div>Booked</div>
    <div>
      <span>{{ this.dashboardData?.['#booked']?.current }} YTD</span> |
      <span>{{ this.dashboardData?.['#booked']?.previous }} YTD</span>
    </div>
  </div>

  <div class="col-2 text-center">
    <p style="border: 12px solid #E5E5E5;" class="summary-hero">

      {{ this.dashboardData['#showed'].current }}
    </p>
    <div>Showed</div>
    <div>
      <span>{{ this.dashboardData['#showed'].current }} YTD</span> |
      <span>{{ this.dashboardData['#showed'].previous }} YTD</span>
    </div>
  </div>
</div>

<app-lessons-booked-chart [chart_data]="this.chart_data?.['lessons_booked']"></app-lessons-booked-chart>
<app-payments-chart [chart_data]="this.chart_data?.['payments']"></app-payments-chart>
<app-enrollments-chart [chart_data]="this.chart_data?.['enrollment']"></app-enrollments-chart>
