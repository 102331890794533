import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-update-status-group-lesson',
  templateUrl: './update-status-group-lesson.component.html',
  styleUrls: ['./update-status-group-lesson.component.css']
})
export class UpdateStatusGroupLessonComponent extends BaseFormComponent {

  @Input() studentName: any;
  @Input() eventID: number;
  @Input() attendeesID: string;
  @Input() status: string;
  @Input() appointmentType: string;
  @Input() eventData;

  lookupIDByStatus(status) {
    if (status) {
      let lookup = this._taxonomyService.lesson_status.find(element => element.name == status);
      return lookup.id;
    }
  }

  override ngAfterContentInit(): void {
    // Set default value(s) for services.
    setTimeout(() => {

      for(var i=0; i < this._taxonomyService.service_status.length; i++) {
        if(this._taxonomyService.service_status[i].name == this.status) {
          if (this.appointmentType == 'services') {
            this.f.form.patchValue({
              field_status: this._taxonomyService.service_status[i].id
            });
          }
          break;
        }
      }

      for(var i=0; i < this._taxonomyService.lesson_status.length; i++) {
        if(this._taxonomyService.lesson_status[i].name == this.status) {
          if (this.appointmentType == 'lesson') {
            this.f.form.patchValue({
              field_status: this._taxonomyService.lesson_status[i].id
            });
          }
          break;
        }
      }
    });
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    let values = form.form.value;

    // Build request...
    let body: { parameter: any, value: any }[] = [];

    // Add params the endpoint is expecting.
    if (this.appointmentType == 'group_lesson') {

      for (const key in values) {
        console.log('key', key)
        console.log('key[values]', values[key])
        let body = {
            field_status: values[key]
        }

        this.errorMessage = '';
        this.successMessage = '';
        this._entityRESTService.patchEntity('attendees', 'attendance', +key, body)
        .subscribe(data => {
          this.successMessage = 'Status updated for the selected event.';

          this.displayProgressSpinner(false);
          this._dialogService.dialog.closeAll();
        },
        error => {
          // console.log('error', error);
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem updating the status of this lesson.";
          if (error?.error?.message) {
            this.errorMessage = error.error.message;
          }
        });

      }
    }
  }

  setAllStatus(status: { toString: () => any; }) {
    for (const property in this.f.value) {
      this.f.form.patchValue({
        [property]: status.toString()
      });
    }
  }

}
