export const environment = {
	production: true,
	name: "Lakeland Studio",
	location: "Lakeland",
	drupalUsername: "",
	drupalPassword: "",
	hostUrl: 'https://lakeland.letsdance.tech',
	drupalUrl: 'https://lakeland.letsdance.tech',
	subFolder: '/app/',
};
