import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class DrupalRESTService implements OnInit {

  baseURL = environment.hostUrl;
  // csrfToken;

  _httpHeaders: HttpHeaders;
  _httpHeadersUpload: HttpHeaders;

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) { this._csrfToken() }

  httpHeaders() {
   this._httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      // 'Authorization': 'Basic ' + btoa(environment.drupalUsername + ':' + environment.drupalPassword),
      'X-CSRF-Token': this._authService.csrf_token,
    })
  }

  ngOnInit(): void {
    // this._csrfToken();
  }

  /**
   * Utility to build our HTTPParams object.
   *
   * @param params
   * @returns HttpParams
   */
  private _httpParams(params ?: { parameter: string, value: string | number }[]) {
    let httpParams = new HttpParams();

    // No params available, just return the httpParams object.
    if (typeof params == 'undefined') {
      return httpParams;
    }

    params.forEach((element, index, array) => {
      httpParams = httpParams.append(element.parameter, element.value)
    });

    return httpParams;
  }

  public _csrfToken() {
    // console.log('_csrfToken called...')
    let endpoint = "/rest/session/token";
    let httpParams = this._httpParams();

    this.http.get(this.baseURL + endpoint, {
      headers: this._httpHeaders,
      params: httpParams,
      responseType: 'text'
    }).subscribe(data => {
      this._authService.csrf_token = data;

      this.httpHeaders();
    })

  }

  /**
   * Standard HTTP GET request to Drupal.
   *
   * @param endpoint
   * @param params
   * @returns Observable
   */
  httpGET(endpoint: string, params ?: { parameter: string, value: string }[]) {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    return this.http.get(this.baseURL + endpoint, {
      headers: this._httpHeaders,
      params: httpParams
    })
      .pipe(
        map(data => {
          return data;
        },
          (error: string) => this.logError(error)
        )
      )
  }

  /**
   * Standard HTTP POST to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpPOST(endpoint: string, body, params ?: { parameter: string, value: string }[]) {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    // Get new token on every post request.
    this._csrfToken();

    return this.http.post(this.baseURL + endpoint, body, {
      headers: this._httpHeaders,
      params: httpParams
    });
  }

  /**
   * Standard HTTP PATCH to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpPATCH(endpoint: string, body: any, params ?: { parameter: string, value: string }[]) {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    return this.http.patch(
      this.baseURL + endpoint,
      body,
      {
        observe: 'response',
        headers: this._httpHeaders,
        params: httpParams,
      }
    );
  }

  /**
   * Standard HTTP DELETE to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpDELETE(endpoint: string, body?: { parameter: string, value: string }[], params?: { parameter: string, value: string }[]) {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    return this.http.delete(this.baseURL + endpoint, {
      headers: this._httpHeaders,
      params: httpParams
    });
  }

  /**
   * Support for uploading files in Drupal.
   */
  uploadFile(entity_type_id: string, bundle: string, field_name: string, fileName: string, body) {
    // /file/upload/{entity_type_id}/{bundle}/{field_name}
    let url = '/file/upload/' + entity_type_id + '/' + bundle + '/' + field_name;

    this._csrfToken();

    let params = [
      { parameter: '_format', value: 'json' },
    ];

    let headers = {
      'Content-Type': 'application/octet-stream',
      'X-CSRF-Token': this._authService.csrf_token,
      'Content-Disposition': `file; filename="${fileName}"`,
    };

    let httpParams = this._httpParams(params);

    return this.http.post(url, body, {
      headers: headers,
      params: httpParams
    })
  }

  /**
   *
   * @param route
   * @param httpParams
   * @returns Observable
   */
  downloadFile(route: string, params) {
    let httpParams = this._httpParams(params);
    return this.http.get<Blob>(this.baseURL + route, {
      headers: this._httpHeaders,
      params: httpParams,
      observe: 'response',
      responseType: 'blob' as 'json'
    })
  }

  private logError(error: string) {
    // do nothing :(
    console.log(error);
  }
}
