<div class="container-fluid">
  <h1 class="page-header">Staff Performance Report</h1>

  <app-report-nav-menu [week]="this.week" [year]="this.year" [currentReport]="this.currentReport"></app-report-nav-menu>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Instructor Autocomplete -->
        <!-- <div class="col-3">
          <mat-form-field class="full-width-field">
              <mat-label>Instructor</mat-label>
              <input
                  name="staff_name"
                  (input)="onAutocompleteInstructor($event)"
                  matInput
                  ngModel
                  [matAutocomplete]="autoCompleteInstructor"
                  > -->
              <!-- <button type="button" matSuffix mat-icon-button
                  aria-label="manage_accounts">
                  <mat-icon>manage_accounts</mat-icon>
              </button> -->
          <!-- </mat-form-field>
          <mat-autocomplete #autoCompleteInstructor="matAutocomplete"
              (optionSelected)="onSubmit(f)">
              <mat-option
                  *ngFor="let option of autocompleteInstructorOptions"
                  [value]="option.label + ' ' + '(' + option.value + ')'">
                  {{option.label}} ({{option.value}})
              </mat-option>
          </mat-autocomplete>
      </div> -->

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Week</mat-label>
            <mat-select matInput [ngModel]=week name="week" (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of WeeksForYearData"
              [value]="element.key">
                {{ element.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Year</mat-label>
            <mat-select
            matInput
            [ngModel]=year
            name="year"
            (selectionChange)="onSubmit(f)">
              <mat-option>None</mat-option>
              <mat-option *ngFor="let element of LastFourYearsData"
              [value]="element.key">
                {{ element.value }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">

          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>
      </div>


      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

  <div class="row">
      <div class="col-md-12 mt-3">
          <div class="float-end">

            <button
              [disabled]="this.isDisabled"
              class="me-1"
              mat-raised-button color="primary"
              (click)="openDialog(SendUpdateAPIComponent, null, {
                endpoint: 'staff_performance_send_api',
                operation: 'Send API',
                form: this.f.form.value
              });">Send API</button>

            <button
              [disabled]="this.isDisabled"
              class="me-1"
              mat-raised-button color="primary"
              (click)="openDialog(SendUpdateAPIComponent, null, {
                endpoint: 'staff_performance_send_api',
                operation: 'Update API',
                form: this.f.form.value
              });">Update API</button>

              <button
                [routerLink]="['/ami-reports']"
                class="me-1"
                mat-raised-button color="secondary"
                >Back to list</button>

          </div>
      </div>
  </div>

  <table class="mat-table cdk-table mat-sort mt-4" role="table"
    *ngIf="data != undefined && data['#staff_header_table'] != undefined">
    <thead role="rowgroup">
      <tr>
        <th class="mat-header-cell">Report Type</th>
        <th class="mat-header-cell">Prepared By</th>
        <th class="mat-header-cell">Year</th>
        <th class="mat-header-cell">Week Number</th>
        <th class="mat-header-cell">Date Range</th>
      </tr>
    </thead>
    <tr>
      <td class="mat-row">{{ data['#staff_header_table']['staff_data_header']['#rows'][0][0] }}</td>
      <td class="mat-row">{{ data['#staff_header_table']['staff_data_header']['#rows'][0][1] }}</td>
      <td class="mat-row">{{ data['#staff_header_table']['staff_data_header']['#rows'][0][2] }}</td>
      <td class="mat-row">{{ data['#staff_header_table']['staff_data_header']['#rows'][0][3] }}</td>
      <td class="mat-row">{{ data['#staff_header_table']['staff_data_header']['#rows'][0][5] | date }} - {{ data['#staff_header_table']['staff_data_header']['#rows'][0][6] | date }}</td>
    </tr>
  </table>

  <div class="table-container">
    <mat-card>
        <mat-card-title>
          Instructors
        </mat-card-title>

        <mat-card-content>
          <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">
            <!-- name Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Name">
                Name
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.name != undefined">{{ element.name }}</ng-container>
              </td>
            </ng-container>

            <!-- staff_type Column -->
            <ng-container matColumnDef="staff_type">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by staff_type">
                Staff Type
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.staff_type != undefined">{{ element.staff_type }}</ng-container>
              </td>
            </ng-container>

            <!-- de/bonus Column -->
            <ng-container matColumnDef="de/bonus">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by de/bonus">
                DE/Bonus
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element['de/bonus'] != undefined">{{ element['de/bonus'] }}</ng-container>
              </td>
            </ng-container>

            <!-- number_of_guest Column -->
            <ng-container matColumnDef="number_of_guest">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number_of_guest">
                Number of Guests
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.number_of_guest != undefined">{{ element.number_of_guest }}</ng-container>
              </td>
            </ng-container>

            <!-- private_lessons Column -->
            <ng-container matColumnDef="private_lessons">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by private_lessons">
                Personal Lessons
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.private_lessons != undefined">{{ element.private_lessons }}</ng-container>
              </td>
            </ng-container>

            <!-- number_in_class Column -->
            <ng-container matColumnDef="number_in_class">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by number_in_class">
                Number in Class
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.number_in_class != undefined">{{ element.number_in_class }}</ng-container>
              </td>
            </ng-container>

            <!-- interview_department Column -->
            <ng-container matColumnDef="interview_department">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by interview_department">
                Interview Dept
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.interview_department != undefined">{{ element.interview_department }}
                </ng-container>
              </td>
            </ng-container>

            <!-- renewal_department Column -->
            <ng-container matColumnDef="renewal_department">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by renewal_department">
                Renewal Dept
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.renewal_department != undefined">{{ element.renewal_department }}</ng-container>
              </td>
            </ng-container>

            <!-- dor_sanct_competition Column -->
            <ng-container matColumnDef="dor_sanct_competition">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by dor_sanct_competition">
                Dor/Sanct. Competition
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.dor_sanct_competition != undefined">{{ element.dor_sanct_competition }}
                </ng-container>
              </td>
            </ng-container>

            <!-- showcase_medal_ball Column -->
            <ng-container matColumnDef="showcase_medal_ball">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by showcase_medal_ball">
                Showcase Medal Ball
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.showcase_medal_ball != undefined">{{ element.showcase_medal_ball }}</ng-container>
              </td>
            </ng-container>

            <!-- party_time_non_unit Column -->
            <ng-container matColumnDef="party_time_non_unit">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by party_time_non_unit">
                Party Time Non-Unit
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.party_time_non_unit != undefined">{{ element.party_time_non_unit }}</ng-container>
              </td>
            </ng-container>

            <!-- REPLACE Column -->
            <!-- <ng-container matColumnDef="REPLACE">
              <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
                REPLACE
              </th>
              <td mat-cell *matCellDef="let element">
                <ng-container *ngIf="element.REPLACE">{{ element.REPLACE }}</ng-container>
              </td>
            </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
  </mat-card-content>
  </mat-card>
  </div>

  <div class="table-container">
    <mat-card *ngIf="this.staffPerformanceExecutives?.[0]">
        <mat-card-title>
          Executives
        </mat-card-title>

        <mat-card-content>

        <table class="mat-table cdk-table mat-sort mt-4" role="table"
            *ngIf="data != undefined && this.staffPerformanceExecutives?.[0] != undefined">
            <thead role="rowgroup">
                <tr>
                    <th class="mat-header-cell">Name</th>
                    <th class="mat-header-cell">Staff Type</th>
                    <th class="mat-header-cell">&nbsp; <!--DE/Bonus--></th>
                    <th class="mat-header-cell">&nbsp; <!--Number of Guests--></th>
                    <th class="mat-header-cell">&nbsp; <!--Private Lessons--></th>
                    <th class="mat-header-cell">&nbsp; <!--Number in Class--></th>
                    <th class="mat-header-cell">Interview Dept</th>
                    <th class="mat-header-cell">Renewal Dept</th>
                    <th class="mat-header-cell">Dor/Sanct. Competition</th>
                    <th class="mat-header-cell">Showcase Medal Ball</th>
                    <th class="mat-header-cell">Party Time Non-Unit</th>
                </tr>
            </thead>
            <tr *ngFor="let refund of this.staffPerformanceExecutives ; index as i;">
                <td class="mat-row">{{ this.staffPerformanceExecutives[i]['name'] }}</td>
                <td class="mat-row">{{ this.staffPerformanceExecutives[i]['staff_type'] }}</td>
                <td class="mat-row">&nbsp; <!--{{ this.staffPerformanceExecutives[i]['de/bonus'] }}>--></td>
                <td class="mat-row">&nbsp; <!--${{ this.staffPerformanceExecutives[i]['number_of_guest'] }}--></td>
                <td class="mat-row">&nbsp; <!--${{ this.staffPerformanceExecutives[i]['private_lessons'] }}--></td>
                <td class="mat-row">&nbsp; <!--${{ this.staffPerformanceExecutives[i]['number_in_class'] }}--></td>
                <td class="mat-row">${{ this.staffPerformanceExecutives[i]['interview_department'] }}</td>
                <td class="mat-row">${{ this.staffPerformanceExecutives[i]['renewal_department'] }}</td>
                <td class="mat-row">${{ this.staffPerformanceExecutives[i]['dor_sanct_competition'] }}</td>
                <td class="mat-row">${{ this.staffPerformanceExecutives[i]['showcase_medal_ball'] }}</td>
                <td class="mat-row">${{ this.staffPerformanceExecutives[i]['party_time_non_unit'] }}</td>
            </tr>
        </table>

        </mat-card-content>
    </mat-card>

    <ng-container *ngIf="this.pageSize == 0">
      <div>
        Please select criteria to generate a report.
      </div>
    </ng-container>

    <mat-paginator style="d-none" (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="10"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

</div>
