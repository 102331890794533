import { ComponentType } from '@angular/cdk/portal';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import moment from 'moment';
import { StudentDashboardWrapperComponent } from '../../student/student-dashboard-wrapper/student-dashboard-wrapper.component';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { CancelFutureAppointmentComponent } from '../cancel-future-appointment/cancel-future-appointment.component';
import { CancelRescheduleComponent } from '../cancel-reschedule/cancel-reschedule.component';
import { EnrollmentEntityComponent } from '../enrollment-entity/enrollment-entity.component';
import { EventGroupLessonEntityComponent } from '../event-group-lesson-entity/event-group-lesson-entity.component';
import { EventLessonEntityComponent } from '../event-lesson-entity/event-lesson-entity.component';
import { EventSchedulesEntityComponent } from '../event-schedules-entity/event-schedules-entity.component';
import { EventServicesEntityComponent } from '../event-services-entity/event-services-entity.component';
import { PaymentsEntityComponent } from '../payments-entity/payments-entity.component';
import { PostLessonComponent } from '../post-lesson/post-lesson.component';

@Component({
  selector: 'app-appointment-details',
  templateUrl: './appointment-details.component.html',
  styleUrls: [
    './appointment-details.component.css',
    '../../../../app.component.css',
  ]
})
export class AppointmentDetailsComponent extends BaseDialogComponent implements OnInit {

  entityID = this.injectedDialogData?.event?._def?.extendedProps?.entityId;
  eventData =  this.injectedDialogData?.event?._def?.extendedProps?.eventDetails;
  attendeesID = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.studentsDashboardStatus?.[0]?.attendees_id;
  studentName = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.name;
  eventID = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.entity_id;
  status = this.injectedDialogData?.event?._def?.extendedProps?.eventDetails.studentsDashboardStatus?.[0]?.status;
  appointmentType = this.injectedDialogData?.event?._def?.extendedProps?.appointmentType;
  isEventPassed = moment(this.injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.date + ' ' + this._injectedDialogData?.event?._def?.extendedProps?.eventDetails?.data?.endTime).isAfter();
  studioInformationConfig: any;
  eventEntityData: Object;
  flag2: boolean;
  flag1: boolean;


  override lessonBlockForm = this._formBuilder.group({
    field_material_covered: [null],
    field_comments: [null],
    field_lesson_rating: [null],
  });

  detectSignatureInstructor: boolean;
  detectSignatureStudent: boolean;

  @ViewChild('SignatureStudent') signatureStudent: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureInstructor') signatureInstructor: ElementRef<HTMLCanvasElement>;

  // Component refs
  EventLessonEntityComponent = EventLessonEntityComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  PaymentEntityComponent = PaymentsEntityComponent;
  EventScheduleEntityComponent = EventSchedulesEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  CancelRescheduleComponent = CancelRescheduleComponent;
  PostLessonComponent = PostLessonComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;
  CancelFutureAppointmentComponent = CancelFutureAppointmentComponent;
  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;

  override ngAfterViewInit() {
    console.log('injecte3d data', this.injectedDialogData);
    // Signature stuff.
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureInstructor, 'instructor');

    // Load studio information from config service.
    this._configPages.get_config('studio_information', 'all')
    .subscribe((data) => {
      this.studioInformationConfig = data;
    });

    if (this.injectedDialogData?.event?._def?.extendedProps?.appointmentType == 'lesson') {
      // this._entityRESTService.getEntity('events', 'lesson', this.entityID).subscribe((data) => {
      //   this.eventEntityData = this.entityData = data;
      //   // this.setFormValues(this.lessonBlockForm)
      // });

      this.loadLessonData(this.entityID).subscribe((data) => {
        this.eventEntityData = data;
      });
    }

    // Lesson blocks form load.
    this.getEntity('events', 'lesson', this.entityID, null);
    this.loadLessonData(this.entityID).subscribe((data) => {

    });
    // this.getEntity('events', 'lesson', this.entityID, this.lessonBlockForm);
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  openDialog(component: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} }, data) {
    let dialogRef = this._dialogService.openDialog(component, dialogConfig ?? "defaultWithData", {
      data,
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
    });
  }

  override openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    console.log('fieldsData', fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID ?? '',
        eckType: eckType ?? '',
        bundle: bundle ?? '',
        action: action ?? '',
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      // this.refreshCalendar();
    });
  }

  nextPaymentOnly(field_scheduled_payments?) {
    if (field_scheduled_payments) {
      let only1 = false;
      return field_scheduled_payments.filter((field_scheduled_payment) => {
        if (only1 == false && moment(field_scheduled_payment.field_payment_date).isSameOrAfter(moment().subtract(1, "days"))) {
          only1 = true;
          return true;
        }
      })
    }
  }

  lessonBlockFormSubmit(f?) {
    this.displayProgressSpinner(false);


    if (this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] != null) {
      this.flag1 = true;
      this.flag2 = true;
      this.referenceUploadedFileComplete();
    }

    // Prevent uploading blank signatures on edit.
    if (this.eventEntityData?.['field_instructor_signature']?.[0]?.['url'] == null) {
      let signatureStudent = this.signatureStudent.nativeElement.toBlob(blob => {
        this._drupalRESTService.uploadFile('events', 'lesson', 'field_signature_student' , 'signature_student_lesson_block_' + this.entityID + '.png', blob)
        .subscribe(data => {
          if (data['fid']?.[0]?.['value']) {
            let values =
              {
                "field_signature_student": [
                  {
                    "target_id": data['fid'][0]['value'],
                    "description": ""
                  }
                ],
              };

            if (typeof this.entityData?.['id'] == 'undefined') {
              alert('Missing entity ID.')
            } else {
              this._entityRESTService.patchEntity('events', 'lesson', this.entityID, values)
              .subscribe(data => {
                this.flag1 = true;
                this.referenceUploadedFileComplete();
              });
            }
          }

        });
      });

      let signatureInstructor = this.signatureInstructor.nativeElement.toBlob(blob => {
        this._drupalRESTService.uploadFile('events', 'lesson', 'field_instructor_signature', 'signature_instructor_lesson_block_' + this.entityID + '.png', blob)
        .subscribe(data => {
          if (data['fid']?.[0]?.['value']) {
            let values =
              {
                "field_instructor_signature": [
                  {
                    "target_id": data['fid'][0]['value'],
                    "description": ""
                  }
                ],
              };

            if (typeof this.entityData?.['id'] == 'undefined') {
              alert('Missing entity ID.')
              } else {
              this._entityRESTService.patchEntity('events', 'lesson', this.entityID, values)
              .subscribe(data => {
                this.flag2 = true;
                this.referenceUploadedFileComplete();
              });
            }
          }
        });
      });
    }
  }

  signatureClearStudent() {
    this.signatureClear(this.signatureStudent);
    this.detectSignatureStudent = false;
  }

  signatureClearInstructor() {
    this.signatureClear(this.signatureInstructor);
    this.detectSignatureInstructor = false;
  }

  signatureClear(elementRef: ElementRef<HTMLCanvasElement>) {
    let canvas = elementRef;

    let context = canvas.nativeElement.getContext( '2d' );
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
  }

  referenceUploadedFileComplete() {
    if (!this.flag1 || !this.flag2) {
      return;
    }

    // Save the entity. Close window.
    this._entityRESTService.patchEntity('events', 'lesson', this.entityID, this.lessonBlockForm.value)
    .subscribe(data => {
      // TODO: Respond to successful update message...
      console.log('data', data);

      this.closeDialog();
      this.displayProgressSpinner(false);
    },
      error => this.handleError(error)
    )

  }

  signatureInit(elementRef: ElementRef<HTMLCanvasElement>, elementName: string) {
    // Signature Stuff
    let canvas = elementRef;
    let context = canvas?.nativeElement?.getContext( '2d' );

    if (!context) { return };

    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 3;

    let x = 0, y = 0;
    let isMouseDown = false;

    let stopDrawing = () => { isMouseDown = false; }
    let startDrawing = (event: MouseEvent) => {
        if (elementName == 'student') {
          this.detectSignatureStudent = true;
        }
        if (elementName == 'instructor') {
          this.detectSignatureInstructor = true;
        }
        isMouseDown = true;
      [x, y] = [event.offsetX, event.offsetY];
    }
    let drawLine = (event: any) => {
      let newY, newX;

      if ( isMouseDown ) {
        newX = event.offsetX;
        newY = event.offsetY;

        context.beginPath();
        context.moveTo( x, y );
        context.lineTo( newX, newY );
        context.stroke();
        x = newX;
        y = newY;
      }
    }

    // Add event listeners.
    if (this.isTablet) {
      // Support tablets.
      canvas.nativeElement.addEventListener( 'touchstart', startDrawing );
      canvas.nativeElement.addEventListener( 'pointermove', drawLine );
      canvas.nativeElement.addEventListener( 'touchend', stopDrawing );
      canvas.nativeElement.addEventListener( 'touchcancel', stopDrawing );
    } else {
      canvas.nativeElement.addEventListener( 'mousedown', startDrawing );
      canvas.nativeElement.addEventListener( 'mousemove', drawLine );
      canvas.nativeElement.addEventListener( 'mouseup', stopDrawing );
      canvas.nativeElement.addEventListener( 'mouseout', stopDrawing );
    }
}

}
