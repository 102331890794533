<!-- Main page layout handling. -->
<app-menu></app-menu>

<!-- Router View -->
<div class="">
  <router-outlet></router-outlet>
</div>

<button mat-fab color="primary"
(click)="openDialog(supportRequestForm, 'statusFormWithData', {} )"
style="position: fixed; left: 10px; bottom:25px; z-index: 9999;">
  <mat-icon>contact_support</mat-icon>
</button>
