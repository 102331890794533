import { Injectable } from '@angular/core';
import moment from 'moment';
import { DrupalRESTService } from './drupal-rest.service';
import { TaxonomyService } from './taxonomy.service';

@Injectable({
  providedIn: 'root'
})
export class FieldsService {

  instructorsData = undefined;
  executiveData: any;
  juniorExecutiveData: any;

  constructor(
    private _drupalRESTService: DrupalRESTService,
    private _taxonomyService: TaxonomyService,
    ) {
  }

  dance_types = [
    {key: "waltz", value: "Waltz"},
    {key: "tango", value: "Tango"},
    {key: "foxtrot", value: "Foxtrot"},
    {key: "cha cha", value: "Cha Cha"},
    {key: "rumba", value: "Rumba"},
    {key: "swing", value: "Swing"},
    {key: "salsa", value: "Salsa"},
    {key: "bachata", value: "Bachata"},
    {key: "merengue", value: "Merengue"},
    {key: "country western", value: "Country Western"},
    {key: "wc swing", value: "WC Swing"},
    {key: "other", value: "Other"},
  ];

  field_inquiry_method = [
    {key: "Internet", value: "Internet"},
    {key: "Phone", value: "Phone"},
    {key: "Walk-in", value: "Walk-in"},
    // {key: "waltz", value: "Waltz"},
    {key: "Other", value: "Other"},
    {key: "Guest", value: "Guest"},
  ];

  studentDepartmentData() {
    return [
      {key: "75", value: "Front Dept"},
      {key: "76", value: "Middle Dept"},
      {key: "77", value: "Back Dept"},
    ]
  }

  instructorData() {
    return this.instructorsData;
  }

  instructorsExecutive() {
    return this.executiveData;
  }

  instructorsJuniorExecutive() {
    return this.juniorExecutiveData;
  }

  getInstructorData() {
    let endpoint = "/api/v1/loadInstructors";
    if (this.instructorsData) { return this.instructorsData }
    return new Promise((resolve, reject) => {
      this._drupalRESTService.httpGET(endpoint).subscribe(data => {
        this.instructorsData = data['instructors'];
        this.executiveData = data['executives'];
        this.juniorExecutiveData = data['executives'];

        resolve(true);
      });
    })
  }

  getInstructorDataPromise() {
    return this._drupalRESTService.httpGET('/api/v1/loadInstructors');
  }

  standingTypeData() {
    return [
      { key: "standing", value: "Standing Lessons" },
      { key: "non-standing", value: "Non-Standing Lessons" },
    ]
  }

  lessonTypeData = [
    { key: "lesson", value: "Lesson" },
    { key: "services", value: "Services" },
    { key: "schedules", value: "Schedules" },
    { key: "group_lesson", value: "Group Lessons" },
  ];

  statusTargetData() {
    return this._taxonomyService;
    // return [
    //   { key: "63", value: "Cancelled" },
    //   { key: "61", value: "No Showed Not Charged" },
    //   { key: "60", value: "No Showed, Charged" },
    //   { key: "64", value: "Pending Status" },
    //   { key: "62", value: "Rescheduled" },
    //   { key: "59", value: "Showed" },
    // ]
  }

  CalendarWeekViewForYearData(week53: boolean = false) {
    let weeks = [];
    for (var i = 1; i <= (week53 ? 53 : 52); i++) {
      weeks.push(
        { key: moment().day("Monday").week(i).format('YYYY-MM-DD'), value: "Week " + i.toString().padStart(2, '0') + ", " + moment().format("YYYY") })
    }
    return weeks;
  }

  WeeksForYearData() {
    let weeks = [];
    for (var i = 1; i <= 53; i++) {
      weeks.push(
        { key: i.toString(), value: i.toString() })
    }
    return weeks;
  }

  LastFourYearsData() {
    let years = [];
    for (var i = 0; i <= 3; i++) {
      years.push(
        { key: (moment().year() - i).toString(), value: (moment().year() - i).toString() }
      )
    }
    return years;
  }

  getTaxRateConfig() {
    return this._drupalRESTService.httpGET('/api/v1/configPages?type=tax&field=field_tax_rate')
  }

  getRoyaltyPercentageConfig() {
    // return this._drupalRESTService.httpGET('/api/v1/configPages?type=ami_royalty&field=field_royalty_percentage')
    return this._drupalRESTService.httpGET('/api/v1/configPages?type=ami_royalty&field=all')
  }

  discountType = [
    { key: 'percentage', value: 'Percentage', },
    { key: 'flat', value: 'Flat', },
  ]

  StatesAbbreviations = [
      {
        name: "Alabama",
        abbreviation: "AL"
      },
      {
        name: "Alaska",
        abbreviation: "AK"
      },
      {
        name: "American Samoa",
        abbreviation: "AS"
      },
      {
        name: "Arizona",
        abbreviation: "AZ"
      },
      {
        name: "Arkansas",
        abbreviation: "AR"
      },
      {
        name: "California",
        abbreviation: "CA"
      },
      {
        name: "Colorado",
        abbreviation: "CO"
      },
      {
        name: "Connecticut",
        abbreviation: "CT"
      },
      {
        name: "Delaware",
        abbreviation: "DE"
      },
      {
        name: "District Of Columbia",
        abbreviation: "DC"
      },
      {
        name: "Federated States Of Micronesia",
        abbreviation: "FM"
      },
      {
        name: "Florida",
        abbreviation: "FL"
      },
      {
        name: "Georgia",
        abbreviation: "GA"
      },
      {
        name: "Guam",
        abbreviation: "GU"
      },
      {
        name: "Hawaii",
        abbreviation: "HI"
      },
      {
        name: "Idaho",
        abbreviation: "ID"
      },
      {
        name: "Illinois",
        abbreviation: "IL"
      },
      {
        name: "Indiana",
        abbreviation: "IN"
      },
      {
        name: "Iowa",
        abbreviation: "IA"
      },
      {
        name: "Kansas",
        abbreviation: "KS"
      },
      {
        name: "Kentucky",
        abbreviation: "KY"
      },
      {
        name: "Louisiana",
        abbreviation: "LA"
      },
      {
        name: "Maine",
        abbreviation: "ME"
      },
      {
        name: "Marshall Islands",
        abbreviation: "MH"
      },
      {
        name: "Maryland",
        abbreviation: "MD"
      },
      {
        name: "Massachusetts",
        abbreviation: "MA"
      },
      {
        name: "Michigan",
        abbreviation: "MI"
      },
      {
        name: "Minnesota",
        abbreviation: "MN"
      },
      {
        name: "Mississippi",
        abbreviation: "MS"
      },
      {
        name: "Missouri",
        abbreviation: "MO"
      },
      {
        name: "Montana",
        abbreviation: "MT"
      },
      {
        name: "Nebraska",
        abbreviation: "NE"
      },
      {
        name: "Nevada",
        abbreviation: "NV"
      },
      {
        name: "New Hampshire",
        abbreviation: "NH"
      },
      {
        name: "New Jersey",
        abbreviation: "NJ"
      },
      {
        name: "New Mexico",
        abbreviation: "NM"
      },
      {
        name: "New York",
        abbreviation: "NY"
      },
      {
        name: "North Carolina",
        abbreviation: "NC"
      },
      {
        name: "North Dakota",
        abbreviation: "ND"
      },
      {
        name: "Northern Mariana Islands",
        abbreviation: "MP"
      },
      {
        name: "Ohio",
        abbreviation: "OH"
      },
      {
        name: "Oklahoma",
        abbreviation: "OK"
      },
      {
        name: "Oregon",
        abbreviation: "OR"
      },
      {
        name: "Palau",
        abbreviation: "PW"
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA"
      },
      {
        name: "Puerto Rico",
        abbreviation: "PR"
      },
      {
        name: "Rhode Island",
        abbreviation: "RI"
      },
      {
        name: "South Carolina",
        abbreviation: "SC"
      },
      {
        name: "South Dakota",
        abbreviation: "SD"
      },
      {
        name: "Tennessee",
        abbreviation: "TN"
      },
      {
        name: "Texas",
        abbreviation: "TX"
      },
      {
        name: "Utah",
        abbreviation: "UT"
      },
      {
        name: "Vermont",
        abbreviation: "VT"
      },
      {
        name: "Virgin Islands",
        abbreviation: "VI"
      },
      {
        name: "Virginia",
        abbreviation: "VA"
      },
      {
        name: "Washington",
        abbreviation: "WA"
      },
      {
        name: "West Virginia",
        abbreviation: "WV"
      },
      {
        name: "Wisconsin",
        abbreviation: "WI"
      },
      {
        name: "Wyoming",
        abbreviation: "WY"
      }
    ];

  durations = [
      {value: "00:15"},
      {value: "00:30"},
      {value: "00:45"},
      {value: "01:00"},
      {value: "01:15"},
      {value: "01:30"},
      {value: "01:45"},
      {value: "02:00"},
      {value: "02:15"},
      {value: "02:30"},
      {value: "02:45"},
      {value: "03:00"},
      {value: "03:15"},
      {value: "03:30"},
      {value: "03:45"},
      {value: "04:00"},
      {value: "04:15"},
      {value: "04:30"},
      {value: "04:45"},
      {value: "05:00"},
      {value: "05:15"},
      {value: "05:30"},
      {value: "05:45"},
      {value: "06:00"},
      {value: "06:15"},
      {value: "06:30"},
      {value: "06:45"},
      {value: "07:00"},
      {value: "07:15"},
      {value: "07:30"},
      {value: "07:45"},
      {value: "08:00"},
      {value: "08:15"},
      {value: "08:30"},
      {value: "08:45"},
      {value: "09:00"}
    ];

  repetition_units = [
    { value: "Weekly"},
    { value: "EOW"},
    { value: "Monthly"},
    { value: "Days"},
    { value: "Weeks"},
    { value: "Months"},
  ];



  dayDropdown = [
    {
      value: '01',
      name: 'First'
    },
    {
      value: '02',
      name: 'Second'
    },
    {
      value: '03',
      name: 'Third'
    },
    {
      value: '04',
      name: 'Fourth'
    },
    {
      value: '05',
      name: 'Fifth'
    },
    {
      value: '06',
      name: 'Sixth'
    },
    {
      value: '07',
      name: 'Seventh'
    },
    {
      value: '08',
      name: 'Eighth'
    },
    {
      value: '09',
      name: 'Ninth'
    },
    {
      value: '10',
      name: 'Tenth'
    },
    {
      value: '11',
      name: 'Eleventh'
    },
    {
      value: '12',
      name: 'Twelfth'
    },
    {
      value: '13',
      name: 'Thirteenth'
    },
    {
      value: '14',
      name: 'Fourteenth'
    },
    {
      value: '15',
      name: 'Fifteenth'
    },
    {
      value: '16',
      name: 'Sixteenth'
    },
    {
      value: '17',
      name: 'Seventeenth'
    },
    {
      value: '18',
      name: 'Eighteenth'
    },
    {
      value: '19',
      name: 'Nineteenth'
    },
    {
      value: '20',
      name: 'Twentieth'
    },
    {
      value: '21',
      name: 'Twenty-first'
    },
    {
      value: '22',
      name: 'Twenty-second'
    },
    {
      value: '23',
      name: 'Twenty-third'
    },
    {
      value: '24',
      name: 'Twenty-fourth'
    },
    {
      value: '25',
      name: 'Twenty-fifth'
    },
    {
      value: '26',
      name: 'Twenty-sixth'
    },
    {
      value: '27',
      name: 'Twenty-seventh'
    },
    {
      value: '28',
      name: 'Twenty-eighth'
    },
    {
      value: '29',
      name: 'Twenty-ninth'
    },
    {
      value: '30',
      name: 'Thirtieth'
    },
    {
      value: '31',
      name: 'Thirty-first'
    },
    {
      value: '-1',
      name: 'Last'
    },
    {
      value: '-2',
      name: 'Penultimate'
    },
    {
      value: '-3',
      name: 'Third to last'
    },
    {
      value: '-4',
      name: 'Fourth to last'
    },
    {
      value: '-5',
      name: 'Fifth to last'
    },
    {
      value: '-6',
      name: 'Sixth to last'
    },
    {
      value: '-7',
      name: 'Seventh to last'
    },
    {
      value: '-8',
      name: 'Eighth to last'
    },
    {
      value: '-9',
      name: 'Ninth to last'
    },
    {
      value: '-10',
      name: 'Tenth to last'
    },
    {
      value: '-11',
      name: 'Eleventh to last'
    },
    {
      value: '-12',
      name: 'Twelfth to last'
    },
    {
      value: '-13',
      name: 'Thirteenth to last'
    },
    {
      value: '-14',
      name: 'Fourteenth to last'
    },
    {
      value: '-15',
      name: 'Fifteenth to last'
    },
    {
      value: '-16',
      name: 'Sixteenth to last'
    },
    {
      value: '-17',
      name: 'Seventeenth to last'
    },
    {
      value: '-18',
      name: 'Eighteenth to last'
    },
    {
      value: '-19',
      name: 'Nineteenth to last'
    },
    {
      value: '-20',
      name: 'Twentieth to last'
    },
    {
      value: '-21',
      name: 'Twenty-first to last'
    },
    {
      value: '-22',
      name: 'Twenty-second to last'
    },
    {
      value: '-23',
      name: 'Twenty-third to last'
    },
    {
      value: '-24',
      name: 'Twenty-fourth to last'
    },
    {
      value: '-25',
      name: 'Twenty-fifth to last'
    },
    {
      value: '-26',
      name: 'Twenty-sixth to last'
    },
    {
      value: '-27',
      name: 'Twenty-seventh to last'
    },
    {
      value: '-28',
      name: 'Twenty-eighth to last'
    },
    {
      value: '-29',
      name: 'Twenty-ninth to last'
    },
    {
      value: '-30',
      name: 'Thirtieth to last'
    }
  ];

  dayDropdownSimple = [
    {
      value: '01',
      name: 'First'
    },
    {
      value: '02',
      name: 'Second'
    },
    {
      value: '03',
      name: 'Third'
    },
    {
      value: '04',
      name: 'Fourth'
    },
    {
      value: '05',
      name: 'Fifth'
    },
    {
      value: '06',
      name: 'Sixth'
    },
    {
      value: '07',
      name: 'Seventh'
    },
    {
      value: '08',
      name: 'Eighth'
    },
    {
      value: '09',
      name: 'Ninth'
    },
    {
      value: '10',
      name: 'Tenth'
    },
    {
      value: '11',
      name: 'Eleventh'
    },
    {
      value: '12',
      name: 'Twelfth'
    },
    {
      value: '13',
      name: 'Thirteenth'
    },
    {
      value: '14',
      name: 'Fourteenth'
    },
    {
      value: '15',
      name: 'Fifteenth'
    },
    {
      value: '16',
      name: 'Sixteenth'
    },
    {
      value: '17',
      name: 'Seventeenth'
    },
    {
      value: '18',
      name: 'Eighteenth'
    },
    {
      value: '19',
      name: 'Nineteenth'
    },
    {
      value: '20',
      name: 'Twentieth'
    },
    {
      value: '21',
      name: 'Twenty-first'
    },
    {
      value: '22',
      name: 'Twenty-second'
    },
    {
      value: '23',
      name: 'Twenty-third'
    },
    {
      value: '24',
      name: 'Twenty-fourth'
    },
    {
      value: '25',
      name: 'Twenty-fifth'
    },
    {
      value: '26',
      name: 'Twenty-sixth'
    },
    {
      value: '27',
      name: 'Twenty-seventh'
    },
    {
      value: '28',
      name: 'Twenty-eighth'
    },
    {
      value: '29',
      name: 'Twenty-ninth'
    },
    {
      value: '30',
      name: 'Thirtieth'
    },
    {
      value: '31',
      name: 'Thirty-first'
    }
  ];

  reasons_to_refund = [
    {
      key: "duplicate",
      value: "Duplicate Charge",
    },
    {
      key: "fraudulent",
      value: "Fraudulent Charge",
    },
    {
      key: "requested_by_customer",
      value: "Requested by Customer",
    },
  ]

  reasons_to_delete_payment = [
    {
      key: "no_reason",
      value: "No reason",
    },
    {
      key: "accidental",
      value: "Accidental Charge",
    },
    {
      key: "correction",
      value: "Correction",
    },
  ]
}
