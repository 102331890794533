import { Component } from '@angular/core';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DrupalRESTService } from './services/drupal-rest.service';
import { UtilityService } from 'src/app/services/utility.service';
import { FieldsService } from './services/fields.service';
import { TaxonomyService } from './services/taxonomy.service';
import { AuthRESTService } from './services/auth-rest.service';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuRESTService } from './services/menu-rest.service';
import { BaseFormComponent } from './components/forms/base-form/base-form.component';
import { SupportRequestComponent } from './components/forms/support-request/support-request.component';
import { ComponentType } from '@angular/cdk/overlay';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // styleUrls: ['./app.component.css'],
})
export class AppComponent extends BaseFormComponent {

  supportRequestForm = SupportRequestComponent;

  ngOnInit() {
    this._fieldsService.getInstructorData();
    this._taxonomyService.initTaxonomyTerms();

    // Test if user is logged in when app initializes.
    // TODO: Move this into an app initilizer.
    this._authRESTService.test_login()
      .subscribe(response => {
        if (response['success'] == true) {
          this._authService.current_user = response['current_user'];
          // TODO this needs to pull csrf token and logout_token
          this._authService.csrf_token = response['csrf_token'];
          this._authService.logout_token = response['logout_token'];

          // Let Angular know the user is logged in.
          this._authService.set_user_authenticated(true);
          // Initialize the menu.
          this._menuRESTService.getMenu();
          // Navigate to dashboard.
          // this._router.navigate(['dashboard']);
        } else {
          // User needs to login.
          this._authService.set_user_authenticated(false);
          this._router.navigate(['auth/login']);
        }
      })
  }


    /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
    public openDialog(formName: ComponentType<unknown>, configName?, data?) {
      this._dialogService.openDialog(formName, configName, data)
      .afterClosed()
      .subscribe(data => {
      });
    }

}
