import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import _, { unset } from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseFormComponent } from '../base-form/base-form.component';
import { EventServicesEntityComponent } from '../event-services-entity/event-services-entity.component';

@Component({
  selector: 'app-student-inquiry-entity',
  templateUrl: './student-inquiry-entity.component.html',
  styleUrls: ['./student-inquiry-entity.component.css']
})
export class StudentInquiryEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "student_accounts";
  override bundle = "student_account";
  override entityId = this._injectedDialogData['data']['EntityID'];

  contactList = [];
  field_contacts = [];
  checked = true;
  studentAccountCreated: boolean = false;
  studentAccountData;
  override field_date_and_time = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  field_inquired = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _field_expiration_date = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _field_sale_date = this._injectedDialogData['data']['fieldsData']['dayview_field_date_and_time'];
  _inquiry = this._injectedDialogData['data']['fieldsData']['inquiry'];

  override onSubmit(f: NgForm, promptScheduleService = false) {
    this.displayProgressSpinner(true);
    // Copy the object to not modify binded data.
    let values = _.cloneDeep(f.form.value);
    let requested_date_and_time = moment(values.requested_date_time).format('YYYY-MM-DDTHH:mm:ss');

    this.__alterValues(values);

    // Fix for "field_teacher" being a required field when it should not.
    if (values.field_teacher == "") { delete values.field_teacher; }

    // Use the requested date and time (for the enrollment).
    values.field_inquired = this.field_inquired; // contact date
    this._field_expiration_date = values.requested_date_time;
    this._field_sale_date = values.requested_date_time;

    // Prep the data to be send.
    let studentBody = {
      ...values,
      field_contacts: {
        ...values.field_contacts,
      },
      field_inquired: moment(values.field_inquired).format('YYYY-MM-DD'),
      field_archive: "0",
    };

    let createStudentAccount = this._entityRESTService.createEntity('student_accounts', 'student_account', studentBody, [{'parameter': '_format', 'value': 'json' }]);
    let schedule_first_lesson = Boolean(studentBody?.['requested_date_time'] != "" && studentBody?.['field_teacher'] && studentBody?.['lesson_type']);
    let create_de_enrollment = Boolean(schedule_first_lesson && studentBody?.['lesson_type'] == 560);
    let create_preoriginal_enrollment = Boolean(schedule_first_lesson && studentBody?.['lesson_type'] == 1393);

    if (this.studentAccountCreated == false) {
      createStudentAccount.subscribe(data => {
        this.displayProgressSpinner(false);
        this.studentAccountCreated = true;
        this.studentAccountData = data;

        // This is triggered by the "Save and Schedule Service" button.
        if (promptScheduleService) {
          // Potentially have more than one student in the service.
          let field_students = [];
          data['field_contacts'].forEach(contact => {
            field_students.push(contact.target_id.toString())
          });

          // Date and time should be 15 minutes before the scheduled lesson.
          let field_date_and_time = moment(requested_date_and_time).subtract(15, 'minutes');;

          let fieldsData = {
            'field_inquiry_taker': studentBody?.field_inquiry_taker,
            'field_duration': '00:15',
            'field_date_and_time': field_date_and_time,
            '___field_student': {
              type: 'attendees',
              bundle: 'attendance',
              field_student_account: data['title'][0].value.toString() + ' (' + data['id'][0].value.toString() + ') ',
              field_students: field_students.toString(),
            },
          }

          this._dialogService.openDialog(EventServicesEntityComponent, "defaultWithData", {
            data: {
              EntityID: null,
              eckType: 'events',
              bundle: 'services',
              action: 'create',
              fieldsData: fieldsData ?? '',
            },
          }).afterClosed().subscribe(data => {
          });
        }

        // Convenience functionality to create a DE enrollment.
        if (create_de_enrollment && schedule_first_lesson) {
          let enrollmentBody =
          {
            "field_draft": false,
            "field_student": this.studentAccountData['id'][0].value,
            "field_sale_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
            "field_expiration_date": moment(this._field_expiration_date).add(4, 'weeks').format('YYYY-MM-DD'),
            "field_category": "1395",
            // "field_enrollment_package_name":"64777",
            "field_enrollment_lesson_price": 0,
            "field_enrollment_lesson_count": 1,
            "field_total_lessons_price": 0,
            "field_enrollment_total_price": 0,
            "field_taxes": 0,
            "field_tax_percentage": "0",
            "field_total_paid": null,
            "field_lesson_used": null,
            "field_lessons_paid": null,
            "field_lesson_available": null,
            "field_payments_structure": "paid_in_full",
            // "field_executive": studentBody['field_teacher'],
            "field_junior_executive": null,
            "field_scheduled_payments": [
              {
                "field_amount_paid": null,
                "field_enrollment": null,
                "field_paid_in_full": true,
                "field_down_payment": true,
                "field_payment_amount": 0,
                "field_payment_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
                "bundle": "scheduled_payments",
                "type": "scheduled_payments"
              }
            ],
            "field_instructor_percentages": [
              {
                "field_amount": 0,
                "field_instructor": studentBody['field_teacher'],
                "field_percentage": 100,
                "bundle": "instructor_percentages",
                "type": "instructor_percentages"
              }
            ],
            "field_interval_unit": "",
            "field_discount": 0,
            "field_discount_type": "flat",
            "field_enrollment_status": "Open",
            "field_next_scheduled_payment": "",
            "field_visibility": false,
            "field_notes": ""
          };

          // Create the enrollment for the student.
          this._entityRESTService.createEntity('packages', 'enrollment', enrollmentBody).subscribe(data => {
            let enrollmentData = data;
            console.log('enrollmentData', enrollmentData);
            // After the enrollment is created, create the first lesson.
            //  - Convenience functionality to schedule first lesson.
            if (schedule_first_lesson) {
              let eventBody = {
                "field_student": [
                  {
                    "type": "attendees",
                    "bundle": "attendance",
                    "field_status": "64",
                    "field_student_account": this.studentAccountData['id'][0].value,
                    "field_students": (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
                    "field_enrollment": { target_id: enrollmentData['id'][0]['value'] } // Add enrollment here.
                  }
                ],
                "field_type": studentBody['lesson_type'],
                "field_instructor": { target_id: studentBody['field_teacher'] }, // data['field_teacher'][0].target_id
                "field_duration": "00:45",
                "field_date_and_time": requested_date_and_time,
              };

              // Get default duration times.
              let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
              eventBody = {
                ...eventBody,
                field_duration: _duration
              }

              // Create's the first event for the student.
              this._entityRESTService.createEntity('events', 'lesson', eventBody).subscribe(data => {
                this.refreshCalendar();
              }, error => {
                // TODO
              })
            }
          }, error => {
            // TODO
          })
        }

        // Convenience functionality to create a Pre Original enrollment.
        if (create_preoriginal_enrollment && schedule_first_lesson) {

          // First, load the pre original package because we need price data.
          this._entityRESTService.getEntity('packages', 'package', 65605).subscribe(packageEntity => {
            let preOriginalPackage = packageEntity;
            let preOriginalPackagePrice = preOriginalPackage?.['field_lesson_price'] ?? 25;

            let enrollmentBody =
            {
              "field_draft": false,
              "field_student": this.studentAccountData['id'][0].value,
              "field_sale_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
              "field_expiration_date": moment(this._field_expiration_date).add(4, 'weeks').format('YYYY-MM-DD'),
              "field_category": "49",
              "field_enrollment_package_name": "65605",
              "field_enrollment_lesson_price": preOriginalPackagePrice,
              "field_enrollment_lesson_count": 1,
              "field_total_lessons_price": preOriginalPackagePrice,
              "field_enrollment_total_price": preOriginalPackagePrice,
              "field_taxes": 0,
              "field_tax_percentage": "0",
              "field_total_paid": null,
              "field_lesson_used": null,
              "field_lessons_paid": null,
              "field_lesson_available": null,
              "field_payments_structure": "paid_in_full",
              // "field_executive": studentBody['field_teacher'],
              "field_junior_executive": null,
              "field_scheduled_payments": [
                {
                  "field_amount_paid": null,
                  "field_enrollment": null,
                  "field_paid_in_full": true,
                  "field_down_payment": true,
                  "field_payment_amount": preOriginalPackagePrice,
                  "field_payment_date": moment(this._field_sale_date).format("YYYY-MM-DD"),
                  "bundle": "scheduled_payments",
                  "type": "scheduled_payments"
                }
              ],
              "field_instructor_percentages": [
                {
                  "field_amount": preOriginalPackagePrice,
                  "field_instructor": studentBody['field_teacher'],
                  "field_percentage": 100,
                  "bundle": "instructor_percentages",
                  "type": "instructor_percentages"
                }
              ],
              "field_interval_unit": "",
              "field_discount": 0,
              "field_discount_type": "flat",
              "field_enrollment_status": "Open",
              "field_next_scheduled_payment": "",
              "field_visibility": false,
              "field_notes": ""
            };

            // Create the enrollment for the student.
            this._entityRESTService.createEntity('packages', 'enrollment', enrollmentBody).subscribe(data => {
              let enrollmentData = data;
              // After the enrollment is created, create the first lesson.
              //  - Convenience functionality to schedule first lesson.
              if (schedule_first_lesson) {
                let eventBody = {
                  "field_student": [
                    {
                      "type": "attendees",
                      "bundle": "attendance",
                      "field_student_account": this.studentAccountData['id'][0].value,
                      "field_students": (this.studentAccountData?.['field_contacts']?.[1]?.target_id) ? [this.studentAccountData['field_contacts'][0].target_id, this.studentAccountData['field_contacts'][1].target_id] : this.studentAccountData['field_contacts'][0].target_id, // field_contacts, default to the couple value.
                      "field_status": "64",
                      "field_enrollment": { target_id: enrollmentData['id'][0]['value'] } // Add enrollment here.
                    }
                  ],
                  "field_type": studentBody['lesson_type'],
                  "field_instructor": { target_id: studentBody['field_teacher'] }, // data['field_teacher'][0].target_id
                  "field_duration": "00:45",
                  "field_date_and_time": requested_date_and_time,
                };

                // Get default duration times.
                let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
                eventBody = {
                  ...eventBody,
                  field_duration: _duration
                }

                // Create's the first event for the student.
                this._entityRESTService.createEntity('events', 'lesson', eventBody).subscribe(data => {
                }, error => {
                  // TODO
                })
              }
            }, error => {
              // TODO
            })

          })
        }

        // Convenience functionality to schedule first lesson.
        if (schedule_first_lesson && !create_de_enrollment && !create_preoriginal_enrollment) {
          let eventBody: any = {
            "field_student": [
              {
                "type": "attendees",
                "bundle": "attendance",
                "field_student_account": this.studentAccountData['id'][0].value,
                "field_students": this.studentAccountData?.['field_contacts']?.[0]?.target_id, // field_contacts
                "field_status": "64",
              }
            ],
            "field_type": studentBody['lesson_type'],
            "field_instructor": { target_id: studentBody['field_teacher'] }, // data['field_teacher'][0].target_id
            // "field_duration": "00:45",
            "field_date_and_time": requested_date_and_time,
          };

          // Get default duration times.
          let _duration = this.updateLessonDefaultTimes(studentBody['lesson_type']);
          eventBody = {
            ...eventBody,
            field_duration: _duration
          }

          // Create's the first event for the student.
          this._entityRESTService.createEntity('events', 'lesson', eventBody).subscribe(data => {
            this.refreshCalendar();
          }, error => {
            // TODO
          })
        }

        this.closeDialog();

      },
        error => this.handleError(error)
      )
    }
  }

  scheduleFirstLesson() {

  }

  studentUpdateLoad = (index: number): void => {
    // console.log('UpdateLoad called...')
    this.editIndex = index;
    this.editMode = true;
    this.f.controls['__field_contact_inline_form'].patchValue(this.field_contacts[index])
  }

  studentRemove = (index: number): void => {
    // Reset the field contacts group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
    this.field_contacts.splice(index, 1);
  }

  studentUpdateSubmit(number, contact: any) {
    this.field_contacts[this.editIndex] = contact;
    this.f.form.controls['__field_contact_inline_form'].reset();
    this.editMode = false;
  }

  studentIncrement(contact: any) {
    // Append type & bundle to model.
    contact = {
      ...contact,
      type: "contacts",
      bundle: "student_record",
    }

    // If our data binding fails us (API needs to give an array), set as an empty array.
    if (!Array.isArray(this.field_contacts)) { this.field_contacts = [] }

    this.field_contacts.push(contact);

    // Reset the field contact group control.
    this.f.form.controls['__field_contact_inline_form'].reset();
  }

}
