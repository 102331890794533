import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-enrollment-lesson-list-view',
  templateUrl: './enrollment-lesson-list-view.component.html',
  styleUrls: ['./enrollment-lesson-list-view.component.css']
})
export class EnrollmentLessonListViewComponent extends ViewTableComponent implements OnInit {

  @Input() override enrollmentID;
  @Output() dataCountEvent = new EventEmitter<string>();

  override displayedColumns: string[] = [
    'counter',
    'field_date_and_time',
    'field_date_and_time_1',
    // 'title',
    'field_type',
    'field_instructor',
    'field_status',
    'id',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;

    let body = [
      {
        parameter: 'field_enrollment_target_id',
        value: this.enrollmentID
      }
    ]

    // Load the new data with the filtering parameters.
    this._drupalRESTService.httpGET('/api/v2/enrollment-lessons', body)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data['results'];
        this.dataSource = new MatTableDataSource(data['results']);

        let showedCounter = data['results'].filter(item => item.field_status === 'Showed').length;

        this.data = data['results'].map((item, index) => {
          if (item.field_status === 'Showed' || item.field_status === 'No Showed, Charged') {
            item.counter = showedCounter.toString();
            showedCounter--;
          }
          return item;
        });

        // Pagination
        if (data['results'] && this.paginator) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }

        // Emit count to parent component.
        this.dataCountEvent.emit(data['count'])
      },
        error => this.handleError(error)
      )
  }



}
