import { Component, OnInit } from '@angular/core';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';

@Component({
  selector: 'app-paid-lesson-audit',
  templateUrl: './paid-lesson-audit.component.html',
  styleUrls: ['./paid-lesson-audit.component.css']
})
export class PaidLessonAuditComponent extends BaseDialogComponent {


  auditData: any;

  override ngOnInit(): void {
    this.init();

    this.showProgressSpinner = true;

    let params = [
      { parameter: 'contact_id', value: this._injectedDialogData?.data?.student_id },
    ]

    this._drupalRESTService.httpGET('/api/v1/loadPaidLessonAuditReport', params)
    .subscribe(data => {
      console.log(data);
      this.auditData = data;
      this.showProgressSpinner = false;
    })

  }

}
