<h2>Update the event status:</h2>

<app-success-message [message]=this.successMessage></app-success-message>
<app-error-message [message]=this.errorMessage></app-error-message>

<form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
  <div class="container">
    <div class="row">

      <div class="col-12">
        <p>{{ studentName }}</p>
        <p>Current status: {{ status }}</p>
        <!-- <p>{{ eventID }}</p> -->
        <!-- <p>{{ attendeesID }}</p> -->
      </div>

      <div class="col-12" *ngIf="this.appointmentType == 'lesson'">
        <!-- Status Dropdown -->
        <mat-form-field
            class="full-width-field">
            <mat-label>Lesson Status</mat-label>
            <mat-select
                required
                ngModel
                name="field_status">
                <mat-option
                    *ngFor="let element of this._taxonomyService.lesson_status"
                    [value]="element.id">
                    {{element.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>

      <div class="col-12" *ngIf="this.appointmentType == 'services'">
        <!-- Status Dropdown -->
        <mat-form-field
            class="full-width-field">
            <mat-label>Service Status</mat-label>
            <mat-select
                required
                ngModel
                name="field_status">
                <mat-option
                    *ngFor="let element of this._taxonomyService.service_status"
                    [value]="element.id">
                    {{element.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
      </div>

      <div class="col-5">
        <button type="button" [disabled]="!f.valid" (click)="onSubmit(f);" mat-raised-button
          color="accent">Save</button>
      </div>
    </div>
  </div>
</form>
