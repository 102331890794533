<div class="container-fluid">
  <h1 class="page-header">Payments Made Report</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <!-- Date Range Filter -->
        <div class="col-3">
          <mat-form-field>
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
              <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
            </mat-date-range-input>
            <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>

        <!-- Enrollment Autocomplete -->
        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Enrollment</mat-label>
            <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
              [matAutocomplete]="autoCompleteEnrollment">
            <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
              <mat-icon>manage_accounts</mat-icon>
            </button>
          </mat-form-field>
          <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
            <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
              {{option.label}} ({{option.value}})
            </mat-option>
          </mat-autocomplete>
        </div>

        <!-- Department Filter  -->
        <div class="col-3">
            <mat-form-field class="full-width-field">
              <mat-label>Select Department...</mat-label>
              <mat-select
                name="field_department_name"
                ngModel
                (selectionChange)="onSubmit(f)">
                <mat-option value="all">All</mat-option>
                <mat-option
                *ngFor="let department of departmentData"
                [value]="department.key">{{ department.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="col-3">
          <mat-form-field class="full-width-field">
              <mat-label>Student Name</mat-label>
              <input
                  name="field_student_id"
                  (input)="onAutocompleteStudentAccount($event)"
                  matInput
                  ngModel
                  [matAutocomplete]="studentautocomplete"
                  >
              <button type="button" matSuffix mat-icon-button
                  aria-label="manage_accounts">
                  <mat-icon>manage_accounts</mat-icon>
              </button>
          </mat-form-field>
          <mat-autocomplete
              #studentautocomplete="matAutocomplete"
              (optionSelected)="onSubmit(f)">
              <mat-option *ngFor="let option of autoCompleteStudentAccountOptions"
                  [value]="option.value">
                  {{option.label}} ({{option.value}})
              </mat-option>
          </mat-autocomplete>
      </div>

      <section class="section">
        <mat-checkbox
            name="Sundry"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Sundry</mat-checkbox>
        <mat-checkbox
            name="Misc"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Misc</mat-checkbox>

        <mat-checkbox
            name="Lesson"
            class="margin"
            ngModel
            (change)="onSubmit(f)">Lesson</mat-checkbox>
      </section>

        <section class="section">
          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>

      </div>


    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button>
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">


      <!-- id Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by id">
          Receipt #
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.id }} </td>
      </ng-container>

      <!-- field_date_and_time Column -->
      <ng-container matColumnDef="field_date_and_time">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_date_and_time">
          Date Paid
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_date_and_time | date:'shortDate' }} </td>
      </ng-container>

      <!-- field_student_name Column -->
      <ng-container matColumnDef="field_student_name">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_name">
          Student Name
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_student_name }} </td>
      </ng-container>

      <!-- field_student_department Column -->
      <ng-container matColumnDef="field_student_department">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student_department">
          Student Department
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_student_department }} </td>
      </ng-container>

      <!-- field_enrollment_package_name_label Column -->
      <ng-container matColumnDef="field_enrollment_package_name_label">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_enrollment_package_name_label">
          Enrollment
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.enrollment_title }} </td>
      </ng-container>

      <!-- field_enrollment_package_name_label Column -->
      <ng-container matColumnDef="field_gross_tuition">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_gross_tuition">
          Amount Paid
        </th>
        <td mat-cell *matCellDef="let element"> ${{ element.field_gross_tuition }} </td>
      </ng-container>

      <!-- field_payment_type_label Column -->
      <ng-container matColumnDef="field_payment_type_label">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_payment_type_label">
          Payment Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_payment_type_label }} </td>
      </ng-container>

      <!-- field_instructor_label Column -->
      <ng-container matColumnDef="field_instructor_label">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_instructor_label">
          Instructor
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_instructor }} {{ element.field_last_name_instructor }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <ng-container matColumnDef="closer">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by closer">
          Closer
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_first_name_executive }} {{ element.field_last_name_executive }} </td>
      </ng-container>

      <!-- REPLACE Column -->
      <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
          REPLACE
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
      </ng-container> -->

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
