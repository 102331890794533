import { Component, OnInit } from '@angular/core';
import { BaseFormComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent extends BaseFormComponent implements OnInit {
  ngOnInit(): void {

    this._authService.set_user_authenticated(false);
    this._router.navigate(['/auth/login'], {queryParams: {logged_out: true}})

    this._authRESTService.logout()
    .subscribe(response => {
      console.log(response);
      // this._router.navigate(['auth/logout'])
    });
  }
}
