<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

<!-- <mat-form-field appearance="standard">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
</mat-form-field> -->

<!-- <div class="example-container mat-elevation-z8">
<div class="loading-shade"
  *ngIf="ShowProgressBar">
  <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
    No limit
  </div>
</div>
</div> -->

<div class="table-container">

    <table *ngIf="this.data && this.objLength(this.data) > 0" mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

        <!-- counter Column -->
        <ng-container matColumnDef="counter">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Counter">
							#
					</th>
					<td mat-cell *matCellDef="let element">
						<!-- {{ element.counter }} -->
						{{ element.field_status === 'Showed' ? element.counter : '' }}
					</td>
			</ng-container>

        <!-- field_date_and_time Column -->
        <ng-container matColumnDef="field_date_and_time">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Date">
							Date
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.field_date_and_time | date : 'shortDate' }}
					</td>
				</ng-container>

				<!-- field_date_and_time_1 Column -->
				<ng-container matColumnDef="field_date_and_time_1">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Time">
							Time
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.field_date_and_time | date : 'shortTime' }}
					</td>
				</ng-container>

				<!-- field_type Column -->
				<ng-container matColumnDef="field_type">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Service">
							Service
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.field_type }}
					</td>
				</ng-container>


				<!-- field_instructor Column -->
				<ng-container matColumnDef="field_instructor">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Teacher">
							Teacher
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.field_first_name }} {{ element.field_last_name }}
					</td>
				</ng-container>

				<!-- field_status Column -->
				<ng-container matColumnDef="field_status">
					<th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Status">
							Status
					</th>
					<td mat-cell *matCellDef="let element">
						{{ element.field_status }}
					</td>
				</ng-container>


        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by ID">

            </th>
            <td mat-cell *matCellDef="let element">
							<button
							mat-stroked-button
							PackageEntityComponent
							(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'delete', element.event_id)"
							mat-button>
								Delete
						</button>
						</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [class.highlight-row]="row.field_status !== 'Showed'" mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <ng-container *ngIf="this.objLength(this.data) == 0 && actionType == 'delete'">
        <div>
        This enrollment has no lessons. It is safe to delete.
        </div>
    </ng-container>

    <ng-container *ngIf="this.objLength(this.data) == 0 && actionType != 'delete'">
        <div>
        This enrollment has no lessons.
        </div>
    </ng-container>

    <!-- <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
        [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view">
    </mat-paginator> -->

</div>
