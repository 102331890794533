import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import moment from 'moment';
import { BaseDialogComponent } from '../forms/base-dialog/base-dialog.component';
import { BaseFormComponent } from '../forms/base-form/base-form.component';

@Component({
  selector: 'app-authenticate',
  templateUrl: './authenticate.component.html',
  styleUrls: ['./authenticate.component.css']
})
export class AuthenticateComponent extends BaseFormComponent {

  override onSubmit(f: NgForm) {
    let values = f.form.value;

    this.displayProgressSpinner(true);

    this._authRESTService.login(values.name, values.pass)
      .subscribe(
        (response: any) => {
          let header = response.headers.get('X-Token');
      },
        error => this.handleError(error)
      )

  }

}
