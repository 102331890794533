import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@fullcalendar/core';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-enrollment-payment-display',
  templateUrl: './enrollment-payment-display.component.html',
  styleUrls: ['./enrollment-payment-display.component.css']
})
export class EnrollmentPaymentDisplayComponent extends BaseFormComponent implements OnInit {

  @Input() override entityData: any;
  @Input() override enrollmentForm: any;

  ngOnInit(): void {
  }

}
