<div class="container-fluid">
    <h1 class="page-header">Day View</h1>

    <!-- Filter Form  -->
    <app-day-view-frm-filter (updateCalendarEvents)=updateCalendarEvents($event)
        [filterInstructorCategory]="filterInstructorCategory"
        [filterLessonType]="filterLessonType" [filterStudent]="filterStudent"
        [filterDate]="filterDate" [filterGroupLesson]="filterGroupLesson"
        [filterLesson]="filterLesson" [filterServices]="filterServices">
    </app-day-view-frm-filter>

    <div class="row">
        <div class="col-12">
            <!-- Options  -->
            <div class="float-end mt-3">
                <!-- Summary Details -->
                <div class="d-inline me-1">
                    <span>Overall Summary: </span>
                    <span title="Booked Daily">{{ summaryCount.privateLessonDaily }}</span>
                    <sup title="Taken Daily">{{ summaryCount.privateTakenLessonDaily }}</sup>
                    <b> / </b>
                    <span title="Booked Weekly">{{ summaryCount.privateLessonWeekly }}</span>
                    <sup title="Taken Weekly">{{ summaryCount.privateTakenLessonWeekly }}</sup>
                </div>

                <button style="color:#000; background-color: #82CFFD !important; border-color: #82CFFD !important;" mat-flat-button class="btn-color-status-update me-1" mat-button
                    [matMenuTriggerFor]="updateStatusMenu">
                    Status Update
                    <mat-icon aria-hidden="false" aria-label="Menu dropdown"
                        class="menu-dropdown">arrow_drop_down
                    </mat-icon>
                </button>
                <mat-menu #updateStatusMenu="matMenu">
                    <button mat-menu-item (click)="openDialog(UpdateLessonComponent, 'statusFormWithData', {eventData: this.Events} )">Update Lesson</button>
                    <button mat-menu-item (click)="openDialog(UpdateGroupLessonComponent, 'statusFormWithData', {eventData: this.Events} )">Update Group Lesson</button>
                    <button mat-menu-item (click)="openDialog(UpdateServiceComponent, 'statusFormWithData', {eventData: this.Events} )">Update Services</button>
                </mat-menu>

                <button style="color:#fff; background-color: #1C3F8B !important; border-color: #1C3F8B !important;" mat-flat-button class="me-1" mat-button
                    [matMenuTriggerFor]="newEventMenu">
                    New Event
                    <mat-icon aria-hidden="false" aria-label="Menu dropdown"
                        class="menu-dropdown">arrow_drop_down
                    </mat-icon>
                </button>
                <mat-menu #newEventMenu="matMenu">
                    <button mat-menu-item
                        (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create')">
                        Add Lesson
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'create')">
                        Add Group Lesson
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create')">
                        Add Service
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'create')">
                        Add Schedule
                    </button>
                </mat-menu>


                <button style="color:#fff; background-color: #0AC92B !important; border-color: #0AC92B !important;" (click)="openEntityComponent(StudentInquiryEntityComponent, 'student_accounts', 'student_account', 'create', '', {
                    'dayview_field_date_and_time': this.calendarDateInquiry,
                    'inquiry': true
                })" type="button"
                    mat-button>
                    Add Inquiry
                </button>
            </div>
        </div>
    </div>


    <full-calendar #calendar [options]="calendarOptions"></full-calendar>

    <!-- TODO: make this a component -->
    <div class="popover bottom in" role="tooltip" id="custom-popover" style="display: none;">
        <div class="arrow"></div>
        <div class="popover-content">
            <div style="display: grid;">
                <button (click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', '', {
                    'field_instructor': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="lesson"
                    title="Book Lesson">
                    Lesson
                </button>
                <button (click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', '', {
                    'field_executive': this.currentSelectedInstructorID,
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="service"
                    title="Book Service">
                    Service
                </button>
                <button (click)="openEntityComponent(EventScheduleEntityComponent, 'events', 'schedules', 'create', '', {
                    'field_schedule_instructor': [this.currentSelectedInstructorID],
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="schedule"
                    title="Book Schedule">
                    Schedule
                </button>
                <button (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', 'group_lesson', 'create', '', {
                    'field_instructor': [this.currentSelectedInstructorID],
                    'field_duration': this.currentSelectedDuration,
                    'field_date_and_time': this.currentSelectedStartTime
                })" type="button"
                    class="btn btn-default" target_element="group_lesson"
                    title="Book Group Lesson">
                    Group Lesson
                </button>
            </div>
        </div>
    </div>

    <!-- TODO: make this a component -->
    <div class="popover bottom in" role="tooltip" id="custom-context-menu" style="display: none;">
        <div class="arrow" style="left: 50%;"></div>
        <div class="popover-content">
            <div style="display: grid;">

                <div class="context-menu-item">
                    <mat-icon>content_copy</mat-icon>
                    <div (click)="copyClick()" type="button"
                        class="context-button" target_element="lesson"
                        title="Copy">
                        Copy
                    </div>
                </div>

                <!-- <div class="context-menu-item">
                    <mat-icon>content_paste_off</mat-icon>
                    <div (click)="pasteClick()" type="button"
                        class="context-button" target_element="service"
                        title="Paste">
                        Paste
                    </div>
                </div> -->

                <hr />
                <div class="context-menu-item">
                    <mat-icon>close</mat-icon>
                    <div (click)="closeContextMenu()" type="button"
                        class="context-button"
                        title="Close">
                        Close
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>
