import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-send-update-api',
  templateUrl: './send-update-api.component.html',
  styleUrls: ['./send-update-api.component.css']
})
export class SendUpdateAPIComponent extends BaseSimpleDialogComponent {
  title: any;
  api_data: any;

  override ngAfterContentInit(): void {
    console.log('this._injectedDialogData["data"]', this._injectedDialogData?.['data']);
    console.log(this._injectedDialogData?.['data']);

    this.onSubmit(this.f);
  }

  ngOnInit(): void {
    // Change title if sending or updating API.
    if (this._injectedDialogData?.['data']?.['operation'] == "Send API") {
      this.title = "Send Report API";
    } else {
      this.title = "Update Report API";
    }
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    let values = this._injectedDialogData['data']['form'];

    // Build request...
    let params: { parameter: any, value: any }[] = [];

    // studio_business_send_api endpoint params.
    if (this._injectedDialogData['data']['endpoint'] == 'studio_business_send_api') {
      params = [
        { parameter: 'week', value: values['week'] },
        { parameter: 'year', value: values['year'] },
        { parameter: 'operation', value: this._injectedDialogData['data']['operation'] },
      ];
    }

    // staff_performance_send_api endpoint params.
    if (this._injectedDialogData['data']['endpoint'] == 'staff_performance_send_api') {
      params = [
        { parameter: 'staff_name', value: values['staff_name'] },
        { parameter: 'week', value: values['week'] },
        { parameter: 'year', value: values['year'] },
        { parameter: 'operation', value: this._injectedDialogData['data']['operation'] },
      ];
    }

    // royalty_report_send_api endpoint params.
    if (this._injectedDialogData['data']['endpoint'] == 'royalty_report_send_api') {
      params = [
        { parameter: 'field_student_target_id', value: values['field_student_target_id'] },
        { parameter: 'field_enrollment_package_name_target_id', value: values['field_enrollment_package_name_target_id'] },
        { parameter: 'field_date_and_time_value', value: values['field_date_and_time_value'] },
        { parameter: 'week', value: values['week'] },
        { parameter: 'year', value: values['year'] },
        { parameter: 'operation', value: this._injectedDialogData['data']['operation'] },
      ];
    }

    // sendMiscellaneousServiceReportToApi endpoint params
    if (this._injectedDialogData['data']['endpoint'] == 'sendMiscellaneousServiceReportToApi') {
      params = [
        { parameter: 'field_package_code', value: values?.['field_package_code'] },
        { parameter: 'week', value: values?.['week'] },
        { parameter: 'year', value: values?.['year'] },
        { parameter: 'operation', value: this._injectedDialogData['data']['operation'] },
        // { parameter: 'field_date_and_time_value', value: values['field_date_and_time_value'] },
        { parameter: 'field_date_and_time_value_start', value: values?.['field_date_and_time_value_start'] },
        { parameter: 'field_date_and_time_value_end', value: values?.['field_date_and_time_value_end'] },
        { parameter: 'event_date_and_time_value_start', value: moment(values?.['event_date_and_time_value_start']).format('YYYY-MM-DD') },
        { parameter: 'event_date_and_time_value_end', value: moment(values?.['event_date_and_time_value_end']).format('YYYY-MM-DD') },
        { parameter: 'package_costs', value: values?.['package_costs'] },
        { parameter: 'transportation_costs', value: values?.['transportation_costs'] },
      ];
    }

    this._drupalRESTService.httpGET(`/api/v1/${this._injectedDialogData['data']['endpoint']}`, params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log(data)

        if (data?.['api_response']) {
          this.apiMessage = data['api_response'];
          this.successMessage = 'Report was successfully sent.';
        } else if (data?.['message'] && data?.['message_type'] == "warning") {
          this.api_data = data['api_data'];
          this.errorMessage = data?.['message'];
        } else if (data?.['message'] && data?.['message_type'] == "info") {
          this.api_data = data['api_data'];
          this.successMessage = data?.['message'];
        } else {
          this.closeDialog();
        }
        // TODO: Get proper messages for these API calls.
        // if (data['success']) {
        //   this.closeDialog();
        // }
        // if (data['message']) {
        //   this.errorMessage = data['message'];
        // }
      },
        error => {
          console.log('error', error)
          this.displayProgressSpinner(false);
          if (error?.['error']?.['message']) {
            this.errorMessage = error?.['error']?.['message'];
          } else {
            this.errorMessage = "There was a problem sending this report.";
          }
        });

  }

}
