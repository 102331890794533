import { Injectable, OnInit } from '@angular/core';
import { DrupalRESTService } from './drupal-rest.service';

@Injectable({
  providedIn: 'root'
})
export class TaxonomyService implements OnInit {

  enrollment_type = [];
  group_lesson_types = [];
  lesson_status = [];
  lesson_type = [];
  package_code = [];
  payment_type = [];
  schedule_type = [];
  service_status = [];
  service_type = [];
  sps_code = [];
  student_department = [];
  teacher_category = [];

  constructor(
    private drupalRESTService: DrupalRESTService,
  ) { }

  ngOnInit(): void {
  }

  initTaxonomyTerms() {
      return new Promise((resolve, reject) => {
        this.drupalRESTService.httpGET('/api/v1/taxonomy')
        .subscribe(data => {

          // Respect "published" and "hide"
          for (const taxonomy_term in data) {
              data[taxonomy_term].forEach((element: any) => {
                if (element.hide == false && element.published) {
                  this[taxonomy_term].push(element);
                } else {
                  // console.log(element);
                }
              });
            }
            resolve(true);
          });
      })
  }
}
