import { Injectable } from '@angular/core';
import { FieldsService } from './fields.service';
import { TaxonomyService } from './taxonomy.service';

@Injectable({
  providedIn: 'root'
})
export class NgInitDataService {

  constructor(
    public _taxonomyService: TaxonomyService, 
    public _fieldsService: FieldsService, 
  ) { }

  init() {
    return async () => {
      // this._taxonomyService.initTaxonomyTerms();

      // this._fieldsService.getInstructorData();
    }
  }

}
