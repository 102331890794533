import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';

@Component({
  selector: 'app-post-lesson',
  templateUrl: './post-lesson.component.html',
  styleUrls: ['./post-lesson.component.css']
})
export class PostLessonComponent extends BaseDialogComponent implements OnInit {

  override entityId = this._injectedDialogData?.['data']?.['entityID'];

  override ngAfterContentInit(): void {
    this.onSubmit(this.f);
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    // // Build request...
    // let params: any;

    // // Add params the endpoint is expecting.
    // let firstAttendeeID = this._injectedDialogData?.['data']?.['eventData']['studentsDashboardLinks'][0]?.attendees_id;
    // let selectedStatus = 'selectedStatus[' + firstAttendeeID + ']';
    // params = {
    //   [selectedStatus]: 59,
    //   dateTime: moment().format('YYYY-MM-DD[T]HH:mm:ss'), // 2022-02-21T19:59:20
    //   eventId: this.entityId
    // };


    // this._drupalRESTService.httpPOST('/api/v1/updateStatus', params)

    // Build request...
    let params: { parameter: any, value: any }[] = [];

    // Add params the endpoint is expecting.
    params = [
      { parameter: 'selectedStatus[' + this._injectedDialogData?.['data']?.['eventData']['studentsDashboardLinks'][0]?.attendees_id + ']', value: 59 },
      { parameter: 'dateTime', value: moment().format('YYYY-MM-DD[T]HH:mm:ss') }, // 2022-02-21T19:59:20
      { parameter: 'eventId', value: this.entityId },
    ];

    this._drupalRESTService.httpGET('/api/v1/updateStatus', params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log(data)
        if (data?.['success']) {
          // this.closeDialog();
          this._dialog.closeAll();
        }
        if (data['message']) {
          this.errorMessage = data['message'];
        }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem posting this lesson."; // Default error message.
          this.handleError(error);
        });
  }

}
