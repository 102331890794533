import { Component, OnInit } from '@angular/core';

import { map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// Services
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { AuthRESTService } from 'src/app/services/auth-rest.service';
import { AuthService } from 'src/app/services/auth.service';
import { MenuRESTService } from 'src/app/services/menu-rest.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { DialogService } from 'src/app/services/dialog.service';
import { ComponentType } from '@angular/cdk/overlay';
import { BaseFormComponent } from '../forms/base-form/base-form.component';
import { StudentInquiryEntityComponent } from '../forms/student-inquiry-entity/student-inquiry-entity.component';
import { StudentAccountEntityComponent } from '../forms/student-account-entity/student-account-entity.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent extends BaseFormComponent {

  // logo: string = "assets/images/logo.png";
  logo: string = "assets/images/logo-white.png";

  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  StudentAccountEntityComponent = StudentAccountEntityComponent;

  ngOnInit(): void {
    // this.getMenu();
  }

  openAdminLink(link) {
    let endpoint = environment.drupalUrl;
    window.open(endpoint + link, '_newtab');

  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    // console.log('fieldsData')
    // console.log(fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      if (this?.refreshCalendar) {
        console.log('refresh calendar')
        this.refreshCalendar();
      }
    });
  }

  private loadMenu() {
    // this._menuREST.loadedMenuItems
  }

}
