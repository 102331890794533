import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseFormComponent } from '../base-form/base-form.component';

@Component({
  selector: 'app-package-entity',
  templateUrl: './package-entity.component.html',
  styleUrls: ['./package-entity.component.css']
})

export class PackageEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "packages";
  override bundle = "package";
  override entityId = this._injectedDialogData['data']['EntityID'];

  isMiscOrSundry: boolean;

  calcNewTotal() {
    let total;
    let formValues = this.f.form.value;

    // If the category is misc (554) or sundry (554), return the lesson price.
    if (formValues.field_sps_code == '554' || formValues.field_sps_code == '558') {
      // Update whatever lesson count is to 0
      this.f.form.patchValue({
        field_lesson_count: 0,
      });

      total = (formValues.field_lesson_price);
    } else {
      total = (formValues.field_lesson_price * formValues.field_lesson_count);
    }

    this.f.form.patchValue({
      field_total_price: total
    });

    // Determine is enrollment is misc or sundry item.
    this.isMiscOrSundry = (formValues.field_sps_code == 554 || formValues.field_sps_code == 558) ?? false;
  }

  onSubmitPackage(f: NgForm) {
    this.displayProgressSpinner(true);

    console.log('packageForm', this.f.value);

    // Set default value for field_retired if nothing is set.
    if (this.f.value?.['field_retired'] == "") {
      this.f.value.field_retired = false;
    }

    // If SPS code is sundry (558), Misc (554), or Non Unit (657) don't send lesson_type.
    if ((this.f.value.field_lesson_type == '') && (this.f.value.field_sps_code == '558' || this.f.value.field_sps_code == '554' || this.f.value.field_sps_code == '657')) {
      // delete (this.f.value.field_lesson_type);
    }

    // Iterate over each field in the form
    for (let field in this.f.controls) {
      // Check if the field is an empty string
      if (this.f.control.get(field).value === '') {
        // If it is, set its value to null
        this.f.control.get(field).patchValue(null);
      }
      if (this.f.control.get(field).value === undefined) {
        // If it is, set its value to null
        this.f.control.get(field).patchValue(null);
      }
    }

    if (this.f.value.field_lesson_type == undefined) {
      this.f.value.field_lesson_type = null;
    }

    this.onSubmit(this.f)
  }

  misc_list = [
    "1401",
    "160",
    "161",
    "162",
    "165",
    "166",
    "167",
    "170",
    "172",
    "173",
    "174",
    "175",
    "1402",
    "177",
    "180",
    "181",
    "182",
    "183",
    "188",
    "190",
    "191",
    "193",
    "636",
    "637",
    "1400",
  ]

  non_unit_list = [
    "173",
    // "1399",
    // "1397",
    // "1398",
    // "1396"
  ]

  non_unit_group_non_list = [
    "1410",
    "1411",
    // "1397",
    // "1398",
    // "1396"
  ]

  sundry_list = [
    "196",
    "197",
    "198",
    "199",
    "200",
    "201",
    "202",
    "203",
    "195",
    "635"
  ]

}
