import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css',
    '../../../../app.component.css'
  ]
})
export class ServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onSubmit(f) {

  }
  
  statusList: {id: number, value: string}[] = [
    { id: 110, value: "Cancelled" },
    { id: 112, value: "Incomplete" },
    { id: 107, value: "No Sale" },
    { id: 109, value: "No Showed" },
    { id: 113, value: "Pending Status" },
    { id: 111, value: "Rescheduled" },
    { id: 106, value: "Sale" },
    { id: 108, value: "Think it Over" },
  ]

  service_types: {id: number, value: string}[] = [
    { id: 566, value: "Graduation/Checkout" },
    { id: 567, value: "Original Chat" },
    { id: 568, value: "Dance Demo" },
    { id: 574, value: "Chat" },
    { id: 577, value: "Extension Chat" },
    { id: 584, value: "Service Visit" },
    { id: 585, value: "Dance O Rama Chat" },
    { id: 586, value: "Progress Check" },
    { id: 588, value: "Benefit Sheet" },
    { id: 589, value: "Renewal Chat" },
    { id: 593, value: "Lesson Package Chat" },
    { id: 594, value: "Showcase Chat" },
    { id: 595, value: "Dream Sheet" },
    { id: 596, value: "Grad Chat" },
    { id: 597, value: "Coaching Lesson (C)" },
    { id: 632, value: "Medal Ball Chat" },
    { id: 633, value: "Solo Chat" },
    { id: 634, value: "Sponsor Chat" },
    { id: 647, value: "In-House Coaching Lesson" },
    { id: 650, value: "Master Class/Dance Camp" },
    { id: 651, value: "Event Chat" },
    { id: 652, value: "Lesson Visit" },
    { id: 656, value: "Pre Chat" },
  ]
}
