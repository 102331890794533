<app-entity-mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane"
    cdkDragHandle title="Lesson"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner,
        'shade-locked': isEventLocked
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered"
            *ngIf="showProgressSpinner" mode="indeterminate">
        </mat-progress-spinner>

        <mat-icon aria-hidden="false" class="lockIconCentered"
            *ngIf="isEventLocked">lock</mat-icon>

        <mat-tab-group class="eventTabGroup"
            [selectedIndex]="this.selectedIndex"
            (selectedTabChange)="setFormMode($event.index)"
            animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab
                label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
                    <div class="container">
                        <div class="row">
                            <div class="col-4">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Lesson Type</mat-label>
                                    <mat-select required ngModel
                                        name="field_type"
                                        (selectionChange)="validateEnrollment('_none', td_student_account.value, enrollment_id.value); updateLessonDefaultTimes($event.value)">
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._taxonomyService?.lesson_type"
                                            [value]="element.id">{{ element.name
                                            }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-4">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Instructor</mat-label>
                                    <mat-select required matInput ngModel
                                        name="field_instructor">
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._fieldsService?.instructorData()"
                                            [value]="element.uid">
                                            {{ element.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-4">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Duration</mat-label>
                                    <mat-select matInput ngModel
                                        name="field_duration">
                                        <mat-option>None</mat-option>
                                        <mat-option
                                            *ngFor="let element of this._fieldsService.durations"
                                            [value]="element.value">
                                            {{ element.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field>
                                    <input [ngModel]="field_date_and_time"
                                        (click)="picker_field_date_time.open()"
                                        (ngModelChange)="uiUpdateDate($event)"
                                        name="field_date_and_time" matInput
                                        [ngxMatDatetimePicker]="picker_field_date_time"
                                        placeholder="Choose a date">
                                    <mat-datepicker-toggle matSuffix
                                        [for]="picker_field_date_time">
                                    </mat-datepicker-toggle>
                                    <ngx-mat-datetime-picker
                                        #picker_field_date_time
                                        [hideTime]="false"
                                        [enableMeridian]="true"
                                        [showSpinners]="true"
                                        [showSeconds]="false"
                                        [stepHour]="1" [stepMinute]="15"
                                        [touchUi]="false">
                                    </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>

                            <div class="col-12 mt-1">
                                <mat-expansion-panel [expanded]="true">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Personal Information
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>
                                    <input
                                        class="d-none"
                                        type="text"
                                        [(ngModel)]="field_student"
                                        name="field_student"
                                        value="{{studentList}}">

                                    <!-- Attendees -->
                                    <app-display-attendees
                                        [field_students]="field_student"
                                        [studentUpdateLoad]="studentUpdateLoad"
                                        [studentRemove]="studentRemove"></app-display-attendees>

                                    <div
                                        [ngClass]="{ 'd-none': (this.objLength(field_student) > 0) && !this.editMode }"
                                        style="padding: 0 12px; margin-bottom: 12px;"
                                        ngModelGroup="__field_students_inline_form"
                                        #attendee="ngModelGroup">

                                        <input class="d-none" ngModel name="id"
                                            matInput>
                                        <input class="d-none"
                                            [ngModel]="entityType" name="type"
                                            matInput>
                                        <input class="d-none"
                                            [ngModel]="entityBundle"
                                            name="bundle" matInput>

                                        <div class="row">
                                            <!-- Student Account Autocomplete -->
                                            <div class="col-12">
                                                <mat-form-field
                                                    class="full-width-field">
                                                    <mat-label>Student Account</mat-label>
                                                    <input
                                                        name="field_student_account"
                                                        (input)="onAutocompleteStudent($event)"
                                                        matInput
                                                        ngModel
                                                        (ngModelChange)="onStudentNameSelectThenLoadEnrollmentName($event, true); lookupStudentAccountContact($event);"
                                                        #td_student_account
                                                        [matAutocomplete]="autoCompleteStudentAccount">
                                                    <button type="button"
                                                        matSuffix
                                                        mat-icon-button
                                                        aria-label="manage_accounts">
                                                        <mat-icon>manage_accounts</mat-icon>
                                                    </button>
                                                </mat-form-field>
                                                <mat-autocomplete
                                                    #autoCompleteStudentAccount="matAutocomplete"
                                                    (optionSelected)="lookupStudentAccountContact($event); onStudentNameSelectThenLoadEnrollmentName($event);">
                                                    <mat-option
                                                        *ngFor="let option of autoCompleteStudentOptions"
                                                        [value]="option.label + ' (' + option.value + ')'">
                                                        {{option.label}}
                                                        ({{option.value}})
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </div>

                                            <div
                                                class="col-12">
                                                <label
                                                    [ngClass]="{ 'd-none': !(this.objLength(autoCompleteStudentContactOptions) > 0) }">Students<sup>*</sup></label>
                                                <mat-radio-group
                                                    ngModel
                                                    name="field_students"
                                                    class="radio-group"
                                                    aria-label="Select an option">
                                                    <mat-radio-button
                                                        class="col-12 radio-button"
                                                        *ngFor="let contact of autoCompleteStudentContactOptions"
                                                        [value]="contact['id']">
                                                        {{ contact['title'] }}
                                                    </mat-radio-button>
                                                </mat-radio-group>
                                            </div>

                                            <!-- Enrollment Dropdown -->
                                            <div class="col-12">
                                                <mat-form-field
                                                    class="full-width-field">
                                                    <mat-label>Enrollment Name</mat-label>
                                                    <mat-select
                                                        matInput
                                                        ngModel
                                                        name="field_enrollment"
                                                        (selectionChange)="validateEnrollment('_none', td_student_account.value, enrollment_id.value)"
                                                        #enrollment_id>
                                                        <mat-option>None</mat-option>
                                                        <mat-option
                                                            *ngFor="let element of enrollmentsForUser"
                                                            [value]="element.id[0].value">
                                                            {{ element.title[0]?.value
                                                            }}
                                                            (Available: {{
                                                            element.field_lesson_available[0]?.value
                                                            }},
                                                            Paid: {{
                                                            element.field_total_paid[0]?.value
                                                            }},
                                                            Due: {{
                                                            element.field_balance_due[0]?.value
                                                            }})
                                                            <!-- (Lessons Available: 3, Price: $0.00, Paid: 0, Balance: 0) -->
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>

                                            <div class="col-12">
                                                <mat-form-field
                                                    class="full-width-field">
                                                    <mat-label>Description</mat-label>
                                                    <textarea
                                                        name="field_description"
                                                        ngModel
                                                        matInput
                                                        placeholder></textarea>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button *ngIf="!editMode"
                                                type="button"
                                                mat-raised-button
                                                [disabled]="!attendee.valid"
                                                class="me-1"
                                                color="primary"
                                                (click)="studentIncrement(attendee.value)">
                                                Create attendees
                                            </button>
                                            <button *ngIf="editMode"
                                                type="button"
                                                mat-raised-button
                                                [disabled]="!attendee.valid"
                                                class="me-1"
                                                color="primary"
                                                (click)="studentUpdateSubmit(i, attendee.value)">
                                                Edit Attendee
                                            </button>
                                        </div>

                                    </div>
                                </mat-expansion-panel>
                            </div>

                            <div class="col-12">
                                <mat-expansion-panel
                                    (opened)="recurringEvent = true"
                                    (closed)="recurringEvent = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Standing Appointment
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{recurringEvent ?
                                            'Repeating event.' :
                                            'This event will not repeat.'}}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <div class="row">
                                        <div class="col-6">
                                            <button
                                                type="button"
                                                class="me-1"
                                                (click)="recurringMode = 'Days'"
                                                mat-flat-button
                                                [color]="recurringMode == 'Days' ? 'accent' : 'basic'">Daily</button>
                                            <button
                                                type="button"
                                                class="me-1"
                                                (click)="recurringMode = 'Weeks'"
                                                mat-flat-button
                                                [color]="recurringMode == 'Weeks' ? 'accent' : 'basic'">Weekly</button>
                                            <button
                                                type="button"
                                                class="me-1"
                                                (click)="recurringMode = 'Months'"
                                                mat-flat-button
                                                [color]="recurringMode == 'Months' ? 'accent' : 'basic'">Monthly</button>
                                        </div>
                                        <div class="col-6">
                                            <mat-form-field appearance="fill">
                                                <mat-label>Instructor(s)</mat-label>
                                                <mat-select multiple matInput
                                                    [ngModel]="field_instructor_alternate_list"
                                                    name="field_instructor_alternate_list">
                                                    <mat-option>None</mat-option>
                                                    <mat-option
                                                        *ngFor="let element of this._fieldsService?.instructorData()"
                                                        [value]="element.uid">
                                                        {{ element.value }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-checkbox
                                                [ngModel]="field_use_alternating_instructor"
                                                name="field_use_alternating_instructor"
                                                class="example-margin">Alternate
                                                Teachers</mat-checkbox>
                                        </div>

                                    </div>

                                    <input
                                        class="d-none"
                                        type="text"
                                        [(ngModel)]="recurringMode"
                                        name="field_repetition_units"
                                        value="{{recurringMode}}">

                                    <div>
                                        Repeat every
                                        <mat-form-field style="width:50px"
                                            class="ms-1 me-1">
                                            <input min="0"
                                                name="field_repetition_frequency"
                                                [ngModel]="interval" matInput
                                                type="number">
                                        </mat-form-field>
                                        <ng-container
                                            [ngSwitch]="this.recurringMode">
                                            <span *ngSwitchCase="'Days'">day(s).</span>
                                            <span *ngSwitchCase="'Weeks'">week(s).</span>
                                            <span *ngSwitchCase="'Months'"></span>
                                            <span *ngSwitchCase="'yearly'">
                                                years on the DAYDROPDOWN of
                                                MONTHDROPDOWN
                                            </span>
                                            <span *ngSwitchDefault></span>
                                        </ng-container>
                                        <span
                                            [ngClass]="{ 'd-none': recurringMode != 'Months' }">
                                            months on the
                                            <mat-form-field class="ms-1"
                                                style="width:180px">
                                                <mat-label>Select Day</mat-label>
                                                <mat-select
                                                    matInput
                                                    ngModel
                                                    name="field_recurrent_month_day">
                                                    <mat-option>None</mat-option>
                                                    <mat-option
                                                        *ngFor="let element of this._fieldsService.dayDropdown"
                                                        [value]="element.value">
                                                        {{ element.name }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </span>
                                    </div>

                                    <div
                                        [ngClass]="{ 'd-none': recurringMode != 'Weeks' }">
                                        <div class="mt-1 mb-3">
                                            <section class="example-section">

                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_su"
                                                    name="field_recurrent_weekday_su"
                                                    class="example-margin">Sun</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_mo"
                                                    name="field_recurrent_weekday_mo"
                                                    class="example-margin">Mon</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_tu"
                                                    name="field_recurrent_weekday_tu"
                                                    class="example-margin">Tue</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_we"
                                                    name="field_recurrent_weekday_we"
                                                    class="example-margin">Wed</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_th"
                                                    name="field_recurrent_weekday_th"
                                                    class="example-margin">Thu</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_fr"
                                                    name="field_recurrent_weekday_fr"
                                                    class="example-margin">Fri</mat-checkbox>
                                                <mat-checkbox
                                                    [ngModel]="field_recurrent_weekday_sa"
                                                    name="field_recurrent_weekday_sa"
                                                    class="example-margin">Sat</mat-checkbox>
                                            </section>
                                        </div>
                                    </div>

                                    <div>
                                        <div
                                            style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius:10px;">

                                            <label
                                                id="example-radio-group-label"><h3>Stop
                                                    Condition</h3></label>

                                            <mat-radio-group
                                                [(ngModel)]="field_expiration_date"
                                                name="field_expiration_date"
                                                aria-labelledby="example-radio-group-label"
                                                class="example-radio-group">
                                                <div class="row" style>
                                                    <mat-radio-button
                                                        [ngClass]="{ 'd-none': recurringMode == 'Months' }"
                                                        (click)="calcDateEOM()"
                                                        [value]="valueEOM">
                                                        Run until the end of the
                                                        month
                                                    </mat-radio-button>
                                                    <mat-radio-button
                                                        [ngClass]="{ 'd-none': recurringMode != 'Months' }"
                                                        (click)="calcDateEOY()"
                                                        [value]="valueEOY">
                                                        Run until the end of the
                                                        year
                                                    </mat-radio-button>
                                                </div>
                                                <div class="row">
                                                    <mat-radio-button
                                                        [value]="valueSpecificExpirationDate">
                                                        <div class="row">
                                                            <span>Run until a
                                                                specific date
                                                                &nbsp;
                                                                <mat-form-field
                                                                    style="width: 150px;">
                                                                    <mat-label>Expiration
                                                                        Date</mat-label>

                                                                    <input
                                                                        matInput
                                                                        [ngxMatDatetimePicker]="field_expiration_datepicker"
                                                                        value="{{field_expiration_date}}"
                                                                        (dateInput)="uiUpdateExpirationDate($event);">
                                                                    <mat-datepicker-toggle
                                                                        matSuffix
                                                                        [for]="field_expiration_datepicker">
                                                                    </mat-datepicker-toggle>
                                                                    <ngx-mat-datetime-picker
                                                                        #field_expiration_datepicker
                                                                        [hideTime]="true"
                                                                        [enableMeridian]="true"
                                                                        [showSpinners]="true"
                                                                        [showSeconds]="false"
                                                                        [stepHour]="1"
                                                                        [stepMinute]="15"
                                                                        [touchUi]="false"></ngx-mat-datetime-picker>
                                                                </mat-form-field>
                                                            </span>
                                                        </div>
                                                    </mat-radio-button>
                                                </div>
                                            </mat-radio-group>

                                        </div>
                                    </div>

                                </mat-expansion-panel>
                            </div>

                            <div class="col-12">
                                <mat-checkbox
                                    [ngModel]="this._frmValues?.field_is_confirmed"
                                    name="field_is_confirmed"
                                    class="example-margin mb-3 mt-3">Confirmed</mat-checkbox>
                            </div>
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'"
                *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<!-- <div *ngIf="f.form.invalid">
    <h3>Invalid Fields:</h3>
    <ul>
        <li *ngFor="let invalidControl of getInvalidControls(f)">
        {{ invalidControl.name }} - Error: {{ invalidControl.errors | json }}
        </li>
    </ul>
</div> -->

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <button [disabled]="!this?.f?.valid || f?.value?.field_student?.length == 0" type="button" (click)="onSubmit(f);" mat-raised-button
            color="accent">Save</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button [disabled]="isEventLocked" type="button"
                (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button [disabled]="isEventLocked" type="button"
                (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button
                color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button
                color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button
            color="accent">Cancel</button>
        <button
            (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID']); this._dialog.closeAll(); this.refreshCalendar(10000);"
            type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
