import { CommonModule } from "@angular/common";
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { UtilityService } from 'src/app/services/utility.service';
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { NgForm } from "@angular/forms";
import { DrupalRESTService } from "src/app/services/drupal-rest.service";
import { ActivatedRoute, Event, Params, Router } from "@angular/router";
import { saveAs } from 'file-saver';
import moment from "moment";
import { ViewTableComponent } from "../../base/view-table/view-table.component";
import { StudentDashboardWrapperComponent } from "../student-dashboard-wrapper/student-dashboard-wrapper.component";
import { ComponentType } from "@angular/cdk/overlay";

@Component({
  selector: 'app-students-list',
  templateUrl: './students-list.component.html',
  styleUrls: [
    './students-list.component.css',
    '../../../app.component.css'
  ]
})

export class StudentsListComponent extends ViewTableComponent implements OnInit {

  currentPage: number;
  StudentDashboardWrapperComponent = StudentDashboardWrapperComponent;

  activeLink = 'Active Students';
  links = [
    {linkTitle: 'Active Students', linkRoute: '/students-list' },
    {linkTitle: 'Retired Students', linkRoute: '/students-list-retired' }
  ]

  override displayedColumns: string[] = [
    'field_last_name_value',
    'field_first_name_value',
    'field_student_department_target_id',
    // 'field_last_lesson_date_day_value',
    'field_last_lesson_taken_value',
    'field_upcoming_lesson_value',
    'field_total_lessons_enrolled_value',
    'field_total_lessons_taken_value',
    'field_total_lessons_remaining_value',
    'field_total_lessons_paid_ahead_value',
    'instructor',
    'field_email_value',
    'field_cell_phone_value',
    'dashboard',
    'edit'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Handle different checkbox based filters.
    let filters = '?field_archive=1&sort_order=ASC&sort_by=field_last_name_value';
    let nfa;
    let today = moment().format('YYYY-MM-DD');
    if (params) {
      for (const property in params) {
        // console.log(params[property])
        if (params[property]?.['parameter'] == 'Active') {
          // filters = filters + "status[]=active&";
          filters = filters + "&field_last_lesson_taken_value=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
        }
        if (params[property]?.['parameter'] == 'Inactive') {
          filters = filters + "&field_last_lesson_taken_value=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (params[property]?.['parameter'] == 'NFA') {
          filters = filters + "&field_nfa=1";
          filters = filters + "&field_total_lessons_remaining_value[value]=0";
          filters = filters + "&field_total_lessons_remaining_value[min]=";
          filters = filters + "&field_total_lessons_remaining_value[max]=";
          filters = filters + "&field_total_lessons_remaining_value_op=>";
        }
        if (params[property]?.['parameter'] == 'paid_finished') {
          filters = filters + "&field_paid_finished=true";
          filters = filters + "&field_total_lessons_paid_ahead_value=0";
          filters = filters + "&field_total_lessons_remaining_value[value]=0"
          filters = filters + "&field_total_lessons_remaining_value[min]=";
          filters = filters + "&field_total_lessons_remaining_value[max]=";
          filters = filters + "&field_total_lessons_remaining_value_op==";
        }
      }
    }

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v1/students" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.setupPagination(data, params);
        }
      },
        error => this.handleError(error)
      )
  }

  setStudentArchive(student_account_id: number, value: boolean) {
    let body = {
      field_archive: '' + value,
    };
    let setStudentArchive = this._entityRESTService.patchEntity('student_accounts', 'student_account', student_account_id, body);
    setStudentArchive.subscribe(data => {
      console.log('data', data);
      this.getData(this.queryParams);
    })
  }

  // openDialogStudentList(component: ComponentType<unknown>, dialogConfig: { height: string, width: string, closeOnNavigation?: boolean, data?: {} }, data: any) {
  //   let dialogRef = this._dialogService.openDialog(component, dialogConfig ?? "defaultWithData", {
  //     data,
  //   }).afterClosed().subscribe(data => {
  //     this.getData(this.queryParams)
  //   });
  // }
}
