<div mat-dialog-title>
    <h1>Package</h1>
    <button mat-button mat-dialog-close>
        <mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon>
    </button>
</div>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

        <mat-tab-group [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <!-- {{ this.f?.form?.value | json }} -->

                <form (ngSubmit)="onSubmitPackage(f)" #f="ngForm" class="form">
                    <div class="container">
                        <div class="row">
                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Abbreviation</mat-label>
                                    <input name="field_abbreviation" matInput ngModel>
                                </mat-form-field>
                            </div>

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Name</mat-label>
                                    <input name="field_package_name" matInput ngModel>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>SPS Code</mat-label>
                                    <mat-select (selectionChange)="calcNewTotal()" matInput ngModel name="field_sps_code">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._taxonomyService?.sps_code"
                                            [value]="element.id">
                                            {{ element.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <ng-container>
                                <div class="col-12"
                                    [ngClass]="{
                                        'd-none': this.f.form.value.field_sps_code != 558 && this.f.form.value.field_sps_code != 554 && this.f.form.value.field_sps_code != 657
                                    }
                                ">
                                    <mat-form-field class="full-width-field">
                                        <mat-label>Package Code</mat-label>
                                        <mat-select matInput ngModel name="field_package_code">
                                            <mat-option>None</mat-option>
                                            <!-- For SPS Codes that match "MISC" -->
                                            <div [ngClass]="{ 'd-none': !(this.f?.form?.value?.field_sps_code == 554) }">
                                                <ng-container *ngFor="let element of this._taxonomyService?.package_code">
                                                    <ng-container *ngIf="misc_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <!-- For SPS Codes that match "SUNDRY" -->
                                            <div [ngClass]="{ 'd-none': !(this.f?.form?.value?.field_sps_code == 558) }">
                                                <ng-container *ngFor="let element of this._taxonomyService?.package_code">
                                                    <ng-container *ngIf="sundry_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                            <!-- For SPS Codes that match "Non Unit" -->
                                            <div [ngClass]="{ 'd-none': !(this.f?.form?.value?.field_sps_code == 657) }">
                                                <ng-container *ngFor="let element of this._taxonomyService?.package_code">
                                                    <ng-container *ngIf="non_unit_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </ng-container>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Lesson Type</mat-label>
                                    <mat-select matInput ngModel name="field_lesson_type">
                                        <mat-option>None</mat-option>

                                        <ng-container [ngSwitch]="this.f?.form?.value?.field_sps_code">
                                            <!-- 558 is Sundry -->
                                            <!-- <ng-container *ngSwitchCase="558">
                                                <ng-container *ngFor="let element of this._taxonomyService?.lesson_type">
                                                    <ng-container *ngIf="sundry_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container> -->
                                            <!-- 554 is MISC -->
                                            <!-- <ng-container *ngSwitchCase="554">
                                                <ng-container *ngFor="let element of this._taxonomyService?.lesson_type">
                                                    {{element.id | json}}
                                                    <ng-container *ngIf="misc_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container> -->
                                            <!-- 657 is Non Unit -->
                                            <ng-container *ngSwitchCase="657">
                                                <ng-container *ngFor="let element of this._taxonomyService?.lesson_type">
                                                    <ng-container *ngIf="non_unit_group_non_list.includes(element.id)">
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                            <!-- Default case -->
                                            <ng-container *ngSwitchDefault>
                                                <ng-container *ngFor="let element of this._taxonomyService?.lesson_type">
                                                    <ng-container>
                                                        <mat-option [value]="element.id">{{ element.name }}</mat-option>
                                                    </ng-container>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-4">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Lesson Amount</mat-label>
                                    <input type="number" (change)="calcNewTotal()"  name="field_lesson_price" matInput ngModel>
                                </mat-form-field>
                            </div>

                            <div [ngClass]="{ 'd-none': isMiscOrSundry  }" class="col-4">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Lesson Count</mat-label>
                                    <input type="number" name="field_lesson_count" matInput ngModel>
                                </mat-form-field>
                            </div>

                            <div class="col-4">
                                <mat-form-field appearance="fill" class="full-width-field">
                                    <mat-label>Total Investment</mat-label>
                                    <input readonly name="field_total_price" matInput ngModel>
                                    <span matPrefix>$&nbsp;</span>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Additional Notes</mat-label>
                                    <textarea name="field_additional_notes" matInput ngModel></textarea>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-checkbox name="field_retired" matInput ngModel>Retired</mat-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <!-- <div class="col-12">
                                        <button type="button" (click)="onSubmit(f);" mat-raised-button
                                            color="accent">Save</button>
                                        &nbsp;
                                        <button type="button" (click)="selectFormIndex(2); setFormMode('delete')" mat-raised-button
                                            color="accent">Delete</button>
                                    </div> -->
                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <button type="button" (click)="onSubmitPackage(f);" mat-raised-button color="accent">Save</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmitPackage(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmitPackage(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
