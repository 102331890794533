import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { environment } from 'src/environments/environment';
import { MatStepper } from '@angular/material/stepper';
import { Validators, FormArray } from '@angular/forms';
import { EnrollmentEntityComponent } from '../../forms/enrollment-entity/enrollment-entity.component';
import { EventLessonEntityComponent } from '../../forms/event-lesson-entity/event-lesson-entity.component';
import { from, mergeMap, forkJoin } from 'rxjs';

@Component({
  selector: 'app-base-enrollment',
  templateUrl: './base-enrollment.component.html'
})
export class BaseEnrollmentComponent extends BaseDialogComponent {

  detectSignatureStudio: boolean;
  detectSignatureStudent: boolean;
  flag1: boolean;
  flag2: boolean;

  studioInformationConfig;
  taxRate: number = 0;

  EventLessonEntityComponent = EventLessonEntityComponent;
  EnrollmentEntityComponent = EnrollmentEntityComponent;

  enrollmentStepperIndex: number = 0;

  @ViewChild('SignatureStudent') signatureStudent: ElementRef<HTMLCanvasElement>;
  @ViewChild('SignatureStudio') signatureStudio: ElementRef<HTMLCanvasElement>;
  @ViewChild('enrollmentStepper') enrollmentStepper: MatStepper;

  override ngOnInit(): void {
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];
    this.enrollmentID = this._injectedDialogData['data']['EntityID'];
    // Load extra data for enrollments.
    if (this._injectedDialogData['data']['action'] == 'view' || this._injectedDialogData['data']['action'] == '0') {
      this.loadEnrollmentData(this.enrollmentID).subscribe(data => {
        this._enrollmentEntityData = data;

        // Load additional student data once we know which enrollment we are working with.
        this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
        .subscribe(data => {
          // console.log('student_accounts student_account data', data);
          this.studentAccountEntityData = data;
        });
      });

      // Load studio information from config service.
      this._configPages.get_config('studio_information', 'all')
      .subscribe((data) => {
        this.studioInformationConfig = data;
      });
    }

    // Load extra data for enrollments.
    if (this._injectedDialogData['data']['action'] == 'signEnrollment') {
      // this.enrollmentStepper.selectedIndex = 3;
      this.loadEnrollmentData(this.enrollmentID).subscribe(data => {
        this._enrollmentEntityData = data;

        // Load additional student data once we know which enrollment we are working with.
        this._entityRESTService.getEntity('student_accounts', 'student_account', data['field_student'][0].target_id)
        .subscribe(data => {
          // console.log('student_accounts student_account data', data);
          this.studentAccountEntityData = data;

          if (this.enrollmentStepper != undefined) {
            this.enrollmentStepper.selectedIndex = 2;
          }
        });
      });
    }

    this.enrollmentForm = this._formBuilder.group({
      field_draft: [false],
      field_student: ['', Validators.required],
      field_sale_date: [null, Validators.required],
      field_expiration_date: [null, Validators.required],
      field_category: [null, Validators.required],
      field_enrollment_package_name: [null, Validators.required],
      field_enrollment_lesson_price: [null, Validators.required],
      field_enrollment_lesson_count: [null, Validators.required],
      field_total_lessons_price	: ['', Validators.required],
      field_enrollment_total_price: [null, Validators.required],
      field_taxes: [null, Validators.required],
      field_tax_percentage: [this.taxRate, Validators.required],
      field_total_paid: [null],
      field_lesson_used: [null],
      field_lessons_paid: [null],
      field_lesson_available: [null],
      field_payments_structure: [],
      field_payments_structure_type: [],
      field_executive: [null, Validators.required],
      field_junior_executive: [null],
      field_scheduled_payments: new FormArray([]),
      field_instructor_percentages: new FormArray([]),
      field_interval_unit: [''],
      field_discount: [0, Validators.required],
      field_discount_type: [],
      field_enrollment_status: ['Open', Validators.required],
      field_next_scheduled_payment: [''],
      field_visibility: [false],
      field_notes	: [''],
      interval: [this.interval],
      field_recurrent_month_day: [''],
      field_recurrent_weekday_mo: [''],
      field_recurrent_weekday_tu: [''],
      field_recurrent_weekday_we: [''],
      field_recurrent_weekday_th: [''],
      field_recurrent_weekday_fr: [''],
      field_recurrent_weekday_sa: [''],
      field_recurrent_weekday_su: [''],
      payment_builder_date_step: new FormArray([]),
    });
    this.agreementFormGroup = this._formBuilder.group({
      field_nothing	: [''],
    });
    this.paymentForm = this._formBuilder.group({
      field_payment_id: [this.paymentID, Validators.required],
      // field_customer_name: [null],
      field_date_and_time: [this.todaysDate, Validators.required],
      field_gross_tuition: [null, Validators.required],
      field_student_name: [null, Validators.required],
      field_enrollment_name: this._formBuilder.group({
        'target_id': [null, Validators.required]
      }),
      field_scheduled_payment: this._formBuilder.group({
        'target_id': [null]
      }),
      field_payment_type: [null, Validators.required],
      cc_number: [null],
      cc_cvv: [null],
      cc_expiration: [null],
      cc_expiration_month: [null],
      cc_expiration_year: [null],
      cc_postal_code: [null],
      field_stripe_charge_id: [this.entityData?.field_stripe_charge_id],
      reason_to_refund: [null],
      field_payments_use_card: [null],
    });
  }

  override ngAfterViewInit(): void {
    this.agreementFormGroup = this._formBuilder.group({
      field_nothing	: [''],
    });

    // Signature stuff.
    this.signatureInit(this.signatureStudent, 'student');
    this.signatureInit(this.signatureStudio, 'studio');

    // Pull tax rate.
    if (this.actionType == "create") {
      this._fieldsService.getTaxRateConfig().subscribe(data => {
        this.enrollmentForm.get("field_tax_percentage").setValue(data?.[0]?.['value']);
      });
    } else {
      // Respect the tax value if in edit mode.
    }

    this.getUniquePaymentID()
      .subscribe(data => {
        this.paymentID = data;
        // this.setFormMode("create");
      });
  }

  setStepperIndex(index) {
    // this.enrollmentStepper.linear = true;
    if (this.enrollmentStepper != undefined) {
      this.enrollmentStepper.selected.completed = true;
      this.enrollmentStepper.selected.editable = false;
      this.enrollmentStepper.next();
    }
  }

  async onSubmitSignatures(action) {
    this.displayProgressSpinner(true);
    this.setStepperIndex(2);

    const toBlob = (canvasElement: HTMLCanvasElement): Promise<Blob> =>
      new Promise((resolve) => canvasElement.toBlob(resolve));

    const handleBlob = (fieldName: string, element: ElementRef<HTMLCanvasElement>) =>
      from(toBlob(element.nativeElement)).pipe(
        mergeMap((blob) =>
          this._drupalRESTService
            .uploadFile('packages', 'enrollment', fieldName, environment.name + '_' + fieldName + '.png', blob)
            .pipe(
              mergeMap((data) => {
                const values = {
                  [fieldName]: [
                    {
                      target_id: data['fid']?.[0]?.['value'],
                      description: '',
                    },
                  ],
                };

                if (typeof this.entityData?.['id'] !== 'undefined') {
                  return this._entityRESTService.patchEntity(
                    'packages',
                    'enrollment',
                    this.entityData['id'],
                    values
                  );
                }

                throw new Error('Missing entity ID.');
              })
            )
        )
      );

    let observables = [];

    // Check if student signature is not present before attempting to upload
    if (!this._enrollmentEntityData?.['field_signature']?.[0]?.['url']) {
      observables.push(handleBlob('field_signature', this.signatureStudent));
    }

    // Check if representative signature is not present before attempting to upload
    if (!this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']) {
      observables.push(handleBlob('field_representative_signature', this.signatureStudio));
    }

    forkJoin(...observables).subscribe(
      () => {
        this.referenceUploadedFileComplete(action);
        this.displayProgressSpinner(false);
      },
      (err) => {
        console.error(err);
        this.displayProgressSpinner(false);
      }
    );

    if (action === 'save_and_add') {
      this.closeDialog();
    }
  }

  referenceUploadedFileComplete(action) {
    if (action === 'save_and_pay') {
      // Autofill payment information
      this.paymentForm.patchValue({
        field_student_name: this.enrollmentEntityData['field_student']['title'] + ' (' + this.enrollmentEntityData['field_student']['id'] + ')',
        field_enrollment_name: {
          target_id: this.enrollmentEntityData['id'],
        },
      });

      this.displayProgressSpinner(false);
    }
  }

  signatureInit(elementRef: ElementRef<HTMLCanvasElement>, elementName: string) {
    let canvas = elementRef.nativeElement;
    let context = canvas.getContext('2d');

    if (!context) { return; }

    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = 3;

    let x = 0, y = 0;
    let isMouseDown = false;

    // Resize the canvas to match display size
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    let stopDrawing = () => { isMouseDown = false; }

    let startDrawing = (event: PointerEvent | TouchEvent) => {
      if (elementName === 'student') {
        this.detectSignatureStudent = true;
      }
      if (elementName === 'studio') {
        this.detectSignatureStudio = true;
      }
      isMouseDown = true;

      if (event instanceof TouchEvent) {
        let touch = event.touches[0] || event.changedTouches[0];
        x = touch.clientX - canvas.getBoundingClientRect().left;
        y = touch.clientY - canvas.getBoundingClientRect().top;
      } else {
        x = event.offsetX;
        y = event.offsetY;
      }
    }

    let drawLine = (event: PointerEvent | TouchEvent) => {
      let newX, newY;

      if (isMouseDown) {
        if (event instanceof TouchEvent) {
          let touch = event.touches[0] || event.changedTouches[0];
          newX = touch.clientX - canvas.getBoundingClientRect().left;
          newY = touch.clientY - canvas.getBoundingClientRect().top;
        } else {
          newX = event.offsetX;
          newY = event.offsetY;
        }

        context.beginPath();
        context.moveTo(x, y);
        context.lineTo(newX, newY);
        context.stroke();
        x = newX;
        y = newY;
      }
    }

    // Add event listeners.
    if (this.isTablet) {
      // Support tablets and touch devices.
      canvas.addEventListener('touchstart', startDrawing);
      canvas.addEventListener('touchmove', drawLine);
      canvas.addEventListener('touchend', stopDrawing);
      canvas.addEventListener('touchcancel', stopDrawing);
    } else {
      // Pointer events for other devices
      canvas.addEventListener('pointerdown', startDrawing);
      canvas.addEventListener('pointermove', drawLine);
      canvas.addEventListener('pointerup', stopDrawing);
      canvas.addEventListener('pointercancel', stopDrawing);
    }
  }

  signatureClear(elementRef: ElementRef<HTMLCanvasElement>) {
    let canvas = elementRef;

    let context = canvas.nativeElement.getContext( '2d' );
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
  }

  signatureClearStudent() {
    this.signatureClear(this.signatureStudent);
    this.detectSignatureStudent = false;
  }

  signatureClearStudio() {
    this.signatureClear(this.signatureStudio);
    this.detectSignatureStudio = false;
  }

  printPage(backPageOnly = false, promptPrint = false){
    let printContents, popupWin, printContentsBackpage;
		printContents = document.getElementById('enrollment-agreement')?.innerHTML;
		printContentsBackpage = `
    <div style="font-size:10px;" >
    <ol id="l1" >
       <li>
          <p style="padding-top: 1.5pt;margin-bottom:.5rem;" > The Studio agrees to provide this course of instruction and / or services in accordance
             with the Arthur Murray® method of dance instruction and to make its
             facilities and personnel available by individual independent appointment for
             each lesson or service for such purposes during the term of this agreement.
             The Studio may provide the Student with any instructor employed by the Studio
             and is not obligated to provide any specific instructor nor to provide the
             same instructor for different lessons.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">Instructions shall be available commencing this date and shall not be
             charged against this enrollment until the completion of all previous
             enrollments, if any.All lessons are 45 minutes long, which includes
             transition time between lessons.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;"> Personal lessons to be made available by the Studio, shall expire
             whether actually used or not upon the agreed expiration date for all
             instruction or lessons under this agreement.All group lessons(if charged)
             and Video Tape Studies shall expire over the same period as the personal
             lessons.The teaching or honoring of any lessons and / or services beyond the
             term of expiration shall not be deemed as a waiver of this expiration
             provision by the Studio.</p>
       </li>
       <li>
          <p
             style="padding-bottom:1pt;"> Student agrees to complete all lessons and / or services as expressly
             provided in this agreement.Student shall not be relieved of the obligation
             to make any payment agreed to, and no deduction or allowance for any payments
             shall be made by reason of Student &#39;s failure to use any lessons and / or
             services, except as provided.</ p >
       </li>
       <li>
          <p style="margin-bottom:.5rem;">This agreement is subject to cancellation at any time during the term
             of the Agreement upon notification by the Student.If this agreement is
             cancelled within three business days, the Studio will refund all payments
             made under the agreement.After three business days, the Studio will only
             charge you for the dance instructions and dance instruction services actually
             furnished under the agreement plus a reasonable and fair service fee.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;"> If other than an original
             enrollment, this agreement, if for dance instruction, is subject to
             cancellation by the Student without charge within seven days after the
             completion of the previous course of dance instruction.After the expiration
             of the term of this agreement, refunds will not be granted.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">&quot;Notice of cancellation&quot;
             shall be deemed to have been provided by a Student or prospective Student by
             mailing or delivering written notification to cancel the contract or written
             agreement; or by failing to attend instructional facilities for a period of
             five consecutive appointment days on which classes or the provision of
             services which are the subject of the contract or written agreement were
             prearranged with the Student or prospective Student.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">Unless otherwise stated in this
             agreement and for refund when applicable, there is no charge for providing
             group lessons, practice sessions, parties or complimentary services offered
             by the Studio and it is agreed that the tuition is based solely upon the
             number of personal lessons of instruction, the use of video equipment and
             expressly paid -for services.</p>
       </li>
       <li>
       <p style="margin-bottom:.5rem;">The Studio
             may assign this agreement and all monies due shall be paid directly to such
             third party upon notification.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">Student agrees to notify the Studio at least 12 hours in advance to
             cancel or change any personal appointment or be charged for such lessons.</p>
       </li>
       <li>
        <p style="margin-bottom:.5rem;">Student &#39;s rights under this
             agreement are personal in nature and may not be sold, assigned or transferred
             to any other person.In the event of Student &#39;s death or physical
             disability, the Student or his representative may sell, donate or transfer
             the remaining lessons and / or services to any persons or charity subject to
             Studio approval.Student lessons may be transferred to any other Arthur
             Murray® Franchised Dance Studio beyond twenty - five miles from this Studio.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">Student agrees not to associate
             with any Studio instructor and other personnel outside the Studio or to give
             or loan anything of value to any Studio personnel during the term of this
             agreement and for a one year period thereafter.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">Student represents to the Studio
             that(s)he is physically able to take and financially able to pay for this
             course of instruction and / or services, has read and fully understands the
             terms of this agreement, has signed the agreement voluntarily and hereby
             acknowledges receipt of a fully executed copy.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;"><strong>ANY HOLDER OF THIS CONSUMER CREDIT
             CONTRACT IS SUBJECT TO ALL CLAIMS AND DEFENSES WHICH THE DEBTOR COULD ASSERT
             AGAINST THE SELLER OF GOODS OR SERVICES OBTAINED PURSUANT HERETO OR WITH THE
             PROCEEDS HEREOF.RECOVERY HEREUNDER BY THE DEBTOR SHALL NOT EXCEED AMOUNTS
             PAID BY THE DEBTOR HEREUNDER.</strong>
          </p >
       </li>
       <li>
       <p style="margin-bottom:.5rem;">Any controversy or claim arising out of or relating to this agreement
             shall be settled solely by arbitration in accordance with the commercial
             arbitration rules of the American Arbitration Association, and judgment upon
             the award rendered by the arbitrator may be entered in any court having
             jurisdiction.All fees and expenses in connection with the arbitration shall
             be shared equally by the parties.Any action or arbitration on this agreement
             must be brought within 12 months from the date the cause of action arises or
             within six months from the expiration of this agreement, whichever occurs
             first.</p>
       </li>
       <li>
       <p style="margin-bottom:.5rem;">The Federal
             Equal Credit Opportunity Act prohibits creditors from discriminating against
             credit applicants on the basis of sex or marital status.The Federal agency
             which administers compliance with this law is the F.T.C., Washington, D.C.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;"><strong>AS STUDENT, I UNDERSTAND AND AGREE
             THAT THIS AGREEMENT IS MADE BY ME SOLELY WITH THE OWNER OF THE STUDIO, AS
             SELLER, AND DOES NOT DIRECTLY OR INDIRECTLY CONSTITUTE AN AGREEMENT WITH OR
             AN OBLIGATION OF ARTHUR MURRAY INTERNATIONAL, INC., OR AS THE STUDIO
             FRANCHISOR.ARTHUR MURRAY INTERNATIONAL, INC.IS NOT THE OWNER OF THIS
             STUDIO.SHOULD THIS AGREEMENT COMBINED WITH THE COST OF STUDENT &#39;S OTHER
             UNUSED LESSONS AND / OR SERVICES, EXCEED $20, 000.00 OR 200 ENROLLED PERSONAL
             LESSONS OR UNITS WHICHEVER OCCURS FIRST, OR THE MAXIMUM PERMITTED BY LAW,
             WHICHEVER IS LESS, THIS AGREEMENT IS VOID.</strong>
          </p >
       </li>
       <li>
          <p style="margin-bottom:.5rem;">The term &quot;School&quot;, &quot;Studio&quot; or “Center” may be
             used for the same meaning, alternately or interchangeably.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">If any particular provision of this agreement is
             held invalid or unenforceable, same shall not affect the other provisions of
             this agreement.</p>
       </li>
       <li>
          <p style="margin-bottom:.5rem;">No other representations or
             provisions, either written or oral, are a part of this agreement, unless
             expressed herein.</p>
       </li>
    </ol>
    </div>
 </div>`;
		popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
		if (popupWin) {
			popupWin.document.open();
			popupWin.document.write(`
				<html>
					<head>
						<title>Enrollment Copy</title>
						<style type="text/css">
              body {
                font-size:10px;
              }
              p {
                font-family: "Times New Roman";
              }

              .padding-main-divcls{
                padding: 5px;
              }

              .text-center{
                text-align: center
              }
              .width-full{
                width: 100%;
              }

              .box {
                  border-style: solid;
                  border-width: 1px;
                  width: 65px;
                  height: 100px;
                  float: right;
                  margin-right: 50px;
                  font-size: 10px;
                  padding: 5px;
              }
              .box-divcls {
                width: 100%;
                display: inline-block;
              }

              .TermsConditionTable, tr , td {
								padding: 4px !important;
							}
							tr, td {
								page-break-inside: avoid !important;
							}
              .display-none {
                display:none;
              }

              .page1 {
                min-height: calc(100vh - 100px);
                overflow: hidden;
              }

              .page2 {
                font-size:10px;
              }

							</style>
              <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-GLhlTQ8iRABdZLl6O3oVMWSktQOp6b7In1Zl3/Jr59b6EGGoI1aFkw7cmDA6j6gD" crossorigin="anonymous">
          </head>
            <body ${promptPrint ? 'onload="window.print();window.close();"' : ''}>
              <p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>
              <div class="container-fluid">
                ${backPageOnly ? printContentsBackpage : printContents}
              </div>
            </body>
        </html>
      `)
      popupWin.document.close();
    }
  }
}
