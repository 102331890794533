<!--<mat-toolbar class="" style="text-align:right;height:46px; background-color:#f4f4f4;">
<div class="row">
    <div class="col-12">
        <div class="float-end">
            <div class="d-inline me-1">
            </div>
        </div>
    </div>
</div>
</mat-toolbar> -->


<mat-toolbar class="menu-toolbar-mat">

    <span><img class="logo cursor-pointer" [src]="logo" /></span>
    <span class="logoText cursor-pointer" routerLink="splash">Agenda Master</span>

    <span class="example-spacer"></span>

    <span id="menu-items">

        <ng-container *ngIf="this._authService.is_user_authenticated()">

            <ng-container *ngFor="let item of this._menuRESTService.loadedMenuItems">
                <!-- Top Level Routes -->
                <button class="menu-item" *ngIf="!item.below" routerLinkActive="active" mat-button routerLink="{{item.relative}}">
                    <span class="with-dropdown">{{item.title}}
                        <!-- <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="menu-dropdown">arrow_drop_down</mat-icon> -->
                    </span>
                </button>

                <!-- <a class="menu-item" *ngIf="!item.below" routerLinkActive="active" href="{{item.relative}}" type="button" mat-button >
                    {{item.title}}
                </a> -->

                <!-- Nested Routes  -->
                <span *ngIf="item.below
                && item.title != 'Administrative'
                && item.title != 'Tools'">
                    <button class="menu-item" mat-button [matMenuTriggerFor]="menu">
                        <span class="with-dropdown">{{item.title}}
                            <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="menu-dropdown">arrow_drop_down</mat-icon>
                        </span>
                    </button>
                    <mat-menu #menu="matMenu">
                        <ng-container *ngFor="let nestedItem of item.below">
                            <button *ngIf="nestedItem.title != 'Add Student Account'" class="menu-item" routerLinkActive="active" routerLink="{{nestedItem.relative}}" mat-menu-item>
                                <span>{{nestedItem.title}}</span>
                            </button>
                            <button (click)="openEntityComponent(StudentInquiryEntityComponent, 'student_accounts', 'student_account', 'create', '', {
                                'dayview_field_date_and_time': this.calendarDateInquiry,
                                'inquiry': false
                            })" *ngIf="nestedItem.title == 'Add Student Account'" class="menu-item" routerLinkActive="active" mat-menu-item>
                                <span>{{nestedItem.title}}</span>
                            </button>
                        </ng-container>
                    </mat-menu>
                </span>

            </ng-container>


            <button class="menu-item" mat-button [matMenuTriggerFor]="menuAdmin">
                <span class="material-icons">person</span>
                <span *ngIf="this._authService?.current_user?.name" class="with-dropdown">
                    {{ this._authService.current_user.name }}
                </span>
            </button>
            <mat-menu #menuAdmin="matMenu">
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/admin/structure/config_pages/session_start_times')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Session Start Times</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/studio-user-register')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Studio User Register</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/importer-types')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Importers</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/instructor-reorder')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Primary Instructor Reorder</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/secondary-instructor-reorder')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Secondary Instructor Reorder</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/admin/structure/config_pages/tax')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Tax Rate</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/admin/structure/config_pages/ami_royalty/')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>AMI Royalty</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/admin/config/regional/settings')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Timezone</span>
                </button>
                <button *ngIf="this._permissionsService.has_access('view_admin_links')" (click)="openAdminLink('/admin/reports/audit-trail')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Audit Trail</span>
                </button>
                <!-- <button routerLink="configuration" *ngIf="this._permissionsService.has_access('view_admin_links')" class="menu-item" routerLinkActive="active" mat-menu-item>
                    <span>Configuration</span>
                </button> -->
                <button class="menu-item" routerLinkActive="active" routerLink="auth/logout" mat-menu-item>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <!-- <button
            routerLink="auth/logout"
            (click)="true"
            class="menu-item" routerLinkActive="active" mat-button>
            Logout&nbsp;
            </button> -->

        </ng-container>

        <!-- Menu Logged Out -->
        <ng-container *ngIf="!this._authService.is_user_authenticated()">
            <button
            routerLink="auth/login"
            *ngIf="!this._authService.is_user_authenticated()"
            (click)="true"
            class="menu-item" routerLinkActive="active" mat-button>
                Log in&nbsp;
                <!-- <mat-icon
                    aria-hidden="false"
                    aria-label="Menu dropdown"
                    class="menu-dropdown">contact_support</mat-icon> -->
            </button>
        </ng-container>

    </span>


</mat-toolbar>


<!--
<button style="position: absolute; bottom:100; right:100; display: block;" (click)="showTicket = !showTicket" mat-menu-item>
Ticket
</button> -->
