<div mat-dialog-title>
	<h1>Student Account Details</h1>
	<button mat-button mat-dialog-close>
			<mat-icon aria-hidden="false" aria-label="Example home icon">close</mat-icon>
	</button>
</div>

<div [ngClass]="{
			'loading-shade': showProgressSpinner
	 }">

	<div mat-dialog-content>

			<mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

			<mat-tab-group [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

					<mat-tab label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
							<app-error-message [message]=this.errorMessage></app-error-message>

							<form *ngIf="this?.studentAccountDetailsForm" [formGroup]="studentAccountDetailsForm" (ngSubmit)="onSubmitInquiryDetails(f)" #f="ngForm" class="form">
									<div class="container">
											<div class="row">
												<!-- Contacts -->
												<!-- [(ngModel)]="this.entityData?.field_contacts" -->
                        <!-- <input
                            class="d-none"
                            type="text"
                            name="field_contacts"
                            value="{{this.entityData?.field_contacts}}"> -->

												<!-- Display Contacts  -->
												<ng-container *ngIf="studentAccountDetailsForm?.value?.field_contacts">
													<div class="full-width-field">

														<div
														*ngFor="let contact of studentAccountDetailsForm?.value?.field_contacts; let i = index;"
														class="mb-2 mt-2"
														>
																<div class="p-3 display-edit-field">
																		<span>
																			{{ contact?.field_first_name ?? '&nbsp;' }} {{ contact?.field_last_name }}
																			<!-- <br /> -->

																		</span>

																		<!-- <mat-icon
																				class="me-1 float-end"
																				(click)="contactEntityRemove(i)"
																				aria-hidden="false"
																				aria-label="trash">cancel</mat-icon> -->

																		<mat-icon
																				class="me-1 float-end"
																				(click)="studentUpdateLoad(i, contact)"
																				aria-hidden="false"
																				aria-label="edit">edit</mat-icon>
																</div>
														</div>
													</div>
											</ng-container>

											<div class="col-1">
												<div>
													<button type="button"
													[disabled]="studentAccountDetailsForm?.value?.field_contacts.length > 1"
															mat-raised-button
															class="me-1 mb-3"
															color="primary" (click)="contactEntityIncrement()">
															Add Student
													</button>
												</div>
											</div>

										</div>

											<!-- [disabled]="!enrollmentForm.get('field_instructor_percentages').valid" -->


										<div class="row">

												<div class="col-4">
													<mat-form-field class="full-width-field">
															<mat-label>Inquiry Taker</mat-label>
															<mat-select matInput [ngModel]="studentAccountDetailsForm?.field_inquiry_taker" formControlName="field_inquiry_taker" name="field_inquiry_taker">
																	<mat-option>None</mat-option>
																	<mat-option *ngFor="let element of this._fieldsService.instructorData()"
																			[value]="element.uid">
																			{{ element.value }}</mat-option>
															</mat-select>
													</mat-form-field>
												</div>

												<div class="col-4">
													<mat-form-field class="full-width-field">
															<mat-label>Teacher</mat-label>
															<mat-select matInput [ngModel]="studentAccountDetailsForm?.field_teacher" formControlName="field_teacher" name="field_teacher">
																	<mat-option>None</mat-option>
																	<mat-option *ngFor="let element of this._fieldsService.instructorData()"
																			[value]="element.uid">
																			{{ element.value }}</mat-option>
															</mat-select>
													</mat-form-field>
												</div>


												<div class="col-4">
													<mat-form-field class="full-width-field">
															<mat-label>How did you hear about us?</mat-label>
															<mat-select
																	formControlName="field_inquiry_method"
																	name="field_inquiry_method">
																	<mat-option>None</mat-option>
																	<mat-option
																	*ngFor="let element of this._fieldsService.field_inquiry_method"
																	[value]="element.key">{{ element.value }}
																	</mat-option>
															</mat-select>
													</mat-form-field>
												</div>

												<!-- <div class="col-6">
													<mat-form-field class="full-width-field">
														<mat-label>Type of Dances</mat-label>
														<mat-select multiple
															name="field_dances_type"
															ngModel
															>
															<mat-option>None</mat-option>
															<mat-option
															*ngFor="let element of this._fieldsService.dance_types"
															[value]="element.key">{{ element.value }}
															</mat-option>
														</mat-select>
													</mat-form-field>
												</div> -->

												<div class="col-4">
													<mat-form-field>
													<input
															formControlName="field_inquired"
															(ngModelChange)="uiUpdateDate($event)"
															name="field_inquired"
															matInput
															[ngxMatDatetimePicker]="picker_field_date_time"
															placeholder="Inquired Date">
													<mat-datepicker-toggle matSuffix [for]="picker_field_date_time">
													</mat-datepicker-toggle>
													<ngx-mat-datetime-picker #picker_field_date_time
															[hideTime]="false"
															[enableMeridian]="true"
															[showSpinners]="true"
															[showSeconds]="false"
															[stepHour]="1" [stepMinute]="15"
															[touchUi]="false">
													</ngx-mat-datetime-picker>
													</mat-form-field>
												</div>

												<div class="col-4">
													<mat-form-field>
													<input
															formControlName="field_booked_on"
															name="field_booked_on"
															matInput
															[ngxMatDatetimePicker]="picker_field_booked_on"
															placeholder="Booked On">
													<mat-datepicker-toggle matSuffix [for]="picker_field_booked_on">
													</mat-datepicker-toggle>
													<ngx-mat-datetime-picker #picker_field_booked_on
															[hideTime]="false"
															[enableMeridian]="true"
															[showSpinners]="true"
															[showSeconds]="false"
															[stepHour]="1" [stepMinute]="15"
															[touchUi]="false">
													</ngx-mat-datetime-picker>
													</mat-form-field>
												</div>

												<div class="col-4">
													<mat-form-field>
													<input
															formControlName="field_showed"
															name="field_showed"
															matInput
															[ngxMatDatetimePicker]="picker_field_showed"
															placeholder="Showed">
													<mat-datepicker-toggle matSuffix [for]="picker_field_showed">
													</mat-datepicker-toggle>
													<ngx-mat-datetime-picker #picker_field_showed
															[hideTime]="false"
															[enableMeridian]="true"
															[showSpinners]="true"
															[showSeconds]="false"
															[stepHour]="1" [stepMinute]="15"
															[touchUi]="false">
													</ngx-mat-datetime-picker>
													</mat-form-field>
												</div>

												<!-- <div class="col-4">
													<mat-form-field>
													<input
															[ngModel]="field_booked_on"
															(ngModelChange)="uiUpdateDate($event)"
															name="field_booked_on"
															matInput
															[ngxMatDatetimePicker]="picker_field_date_time"
															placeholder="Inquired">
													<mat-datepicker-toggle matSuffix [for]="picker_field_date_time">
													</mat-datepicker-toggle>
													<ngx-mat-datetime-picker #picker_field_date_time
															[hideTime]="false"
															[enableMeridian]="true"
															[showSpinners]="true"
															[showSeconds]="false"
															[stepHour]="1" [stepMinute]="15"
															[touchUi]="false">
													</ngx-mat-datetime-picker>
													</mat-form-field>
												</div> -->

													<div class="col-12">
															<mat-form-field class="full-width-field">
																	<mat-label>Additional Notes</mat-label>
																	<textarea name="field_notes" matInput formControlName="field_notes"></textarea>
															</mat-form-field>
													</div>

													<div class="col-12">
															<mat-checkbox name="field_inquiry_studen" formControlName="field_inquiry_studen" class="example-margin">Inquiry</mat-checkbox>
															<!-- <mat-checkbox name="field_retired" matInput ngModel>Retired</mat-checkbox> -->
													</div>
											</div>

											<div class="row">
													<!-- <div class="col-12">
																			<button type="button" (click)="onSubmit(f);" mat-raised-button
																					color="accent">Save</button>
																			&nbsp;
																			<button type="button" (click)="selectFormIndex(2); setFormMode('delete')" mat-raised-button
																					color="accent">Delete</button>
																	</div> -->
											</div>
									</div>
							</form>
					</mat-tab>

					<!-- <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
							<app-entity-confirm-delete></app-entity-confirm-delete>
					</mat-tab> -->

			</mat-tab-group>

	</div>

</div>

<mat-dialog-actions align="end">
	<ng-container *ngIf="this.actionType === 'create'">
			<button type="button" (click)="onSubmitInquiryDetails(f);" mat-raised-button color="accent">Save</button>
	</ng-container>
	<ng-container *ngIf="this.actionType === 'edit'">
			<ng-container *ngIf="this.initActionType == 'edit'">
					<!-- <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button> -->
					<button type="button" (click)="onSubmitInquiryDetails(f);" mat-raised-button color="accent">Save</button>
			</ng-container>
			<ng-container *ngIf="this.initActionType == 'create'">
					<button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
					<button type="button" (click)="onSubmitInquiryDetails(f);" mat-raised-button color="accent">Save</button>
			</ng-container>
	</ng-container>
	<!-- <ng-container *ngIf="this.actionType === 'delete'">
			<button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
			<button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
					color="accent">Delete</button>
	</ng-container> -->
</mat-dialog-actions>
