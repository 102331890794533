import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular'; // useful for typechecking
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import momentPlugin from '@fullcalendar/moment';

import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { LessonComponent } from 'src/app/components/forms/lesson/lesson.component';
import { GroupLessonComponent } from 'src/app/components/forms/group-lesson/group-lesson.component';
import { SchedulesComponent } from 'src/app/components/forms/schedules/schedules.component';
import { ServicesComponent } from 'src/app/components/forms/services/services.component';
import { StudentAccountComponent } from 'src/app/components/forms/student-account/student-account.component';
import { AppointmentDetailsComponent } from 'src/app/components/forms/appointment-details/appointment-details.component';
import { InquiryComponent } from 'src/app/components/forms/inquiry/inquiry.component';

import $, { data } from "jquery";
import moment from 'moment';
import { ComponentType } from '@angular/cdk/portal';
import { UtilityService } from 'src/app/services/utility.service';
import { Router } from '@angular/router';
import { EventLessonEntityComponent } from '../forms/event-lesson-entity/event-lesson-entity.component';
import { PaymentsEntityComponent } from '../forms/payments-entity/payments-entity.component';
import { DialogService } from 'src/app/services/dialog.service';
import { EventSchedulesEntityComponent } from '../forms/event-schedules-entity/event-schedules-entity.component';
import { StudentInquiryEntityComponent } from '../forms/student-inquiry-entity/student-inquiry-entity.component';
import { EventGroupLessonEntityComponent } from '../forms/event-group-lesson-entity/event-group-lesson-entity.component';
import { EventServicesEntityComponent } from '../forms/event-services-entity/event-services-entity.component';
import { UpdateLessonComponent } from '../forms/update-lesson/update-lesson.component';
import { UpdateGroupLessonComponent } from '../forms/update-group-lesson/update-group-lesson.component';
import { UpdateServiceComponent } from '../forms/update-service/update-service.component';
import { AuthService } from 'src/app/services/auth.service';

// Tippy JS
import { createPopper } from '@popperjs/core';
import tippy from 'tippy.js';
import { hideAll } from 'tippy.js';
import { roundArrow } from 'tippy.js';
import { BaseFormComponent } from '../forms/base-form/base-form.component';

@Component({
  selector: 'app-day-view',
  templateUrl: './day-view.component.html',
  styleUrls: ['./day-view.component.css']
})
export class DayViewComponent extends BaseFormComponent implements OnInit {

  @ViewChild('instructorInfo') instructorInfo;
  // @ViewChild('calendar') calendarComponent: FullCalendarComponent;

  LessonComponent = LessonComponent;
  GroupLessonComponent = GroupLessonComponent;
  SchedulesComponent = SchedulesComponent;
  ServicesComponent = ServicesComponent;
  StudentAccountComponent = StudentAccountComponent;
  InquiryComponent = InquiryComponent;
  EventLessonEntityComponent = EventLessonEntityComponent
  PaymentsEntityComponent = PaymentsEntityComponent
  EventScheduleEntityComponent = EventSchedulesEntityComponent;
  StudentInquiryEntityComponent = StudentInquiryEntityComponent;
  EventGroupLessonEntityComponent = EventGroupLessonEntityComponent;
  EventServicesEntityComponent = EventServicesEntityComponent;
  UpdateLessonComponent = UpdateLessonComponent;
  UpdateGroupLessonComponent = UpdateGroupLessonComponent;
  UpdateServiceComponent = UpdateServiceComponent;

  copyData = {};
  AMTDayView: any = "";
  AMTConfiguration: any = "";
  InstructorList: {} = "";
  AMTCalendarConfiguration: any = "";

  Events: any;
  Resources = {};

  // Filter configuration
  filterInstructorCategory: string[];
  filterLessonType: any;
  filterInstructor: string[] = [];
  filterStudent: string = "";
  filterDate: string = "";
  filterGroupLesson: boolean = false;
  filterLesson: boolean = false;
  filterServices: boolean = false;

  // FullCalendar
  currentSelectedInstructorID;
  currentSelectedDuration;
  currentSelectedStartTime;

  summaryCount = {
    privateLessonDaily: 0,
    privateLessonWeekly: 0,
    privateTakenLessonDaily: 0,
    privateTakenLessonWeekly: 0,
  }

  newDateStart: string;
  newDateEnd: string;
  AMTSessionTime: Object;

  tippyHideDuration = 100;

  intervalId = setInterval(() => {
    this.refreshCalendar();
  } , 60000);
  noTippy: boolean = false;

  ngOnInit(): void {
    this.getAMTCalendarConfiguration();
    this.getAMTFilterConfiguration();
    this.getAMTSessionTime();
    // this.openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create');
  }

  ngOnDestroy(): void {
    // Close all dialogs, otherwise dialogs will stay open when navigating.
    this._dialogService.closeAll();

    clearInterval(this.intervalId);
  }

  openEntityComponent(component: ComponentType<unknown>, eckType: any, bundle: any, action: any, EntityID?: any, fieldsData?: {}) {
    // console.log('fieldsData')
    // console.log(fieldsData)
    this._dialogService.openDialog(component, "defaultWithData", {
      data: {
        EntityID: EntityID,
        eckType: eckType,
        bundle: bundle,
        action: action,
        fieldsData: fieldsData ?? '',
      },
    }).afterClosed().subscribe(data => {
      this.refreshCalendar();
    });
  }

  public openForm2($formName: any) {
    let dialogConfig = LessonComponent;
    let configOptions = {};
    let config = {
      'AddLessonComponent': {
        'all': {
          'height': 'auto',
          'width': '600px',
        },
        'edit': 'dialogAddLessonData'
      }
    }

    if (config[$formName] !== 'undefined') {
      let formConfig = config[$formName];

      if (typeof formConfig['all'] !== 'object') {
        configOptions = formConfig['all'];
      }
      else if (typeof formConfig['all'] !== 'string') {
        // this.dialogAddLessonData()
        configOptions = this[formConfig['all']](configOptions);
      }
    }

  }

  public dialogAddLessonData(configOptions) {
    configOptions['data'] = {
      instructors: this.filterInstructorCategory,
      lesson_types: this.filterLessonType
    };
    return configOptions;
  }

  /**
   * Utility to open dialogs with a default configuration.
   *
   * @param formName
   * @param dialogConfig
   */
  public openDialog(formName: ComponentType<unknown>, configName?, data?) {
    this._dialogService.openDialog(formName, configName, data)
    .afterClosed()
    .subscribe(data => {
      this.refreshCalendar();
    });
  }

  /**
   * FullCalendar Configuration.
   */
  calendarOptions: CalendarOptions = {
    timeZone: 'local',
    plugins: [momentPlugin],
    firstDay: 0,
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    initialView: 'resourceTimeGridDay',
    height: "auto",
    refetchResourcesOnNavigate: true,
    allDaySlot: false,
    // Don't allow events to overlap.
    slotEventOverlap: false,
    droppable: true,
    editable: true,

    events: this.getCalendarEvents(),
    resources: this.getCalendarResources(),

    loading: function(isLoading) {
      if (isLoading) {
      } else {
        // Hide custom popover if mouse hover exits.
        $("#custom-popover").hover(null, function () {
          $(this).hide();
        });
        // Hide custom context menu if mouse hover exits.
        $("#custom-context-menu").hover(null, function () {
          $(this).hide();
        });
      }
    },

    eventDrop: (info: any) => {
      let eventID = info?.oldEvent?._def?.extendedProps?.entityId;
      let eventType = info?.event?._def?.extendedProps?.appointmentType;
      let newStartTime = moment(info.event.start).format('YYYY-MM-DDTHH:mm:ss');
      let newInstructor = info?.newResource?._resource?.id;

      // hideAll() to hide all tippy instances.
      hideAll({duration: this.tippyHideDuration});
      this.noTippy = true;

      this._entityRESTService.patchEntity('events', eventType, eventID, {
        'field_date_and_time': newStartTime,
        'field_instructor': newInstructor,
      }).subscribe((data) => {
        this.noTippy = false;
        this.refreshCalendar();
      });
    },

    // Change in duration
    eventResize: (eventResizeInfo: any) => {
      let eventID = eventResizeInfo?.oldEvent?._def?.extendedProps?.entityId;
      let eventType = eventResizeInfo?.event?._def?.extendedProps?.appointmentType;

      // hideAll() to hide all tippy instances.
      hideAll({duration: this.tippyHideDuration});
      this.noTippy = true;

      this._entityRESTService.patchEntity('events', eventType, eventID, {
        'field_duration': this.calcDuration(eventResizeInfo.event.start, eventResizeInfo.event.end),
      }).subscribe((data) => {
        this.noTippy = false;
        this.refreshCalendar();
      });
    },

    eventContent: (arg: any) => {
      // Confirmation status for events.
      let isConfirmed = arg.event._def.extendedProps?.['eventDetails']?.data?.isConfirmed;
      let lessonType = arg.event._def.extendedProps?.['appointmentType'];

      // This is where the meat of content comes through.
      let contentToShow = document.createElement('div');
      contentToShow.innerHTML = arg.event._def.extendedProps['dataToShow'];

      // Add confirmation status.
      let confirmationStatus = document.createElement('div');
      confirmationStatus.className = "confirmation-status";

      // Only show confirmations for regular lessons.
      if (isConfirmed && lessonType == 'lesson') {
        confirmationStatus.innerHTML = '<span class="material-icons">check_circle</span>';
      } else if (isConfirmed == false && lessonType == 'lesson') {
        confirmationStatus.innerHTML = '<span class="material-icons">circle</span>';
      }

      // Makes confirmation clickable.
      confirmationStatus.addEventListener("click", (e: Event) => {
        e.stopPropagation();
        this.toggleEventConfirmation(arg);
      })

      let arrayOfDomNodes = [ confirmationStatus, contentToShow ]
      return { domNodes: arrayOfDomNodes }
  },

    eventDidMount: (info) => {
      // Custom context menu.
      let eventId = info.event.id

      info.el.addEventListener("contextmenu", (jsEvent)=>{
        if (info.event._def?.extendedProps?.['appointmentType'] == 'lesson') {
          jsEvent.preventDefault();
          this.copyData = info.event?._def?.extendedProps?.['eventDetails']?.data;

          // Custom popup box for when selecting times in the calendar.
          var left = jsEvent.pageX;
          var top = jsEvent.pageY;
          var theHeight = $("#custom-context-menu").height();
          $("#custom-context-menu").show();
          $("#custom-context-menu").css(
            "left",
            left - $("#custom-context-menu").width() / 2 + "px"
          );
          $("#custom-context-menu").css(
            "top",
            top - theHeight / 2 + 20 + "px"
          );
          $("#custom-context-menu").css("display", "block");
        }
      });

      // Popups for events.
      tippy(info.el, {
        allowHTML: true,
        placement: "bottom",
        arrow: roundArrow,
        animation: 'fade',
        theme: 'light',
        duration: [500, 0],
        content: info.event.extendedProps?.['tooltip'],
        // trigger: "click",
      })

    },

    headerToolbar: {
      start: 'today prev,next',
      center: 'title',
      end: ''
    },

    titleFormat: function(date) {
      let newTitleFormat = moment(date.end.marker).format('dddd[,] MMMM DD[, ]YYYY[ - Week #] ');
      let newTitleWeek = moment;
      newTitleWeek.updateLocale('en', {
        week: {
          dow : 0, // Sunday as the first DOW.
        }
      });

      newTitleFormat += newTitleWeek(date.end.marker).format('w');

      return (newTitleFormat).toString();
    },

    eventClick: (info) => {
      console.log('info', info)
      this.openDialog(AppointmentDetailsComponent, "defaultWithData", info);

      // hideAll() to hide all tippy instances.
      hideAll({duration: this.tippyHideDuration});
    },

    eventMouseLeave: function( mouseLeaveInfo ) {
      // hideAll() to hide all tippy instances.
      hideAll({duration: this.tippyHideDuration});
    },

    selectable: true,

    select: (info) => {
      console.log('resource', info.resource);

      // Custom popup box for when selecting times in the calendar.
      var left = info.jsEvent.pageX;
      var top = info.jsEvent.pageY;
      var theHeight = $("#custom-popover").height();
      $("#custom-popover").show();
      $("#custom-popover").css(
        "left",
        left - $("#custom-popover").width() / 2 + "px"
      );
      $("#custom-popover").css(
        "top",
        top - theHeight / 2 + 20 + "px"
      );
      $("#custom-popover").css("display", "block");

      // Get the current starting time.
      this.currentSelectedStartTime = moment(info.start).format('YYYY-MM-DD[T]HH:mm:ss');

      // Calculate duration of selected area.
      this.currentSelectedDuration = this.calcDuration(info.start, info.end);

      // Grab the instructor ID, to be used to pass into the form.
      this.currentSelectedInstructorID = info.resource.id;
    },

    // When the events over loped it set false the selectable of calendar
    selectOverlap: function (event) {
      console.log('selectOverlap called...');
      // console.log(event);
      // console.log(event);
      // if (String(event.className[0]) == "Cancelled") {
      //   return true;
      // }
      // return false;

      return false;
    },

    selectAllow: (date) => {
      console.log('selectAllow called...', date)

      // Find the time diff for checking the druation.
      var fromTime = date.start.getTime() / 1000;
      var toTime = date.end.getTime() / 1000;
      var timeDiff = (toTime - fromTime) / 3600; // will give difference in hrs


      // var left = event.pageX;
      // var top = event.pageY;
      var theHeight = $("#selected-hours").height();
      // $("#selected-hours").show();
      // $("#selected-hours .popover-data").html(timeDiff).css({
      //   "min-width": "20px",
      //   "text-align": "center",
      // });
      // if (timeDiff > 9) {
      //   $("#selected-hours .popover-data").css("color", "red");
      // } else {
      //   $("#selected-hours .popover-data").css("color", "black");
      // }
      // $("#selected-hours").css("left", left + "px");
      // $("#selected-hours").css("top", top - theHeight / 2 + "px");
      $("#selected-hours").css({
        "z-index": "9999",
        position: "absolute",
        display: "block",
      });

      return true;
    },

    dateClick: function (info) {
      info.jsEvent.preventDefault();
      console.log('Clicked on: ' + info.dateStr);
      console.log('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
      console.log('Current view: ' + info.view.type);
      console.log('Dayel', info.dayEl)

      if (info.jsEvent.button === 2) {
        console.log('setting...', info)
        this.currentPasteData = info
      }
    },

    resourceLabelDidMount: function (mountArg) {
      // console.log('resourceLabelDidMount', mountArg)

      // Hover for the instructors.
      tippy(mountArg.el, {
        allowHTML: true,
        placement: "bottom",
        arrow: roundArrow,
        animation: 'fade',
        duration: [500, 0],
        theme: 'light',
        content: mountArg.resource?.extendedProps?.['info'],
        // trigger: "click",
      })
    },


    resourceLabelContent: function (resourceObj, $th, el) {
      // console.log('resourceLabelContent', resourceObj)
      // console.log('$th', $th.html)
    },

    // Default configuration.
    slotDuration: "00:15:00",
    slotLabelInterval: "00:01:00",
    displayEventTime: false,
    slotLabelFormat: {
      hour: 'numeric',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short'
    },
  };

  updateCalendarEvents(params: any) {
    console.log("updateCalendarEvents called...", params);

    // FIX: set FullCalendar to go to the correct date.
    this.calendarComponent.getApi().gotoDate(moment(params.filterDate).toISOString());

    this.calendarOptions.events = this.getCalendarEvents(params);
    this.calendarOptions.resources = this.getCalendarResources(params);
  }

  getCalendarEvents(params: any = null, updateColorsOnly?) {

    // console.log("getCalendarEvents called...", params, updateColorsOnly);

    return (fetchInfo, successCallback, failureCallback) => {

      this.getAMTSessionTime();
      // console.log('fetchInfo.startStr');
      // console.log(fetchInfo.startStr);

      let endpoint = "/api/dayview/calendar/events?";

      this.newDateStart = moment(fetchInfo.startStr).toISOString()
      this.newDateEnd = moment(fetchInfo.endStr).toISOString()
      this.calendarDateInquiry = moment(fetchInfo.start).set({"hour": 12, "minute": 0}).toISOString()

      if (params) {

        if (params.filterDate) {
          endpoint = endpoint + "&calendar_date=" + this.newDateStart;
        }

        if (params.filterStudent) {
          endpoint = endpoint + "&student=" + params.filterStudent;
        }

        if (params.filterLessonType) {
          endpoint = endpoint + "&lessonType=" + params.filterLessonType;
        }

        if (params.filterInstructorCategory) {
          endpoint = endpoint + "&instructor_category=" + params.filterInstructorCategory;

          // this.getCalendarResources(params);
        }

        if (params.filterGroupLesson) {
          endpoint = endpoint + "&group_lesson=" + params.filterGroupLesson;
        }

        if (params.filterLesson) {
          endpoint = endpoint + "&lesson=" + params.filterLesson;
        }

        if (params.filterServices) {
          endpoint = endpoint + "&services=" + params.filterServices;
        }

      }

      // Send start && end times.
      endpoint = endpoint + "&start=" + this.newDateStart;
      endpoint = endpoint + "&end=" + this.newDateEnd;

      // Run change on next browser MicroTask.
      Promise.resolve().then(() => {
        this.filterDate = moment(this.newDateStart).toISOString();
      })

      if (updateColorsOnly) {
        this.Events = this.fixEventColors(data);
        successCallback(data);
        return;
      }

      this._drupalRESTService.httpGET(endpoint).subscribe(data => {
        this.Events = this.fixEventColors(data);
        successCallback(data);
      })
    }
  }

  fixEventColors(data) {
    // console.log('Events: ', data);

    // Check if colors should change.
    for (const key in data) {
      let event = data[key];
      if (moment(data[key].end) < moment()) {
        // console.log('event', event);
        if (event?.appointmentType == 'lesson' && event?.status != 'Showed' && event?.status !== 'Cancelled' && event?.status !== 'Rescheduled') {
          data[key].color = "#FF4136";
        }
      }
    }
    return data;
  }

  handleEventDidMount(info: any) {
    // console.log(info.event.extendedProps);
    // eventDidMount
  }

  getCalendarResources(thisParams: any = null) {
    let endpoint = "/api/dayview/calendar/resources?";

    return (fetchInfo, successCallback, failureCallback) => {

      // console.log('fetchInfo', fetchInfo);

      // Refresh the calendar's min and max display times.
      // console.log('this.AMTCalendarConfiguration', this.AMTCalendarConfiguration)

      if (this.AMTCalendarConfiguration?.['office_hours']?.[moment(fetchInfo.startStr).format('dddd')]?.['start']) {
        // console.log('slowMinTime', this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['start'])
        // console.log('slowMaxTime', this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['end'])
        this.calendarComponent.options.slotMinTime = this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['start'];
        this.calendarComponent.options.slotMaxTime = this.AMTCalendarConfiguration['office_hours'][moment(fetchInfo.startStr).format('dddd')]['end'];
      }

      let endpoint = "/api/dayview/calendar/resources";
      let params = [
        { parameter: 'start', value: fetchInfo.startStr },
        { parameter: 'end', value: fetchInfo.endStr }
      ];

      if (thisParams?.filterInstructorCategory) {
        // console.log(thisParams.filterInstructorCategory);

        params = [
          ...params,
          { parameter: 'instructor_category', value: thisParams.filterInstructorCategory },
          // { parameter: 'calendar_date', value: '2022-04-05' },
        ]
      }

      this._drupalRESTService.httpGET(endpoint, params)
      .subscribe(data => {
        this.calcSummary(data)
        successCallback(data);
      },
        error => this.handleError(error)
      )
    };
  }

  calcSummary(data) {
    // console.log("calcSummary called...", data)
    let alreadyCounted: number[] = [];

    // Reset Summary
    this.summaryCount = {
      privateLessonDaily: 0,
      privateLessonWeekly: 0,
      privateTakenLessonDaily: 0,
      privateTakenLessonWeekly: 0,
    }

    data.forEach((element: { id: string; privateLesson: number[]; privatePostedLesson: number[]; }) => {
      let id = parseInt(element.id)

      if (alreadyCounted.includes(id)) { } else {
        this.summaryCount.privateLessonWeekly += element.privateLesson[1];
        this.summaryCount.privateLessonDaily += element.privateLesson[0];
        this.summaryCount.privateTakenLessonWeekly += element.privatePostedLesson[1];
        this.summaryCount.privateTakenLessonDaily += element.privatePostedLesson[0];

        alreadyCounted.push(id);
      };
    });
  }

  // Get the session time.
  getAMTSessionTime() {
    let endpoint = "/api/dayview/calendar/configurations-session";
    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTSessionTime = data;
        this.processAMTSessionTime();
      });
  }

  // Load configuration sessions from Drupal.
  processAMTSessionTime() {
    var CurrentDayDate = new Date(this.newDateStart);
    let dataObject = this.AMTSessionTime;
    var dataArray = Object.entries(dataObject);

    // Strip the gray colors.
    $('td:nth-child(2)').css(
      "background",
      "none"
    );

    // Search for times and add the gray.
    dataArray[CurrentDayDate.getDay()][1].forEach(function (item) {
      $('td[data-time="' + item + '"]:nth-child(2)').css(
        "background",
        "#ccc"
      );
    });

  }

  getAMTCalendarConfiguration() {
    let endpoint: string;

    endpoint = "/api/dayview/calendar/settings";
    // Load calendar from initial configuration and data binding.
    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTCalendarConfiguration = data;
        this.calendarOptions.slotDuration = data['duration'];
        this.calendarOptions.nowIndicator = true;
        this.calendarComponent.options.slotMinTime = data['office_hours']['minTime'];
        this.calendarComponent.options.slotMaxTime = data['office_hours']['maxTime'];
        this.calendarComponent.options.slotDuration = data['duration'];
        this.calendarComponent.options.slotLabelInterval = data['slot_interval'];
        this.calendarComponent.options.displayEventTime = data['event_time'];
        this.calendarComponent.options.resourceOrder = 'order_id';
      });
  }

  getAMTFilterConfiguration() {
    let endpoint = "/api/dayview/calendar/filterInit";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.filterLessonType = data['lessonTypes'];

        this.filterInstructorCategory = data['teacherCategory'];
        this.filterInstructor = data['teacherCategory'];
      });
  }

  getInstructorList() {
    let endpoint = "/api/v1/teacherList";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.InstructorList = data;
      })
  }

  getAMTAutocomplete() {
    let endpoint = "/api/v1/autocomplete?";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTConfiguration = data;
      });
  }

  getAMTDayView() {
    let endpoint = "/api/dayview/resource?instructor_category=78&instructor=&lessonType=&student=&calendar_date=&start=2021-12-01T00%3A00%3A00&end=2021-12-02T00%3A00%3A00&_=1638394993251";

    this._drupalRESTService.httpGET(endpoint)
      .subscribe(data => {
        this.AMTDayView = data;
      });
  }

  override handleError(error: any) {
    // console.log("error", error);


    // Handle authentication errors.
    if (error.status == 302) {
      // this.errorMessage = error.error.message;
      // console.log('this means user logged in')
    }

    // Handle authentication errors.
    if (error.status == 403 || error.status == 400) {

      // TODO: this should save state

      // User needs to login.
      this._authService.set_user_authenticated(false);
      this._router.navigate(['/auth/login'], {queryParams: {logged_out_inactive: true}})
    }
  }

  calcDuration(dateStart: moment.MomentInput, dateEnd: moment.MomentInput) {
    let startDate = moment(dateStart);
    let endDate = moment(dateEnd);
    let duration = moment.duration(endDate.diff(startDate));

    let MINUTES = duration.asMinutes();
    let m = duration.asMinutes() % 60;
    let h = (MINUTES - m) / 60;
    let HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();

    return HHMM
  }

  toggleEventConfirmation(event) {
    console.log('event', event);

    // Confirmation status for events.
    let isConfirmed = event.event._def.extendedProps?.['eventDetails']?.data?.isConfirmed;
    let eventID = event.event._def.extendedProps?.['entityId'];

    let body = {
      field_is_confirmed: !isConfirmed,
    }

    this._entityRESTService.patchEntity('events', 'lesson', eventID, body).subscribe(data => {
      this.refreshCalendar();
    })
  }

  copyClick() {
    $("#custom-context-menu").hide();

    this._entityRESTService.getEntity('events', 'lesson', this.copyData?.['id']).subscribe(data => {
      let fieldsData = {
        'field_type': data?.['field_type'],
        'field_instructor': data?.['field_instructor']?.['id'],
        'field_duration': this.copyData?.['eventDuration'],
        'field_date_and_time': data?.['field_date_and_time'],
        "field_student": [
          {
            "type": "attendees",
            "bundle": "attendance",
            "title": data?.['field_student']?.['title'],
            "field_description": data?.['field_student']?.['field_description'],
            "field_enrollment": data?.['field_student']?.['field_enrollment'],
            "field_status": "64", // Pending status
            "field_students": data?.['field_student']?.['field_students'],
            "field_student_account": (data?.['field_student']?.['title'] + ' (' + data?.['field_student']?.['field_student_account'] + ')'),
          }
        ],
      }

      this._dialogService.openDialog(EventLessonEntityComponent, "defaultWithData", {
        data: {
          EntityID: null,
          eckType: 'events',
          bundle: 'lesson',
          action: 'create',
          fieldsData: fieldsData ?? '',
        },
      }).afterClosed().subscribe(data => {
        this.refreshCalendar();
      });

    })
  }

  pasteClick() {
    console.log('paste click');
  }

  closeContextMenu() {
    $("#custom-context-menu").hide();
  }

}
