<div class="container-fluid">
    <h1 class="page-header">Payments Report</h1>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">
                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Payment ID</mat-label>
                        <input name="field_payment_id_value" (input)="onViewsAutocompleteFiltersID($event)" matInput ngModel
                            [matAutocomplete]="autoCompleteViewFilterID">
                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteViewFilterID="matAutocomplete" (optionSelected)="onSubmit(f)">
                        <mat-option *ngFor="let option of autoCompleteOptions" [value]="option.label">
                            {{option.value}} ({{option.label}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <!-- Customer Name Autocomplete -->
                <!-- <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Customer Name</mat-label>
                        <input name="field_customer_name_value" (input)="onViewsAutocompleteCustomerName($event)" matInput
                            ngModel [matAutocomplete]="autoCompleteCustomerName">
                        <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteCustomerName="matAutocomplete" (optionSelected)="onSubmit(f)">
                        <mat-option *ngFor="let option of autocompleteCustomerNameOptions" [value]="option.label">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div> -->

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Choose a date</mat-label>
                        <input matInput
                        (click)="pickerMin.open()"
                        [ngxMatDatetimePicker]="pickerMin"
                        name="field_date_and_time_value[min]"
                        ngModel
                        (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerMin
                            [hideTime]="true"
                            [enableMeridian]="true"
                            [showSpinners]="true"
                            [showSeconds]="false"
                            [stepHour]="1" [stepMinute]="15"
                            [touchUi]="false">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Through</mat-label>
                        <input
                        (click)="pickerMin.open()"
                        matInput [ngxMatDatetimePicker]="pickerMax" name="field_date_and_time_value[max]" ngModel
                            (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
                        <ngx-mat-datetime-picker #pickerMax
                            [hideTime]="true"
                            [enableMeridian]="true"
                            [showSpinners]="true"
                            [showSeconds]="false"
                            [stepHour]="1" [stepMinute]="15"
                            [touchUi]="false">
                        </ngx-mat-datetime-picker>
                    </mat-form-field>
                </div>


                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Status</mat-label>
                        <mat-select matInput ngModel name="field_status_value" (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                            <mat-option value="All" selected="selected">- Any -</mat-option>
                            <mat-option value="Paid">Paid</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Student</mat-label>
                        <input
                        #studentInput name="title_1" (input)="onAutocompleteStudent($event)" matInput ngModel
                            [matAutocomplete]="autoCompleteStudent">
                        <button (click)="redirectUserProfile(studentInput)" type="button" matSuffix mat-icon-button aria-label="manage_accounts">
                            <mat-icon>manage_accounts</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-autocomplete #autoCompleteStudent="matAutocomplete" (optionSelected)="onSubmit(f)">
                        <mat-option *ngFor="let option of autoCompleteStudentOptions" [value]="option.label">
                            {{option.label}} ({{option.value}})
                        </mat-option>
                    </mat-autocomplete>
                </div>

                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>
            </div>

            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>

    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="float-end">
                <button class="payment" mat-flat-button
                    (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'create')" mat-button>
                    Add Payment
                </button>
            </div>
        </div>
    </div>

    <!-- <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
    </mat-form-field> -->

    <!-- <div class="example-container mat-elevation-z8">
    <div class="loading-shade"
    *ngIf="ShowProgressBar">
    <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
        No limit
    </div>
    </div>
    </div> -->

    <div class="table-container">

        <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

            <!-- field_student_name Column -->
            <ng-container matColumnDef="field_student_name">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Student Name">
                    Student Name
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_student_name
                    }} </td>
            </ng-container>

            <!-- field_payment_id Column -->
            <ng-container matColumnDef="field_payment_id">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Payment ID">
                    Payment ID
                </th>
                <td mat-cell *matCellDef="let element"> {{element.field_payment_id}}
                </td>
            </ng-container>

            <!-- field_customer_name Column -->
            <!-- <ng-container matColumnDef="field_customer_name">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Customer Name">
                    Customer Name
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_customer_name
                    }} </td>
            </ng-container> -->

            <!-- field_date_and_time Column -->
            <ng-container matColumnDef="field_date_and_time">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Date">
                    Date
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.field_date_and_time | date }} </td>
            </ng-container>

            <!-- field_gross_tuition Column -->
            <ng-container matColumnDef="field_gross_tuition">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Payment Amount">
                    Payment Amount
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_gross_tuition }}
                </td>
            </ng-container>

            <!-- field_enrollment_name Column -->
            <ng-container matColumnDef="field_enrollment_name">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Enrollment Name">
                    Enrollment Name
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_enrollment_name }} </td>
            </ng-container>

            <!-- field_payment_type Column -->
            <ng-container matColumnDef="field_payment_type">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Payment Type">
                    Payment Type
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_payment_type }} </td>
            </ng-container>

            <!-- field_status Column -->
            <ng-container matColumnDef="field_status">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Status">
                    Status
                </th>
                <td mat-cell *matCellDef="let element"> {{
                    element.field_status }} </td>
            </ng-container>

            <!-- nothing Column -->
            <!-- <ng-container matColumnDef="nothing">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Assign">
                    Assign
                </th>
                <td mat-cell *matCellDef="let element">
                    {{ element.nothing
                    }}
                </td>
            </ng-container> -->

            <!-- field_teacher Column -->
            <ng-container matColumnDef="field_teacher">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Teacher">
                    Teacher
                </th>
                <td mat-cell *matCellDef="let element"> {{ element.AllScheduledPaymentInstructors }} </td>
            </ng-container>

            <!-- edit Column -->
            <!-- <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Edit">
                    Edit
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-stroked-button
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', element.id)"
                        mat-button>
                        Edit
                    </button>
                </td>
            </ng-container> -->

            <!-- edit Column -->
            <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef
                    sortActionDescription="Sort by edit">
                    Actions
                </th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button [matMenuTriggerFor]="paymentMenu" [matMenuTriggerData]="{paymentID: element.id}">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                </td>
            </ng-container>

            <mat-menu #paymentMenu="matMenu">
                <ng-template matMenuContent let-paymentID="paymentID" let-payment="element">
                    <!-- <button mat-menu-item
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', '0', paymentID)"
                        >
                        View
                    </button> -->
                    <button mat-menu-item
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', paymentID)"
                        >
                        Edit
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'delete', paymentID)"
                        >
                        Delete
                    </button>
                    <button mat-menu-item
                        (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'refund', paymentID)"
                        >
                        Refund
                    </button>
                </ng-template>
            </mat-menu>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <ng-container *ngIf="!this.dataSource">
            <div>
            This student has no payments.
            </div>
        </ng-container>

        <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
            [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view">
        </mat-paginator>

    </div>
</div>
