import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import moment from 'moment';
import { ViewTableComponent } from '../../base/view-table/view-table.component';

@Component({
  selector: 'app-ami-misc-report',
  templateUrl: './ami-misc-report.component.html',
  styleUrls: ['./ami-misc-report.component.css']
})
export class AmiMiscReportComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'payment_id',
    'field_sale_date',
    'field_student',
    'field_instructor_percentages',
    'total_charges_due',
    'field_total_payments_made',
    'field_enrollment_package_name',
    'field_sps_code',
    // 'field_package_code',
  ];

  currentReport: string = 'miscReport';
  week: string;
  year: string;

  miscServiceDeductions: any;
  totalPaymentsMadeMiscReport:number = 0;
  totalChargesDueMiscReport: number;
  totalTransportationCharges = 0;
  totalEventExpenses = 0;
  totalSubjectToPercentage = 0;
  additionalAmountDue = 0;
  royaltyPercentage;
  royaltyAmountDue: any;
  lessFeesAlreadyPaid: number;
  netDueOrCreditDue: number;
  totalDeductions: any;


  override ngOnInit(): void {
    // Pull royalty percentage.
    this._fieldsService.getRoyaltyPercentageConfig().subscribe(data => {
      console.log('royalty percentage', data)
      this.royaltyPercentage = data[0].value;
    });
  }


  loadMiscServiceDeductions() {
    this._entityRESTService.getEntity('deductions', 'miscservicededuction', 'null')
    .subscribe(data => {
      let deductions = data as any;

      this.miscServiceDeductions = deductions?.filter((object: { field_week_report_number?: { [x: string]: string; }[]; field_year_report_number: { [x: string]: string; }[]; }) => {
        // let isRefund = (+object.total_receipts.replace('$', '') < 0);
        let isThisReportWeekYear = (object.field_week_report_number?.[0]?.['value'] == this.week);
        let isThisReportYear = (object.field_year_report_number?.[0]?.['value'] == this.year);

        return (isThisReportWeekYear && isThisReportYear);
      });

      this.calcDeductionTotals(this.miscServiceDeductions);
    })
  }

calcTotals(miscReportData: any) {
  this.totalPaymentsMadeMiscReport = 0;
  this.totalChargesDueMiscReport = 0;

  const processedPackageIds = new Set();

  miscReportData.forEach(data => {
    const packageId = data?.package_id;

    // Skip over any duplicate package_id
    if (packageId && processedPackageIds.has(packageId)) {
      return;
    }

    // Mark this package_id as processed
    if (packageId) {
      processedPackageIds.add(packageId);
    }

    // Total Payments Made
    this.totalPaymentsMadeMiscReport += +data?.field_total_payments_made;
    // Total Charges Due
    // this.totalChargesDueMiscReport += (+data?.field_enrollment_total_price - +data?.field_total_payments_made);
    this.totalChargesDueMiscReport += +data?.field_enrollment_total_price;
  });
}

  calcDeductionTotals(miscServiceDeductions: any) {
    this.totalTransportationCharges = 0;
    this.totalEventExpenses = 0;
    this.totalSubjectToPercentage = 0;
    this.additionalAmountDue = 0;

    miscServiceDeductions?.forEach(deduction => {
      console.log('deduction', deduction)
      this.totalTransportationCharges += deduction.field_transportation_cost?.[0]?.value;
      this.totalEventExpenses += deduction.field_event_expenses?.[0]?.value;
      this.additionalAmountDue += deduction.field_transportation_cost?.[0]?.value;
    });

    this.totalDeductions = this.totalEventExpenses + this.totalTransportationCharges

    // totalPaymentsMadeMiscReport - this.totalTransportationCharges - this.totalEventExpenses

    this.totalSubjectToPercentage = this.totalPaymentsMadeMiscReport - this.totalTransportationCharges - this.totalEventExpenses;
    this.royaltyAmountDue = this.totalSubjectToPercentage * (this.royaltyPercentage / 100);
    this.lessFeesAlreadyPaid = this.totalPaymentsMadeMiscReport * (this.royaltyPercentage / 100);
    this.netDueOrCreditDue = this.royaltyAmountDue - this.lessFeesAlreadyPaid;
  }

  override ngAfterContentInit(): void {
    this._activatedRoute.queryParams.subscribe(data => {
      console.log('subscribed data', data)
      this.ShowProgressBar = false;

      if (data['year']) {
        this.year = data['year']
      } else {
        this.year = moment().format("YYYY");
      }
      if (data['week']) {
        this.week = data['week']
      } else {
        this.week = moment().add(0, 'week').format("w");
      }

      let getData = [
        { parameter: 'week', value: this.week},
        { parameter: 'year', value: this.year},
      ];

      // field_package_code
      if (this.form?.value?.field_package_code) {
        getData.push({ parameter: 'field_package_code', value: this.form.value?.field_package_code});
      }

      // field_date_and_time_value_start
      if (this.form?.value?.field_date_and_time_value_start) {
        getData.push({ parameter: 'field_date_and_time_value_start', value: this.form.value?.field_date_and_time_value_start});
      }

      // field_date_and_time_value_start
      if (this.form?.value?.field_date_and_time_value_end) {
        getData.push({ parameter: 'field_date_and_time_value_end', value: this.form.value?.field_date_and_time_value_end});
      }

      this.getData(getData);
    })
  }

  override getData(params: { parameter: string; value: string; }[]) {
    console.log('params', params)
    // Disable buttons if it's the current week.
    let paramWeek = moment().day("Sunday").year(+this.year).week(+this.week).add(1, 'weeks');
    this.isDisabled = paramWeek.isAfter();

    this.clearFormTableData();

    // let filters = '?';
    // if (params) {
    //   for (const property in params) {
    //     // console.log(params[property])
    //     if (params[property]?.['parameter'] == 'Active') {
    //       // filters = filters + "status[]=active&";
    //       filters = filters + "&field_last_lesson_date=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
    //     }
    //     if (params[property]?.['parameter'] == 'Inactive') {
    //       filters = filters + "&field_last_lesson_date=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
    //     }
    //   }
    // }

    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new student list with the filtering parameters.
    this._drupalRESTService.httpGET('/api/v1/miscellaneous-services-report', this.queryParams)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;
        console.log(data['results']);

        let rows = data['results'];

        // No rows? Don't continue...
        if (!rows) { return; }

        this.data = data;
        this.dataSource = new MatTableDataSource(rows);

        this.loadMiscServiceDeductions();
        this.calcTotals(data['results']);

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }

  calcRoyaltyPercentage(amount) {
    amount = this._filterDigits(amount+'');
    return (amount * (this.royaltyPercentage / 100));
  }

}
