<app-entity-mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle title="Schedules"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate">
        </mat-progress-spinner>

        <mat-tab-group class="eventTabGroup" [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form">
                    <div class="container">
                        <div class="row">

                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Schedule Type</mat-label>
                                    <mat-select matInput ngModel name="field_type" (selectionChange)="updateLessonDefaultTimes($event.value)">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._taxonomyService.schedule_type"
                                            [value]="element.id">
                                            {{ element.name }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6" *ngIf="this.actionType != 'create'">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Instructor</mat-label>
                                    <mat-select matInput ngModel name="field_schedule_instructor">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._fieldsService.instructorData()"
                                            [value]="element.uid">
                                            {{ element.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6" *ngIf="this.actionType == 'create'">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Instructor</mat-label>
                                    <mat-select matInput multiple ngModel name="field_schedule_instructor">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._fieldsService.instructorData()"
                                            [value]="element.uid">
                                            {{ element.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-6">

                                <mat-form-field>
                                <input [ngModel]="field_date_and_time"
                                (ngModelChange)="uiUpdateDate($event)"
                                (click)="picker_field_date_time.open()"
                                name="field_date_and_time"
                                matInput
                                [ngxMatDatetimePicker]="picker_field_date_time"
                                placeholder="Choose a date">
                                <mat-datepicker-toggle matSuffix [for]="picker_field_date_time">
                                </mat-datepicker-toggle>
                                <ngx-mat-datetime-picker #picker_field_date_time
                                    [hideTime]="false"
                                    [enableMeridian]="true"
                                    [showSpinners]="true"
                                    [showSeconds]="false"
                                    [stepHour]="1" [stepMinute]="15"
                                    [touchUi]="false">
                                </ngx-mat-datetime-picker>
                                </mat-form-field>
                            </div>


                            <div class="col-6">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Duration</mat-label>
                                    <mat-select matInput ngModel name="field_duration">
                                        <mat-option>None</mat-option>
                                        <mat-option *ngFor="let element of this._fieldsService.durations"
                                            [value]="element.value">
                                            {{ element.value }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-expansion-panel
                                (opened)="recurringEvent = true"
                                (closed)="recurringEvent = false">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            Standing Appointment
                                        </mat-panel-title>
                                        <mat-panel-description>
                                            {{recurringEvent ? 'Repeating event.' : 'This event will not repeat.'}}
                                        </mat-panel-description>
                                    </mat-expansion-panel-header>

                                    <button
                                        type="button"
                                        class="me-1"
                                        (click)="recurringMode = 'Days'"
                                        mat-flat-button
                                        [color]="recurringMode == 'Days' ? 'accent' : 'basic'">Daily</button>
                                    <button
                                        type="button"
                                        class="me-1"
                                        (click)="recurringMode = 'Weeks'"
                                        mat-flat-button
                                        [color]="recurringMode == 'Weeks' ? 'accent' : 'basic'">Weekly</button>
                                    <button
                                        type="button"
                                        class="me-1"
                                        (click)="recurringMode = 'Months'"
                                        mat-flat-button
                                        [color]="recurringMode == 'Months' ? 'accent' : 'basic'">Monthly</button>
                                    <!-- <button
                                    type="button"
                                    class="me-1"
                                    (click)="recurringMode = 'yearly'"
                                    mat-flat-button
                                    color="primary">Yearly</button> -->

                                    <input
                                        class="d-none"
                                        type="text"
                                        [(ngModel)]="recurringMode"
                                        name="field_repetition_units"
                                        value="{{recurringMode}}">

                                    <!-- <input
                                        class="d-none"
                                        type="text"
                                        ngmodel
                                        name="field_status"
                                        value="64"> -->

                                    <div>
                                        Repeat every
                                        <mat-form-field style="width:50px" class="ms-1 me-1">
                                            <input min="0" name="field_repetition_frequency" [ngModel]="interval" matInput type="number">
                                        </mat-form-field>
                                        <ng-container [ngSwitch]="this.recurringMode">
                                            <span *ngSwitchCase="'Days'">day(s).</span>
                                            <span *ngSwitchCase="'Weeks'">week(s).</span>
                                            <span *ngSwitchCase="'Months'"></span>
                                            <span *ngSwitchCase="'yearly'">
                                                years on the DAYDROPDOWN of MONTHDROPDOWN
                                            </span>
                                            <span *ngSwitchDefault></span>
                                        </ng-container>
                                        <span [ngClass]="{ 'd-none': recurringMode != 'Months' }">
                                            months on the
                                            <mat-form-field class="ms-1" style="width:180px">
                                                <mat-label>Select Day</mat-label>
                                                <mat-select
                                                matInput
                                                ngModel
                                                name="field_recurrent_month_day">
                                                    <mat-option>None</mat-option>
                                                    <mat-option
                                                        *ngFor="let element of this._fieldsService.dayDropdown"
                                                        [value]="element.value">
                                                        {{ element.name }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </span>
                                    </div>

                                    <div [ngClass]="{ 'd-none': recurringMode != 'Weeks' }">
                                        <div class="mt-1 mb-3">
                                            <section class="example-section">
                                                <!-- <mat-checkbox *ngFor="let day of daysOfWeek" ngModel name="{{day}}"
                                                    [value]="day"
                                                    class="example-margin">{{day}}</mat-checkbox> -->

                                                <mat-checkbox [ngModel]="field_recurrent_weekday_su" name="field_recurrent_weekday_su" class="example-margin">Sun</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_mo" name="field_recurrent_weekday_mo" class="example-margin">Mon</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_tu" name="field_recurrent_weekday_tu" class="example-margin">Tue</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_we" name="field_recurrent_weekday_we" class="example-margin">Wed</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_th" name="field_recurrent_weekday_th" class="example-margin">Thu</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_fr" name="field_recurrent_weekday_fr" class="example-margin">Fri</mat-checkbox>
                                                <mat-checkbox [ngModel]="field_recurrent_weekday_sa" name="field_recurrent_weekday_sa" class="example-margin">Sat</mat-checkbox>
                                            </section>
                                        </div>
                                    </div>

                                    <div>
                                        <!-- <h3>
                                           Stop Condition
                                        </h3> -->
                                        <div style="border: 1px solid rgb(204, 204, 204); padding: 13px; border-radius:10px;">


<label id="example-radio-group-label"><h3>Stop Condition</h3></label>

<!-- <input
    class="d-none"
    type="text"
    [(ngModel)]="field_expiration_date"
    name="field_expiration_date"
    value="{{field_expiration_date}}"> -->

<mat-radio-group
  [(ngModel)]="field_expiration_date"
  name="field_expiration_date"
  aria-labelledby="example-radio-group-label"
  class="example-radio-group"
  >
  <div class="row" style="">
    <mat-radio-button [ngClass]="{ 'd-none': recurringMode == 'Months' }" (click)="calcDateEOM()" [value]="valueEOM">
        Run until the end of the month
    </mat-radio-button>
    <mat-radio-button [ngClass]="{ 'd-none': recurringMode != 'Months' }" (click)="calcDateEOY()" [value]="valueEOY">
        Run until the end of the year
    </mat-radio-button>
    <!-- <mat-radio-button (click)="calcDateEOY()" value="1">
        <div class="row" style="">
            <span>Run until the end of the year</span>
        </div>
    </mat-radio-button> -->
  </div>
  <div class="row">
    <mat-radio-button [value]="valueSpecificExpirationDate">
        <div class="row">
            <span>Run until a specific date &nbsp;
                <mat-form-field style="width: 150px;">
                    <mat-label>Expiration Date</mat-label>
                    <!-- <input matInput
                        value="{{field_expiration_date}}"
                        (dateInput)="uiUpdateExpirationDate($event);"
                        [matDatepicker]="field_expiration_datepicker">
                    <mat-datepicker-toggle matSuffix [for]="field_expiration_datepicker">
                    </mat-datepicker-toggle>
                    <mat-datepicker #field_expiration_datepicker></mat-datepicker>
                </mat-form-field> -->

                    <input matInput
                        [ngxMatDatetimePicker]="field_expiration_datepicker"
                        value="{{field_expiration_date}}"
                        (dateInput)="uiUpdateExpirationDate($event);">
                    <mat-datepicker-toggle matSuffix [for]="field_expiration_datepicker">
                    </mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #field_expiration_datepicker
                    [hideTime]="true"
                    [enableMeridian]="true"
                    [showSpinners]="true"
                    [showSeconds]="false"
                    [stepHour]="1" [stepMinute]="15"
                    [touchUi]="false"
                    ></ngx-mat-datetime-picker>
                </mat-form-field>
            </span>
        </div>
    </mat-radio-button>
  </div>
</mat-radio-group>


                                            <!-- <div class="row">
                                                <span>Run until a specific date </span>
                                                <span>
                                                    <mat-form-field style="width: 150px;">
                                                        <mat-label>Expiration Date</mat-label>
                                                        <input name="field_expiration_date" matInput [ngModel]="field_expiration"
                                                            (ngModelChange)="uiUpdateDateAlt($event)"
                                                            [matDatepicker]="field_expiration_date">
                                                        <mat-datepicker-toggle matSuffix [for]="field_expiration_date">
                                                        </mat-datepicker-toggle>
                                                        <mat-datepicker #field_expiration_date></mat-datepicker>
                                                    </mat-form-field>
                                                </span>
                                            </div> -->
                                        </div>
                                    </div>


                                    <div class="row">
                                        <!-- <div class="col-4">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>Expiration Date</mat-label>
                                                <input name="field_expiration_date" matInput [ngModel]="field_expiration"
                                                    (ngModelChange)="uiUpdateDateAlt($event)"
                                                    [matDatepicker]="field_expiration_date">
                                                <mat-datepicker-toggle matSuffix [for]="field_expiration_date">
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #field_expiration_date></mat-datepicker>
                                            </mat-form-field>
                                        </div> -->

                                        <!-- <div class="col-4">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>Repetition Frequency</mat-label>
                                                <input name="field_repetition_frequency" ngModel matInput type="number">
                                            </mat-form-field>
                                        </div> -->

                                        <!-- <div class="col-4">
                                            <mat-form-field class="full-width-field">
                                                <mat-label>Repetition Units</mat-label>
                                                <mat-select ngModel name="field_repetition_units">
                                                    <mat-option
                                                        *ngFor="let element of this._fieldsService.repetition_units"
                                                        [value]="element.value">
                                                        {{ element.value }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div> -->
                                    </div>

                                </mat-expansion-panel>
                            </div>

                            <div class="col-12">
                                <mat-form-field class="full-width-field">
                                    <mat-label>Notes</mat-label>
                                    <textarea ngModel name="field_notes" matInput placeholder=""></textarea>
                                </mat-form-field>
                            </div>


                        </div>
                    </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button>
    </ng-container>
</mat-dialog-actions>
