<div class="container-fluid">
  <h1 class="page-header">Canceled Events</h1>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Filter
      </mat-panel-title>
      <mat-panel-description>
        <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
      <div class="row">

        <div class="col-3">
          <mat-form-field class="full-width-field">
            <mat-label>Lesson Type</mat-label>
            <mat-select matInput ngModel name="field_type_target_id" (blur)="onSubmit(f)" (selectionChange)="onSubmit(f)">
              <mat-option value="All" selected="selected">- Any -</mat-option>
              <mat-option *ngFor="let element of this._taxonomyService.lesson_type" [value]="element.id">
                {{ element.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <section class="section">

          <div class="float-end bring-inline">
            <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
              <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
            </button>
          </div>

        </section>


      </div>


      <section class="section">
      </section>

    </form>

  </mat-expansion-panel>

  <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
  <div class="row">
    <div class="col-md-12 mt-3">
      <div class="float-end">
        <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
        <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
      </div>
    </div>
  </div>

  <!-- <mat-form-field appearance="standard">
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field> -->

  <!-- <div class="example-container mat-elevation-z8">
  <div class="loading-shade"
    *ngIf="ShowProgressBar">
    <mat-spinner *ngIf="ShowProgressBar"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      No limit
    </div>
  </div>
  </div> -->

  <div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortDataView($event)" class="mt-4">

      <!-- title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Title">
          Title
        </th>
        <td mat-cell *matCellDef="let element"> {{element.title}} </td>
      </ng-container>

      <!-- field_type Column -->
      <ng-container matColumnDef="field_type">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_type">
          Lesson Type
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_type }} </td>
      </ng-container>

      <!-- field_date_and_time Column -->
      <ng-container matColumnDef="field_date_and_time">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Date and Time">
          Date and Time
        </th>
        <td mat-cell *matCellDef="let element"> {{ element.field_date_and_time }}
        </td>
      </ng-container>

      <!-- type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by type">
          Type
        </th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <!-- edit_events Column -->
      <ng-container matColumnDef="edit_events">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by Edit">
          Edit
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-container *ngIf="element.type == 'Lesson'">
            <button mat-stroked-button (click)="openEntityComponent(EventLessonEntityComponent, 'events', element.type.toLowerCase(), 'edit', element.id)" mat-button>
              Edit
            </button>
          </mat-container>
          <mat-container *ngIf="element.type == 'Group Lesson'">
            <button mat-stroked-button (click)="openEntityComponent(EventGroupLessonEntityComponent, 'events', element.type.toLowerCase(), 'edit', element.id)" mat-button>
              Edit
            </button>
          </mat-container>
          <mat-container *ngIf="element.type == 'Services'">
            <button mat-stroked-button (click)="openEntityComponent(EventServicesEntityComponent, 'events', element.type.toLowerCase(), 'edit', element.id)" mat-button>
              Edit
            </button>
          </mat-container>
          <mat-container *ngIf="element.type == 'Schedule'">
            <button mat-stroked-button (click)="openEntityComponent(EventScheduleEntityComponent, 'events', element.type.toLowerCase(), 'edit', element.id)" mat-button>
              Edit
            </button>
          </mat-container>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
      [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

  </div>
</div>
