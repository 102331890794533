<h1 class="page-header">AMI Reports</h1>

<div class="text-center bring-inline">
    <a [routerLink]="['/ami-staff-performance']" mat-button mat-flat-button color="">
        Staff Performance Report
        <!-- <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="">payments</mat-icon> -->
    </a>
    &nbsp;
    <a [routerLink]="['/studio-business-summary']" mat-button mat-flat-button color="">
        Studio Business Report
        <!-- <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="">groups</mat-icon> -->
    </a>
    &nbsp;
    <a [routerLink]="['/royalty-report']" mat-button mat-flat-button color="">
        Royalty Report
        <!-- <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="">apartment</mat-icon> -->
    </a>
    &nbsp;
    <a [routerLink]="['/miscellaneous-report']" mat-button mat-flat-button color="">
        Miscellaneous Report
        <!-- <mat-icon aria-hidden="false" aria-label="Menu dropdown" class="">sell</mat-icon> -->
    </a>
  </div>
