import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { BaseFormComponent } from 'src/app/components/forms/base-form/base-form.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseFormComponent implements OnInit {

  override onSubmit(f: NgForm) {
    let values = f.form.value;

    this.displayProgressSpinner(true);

    this._authRESTService.login(values.name, values.pass)
      .subscribe(data => {
        this._authService.current_user = data['current_user'];
        this._authService.csrf_token = data['csrf_token'];
        this._authService.logout_token = data['logout_token'];

        this._menuRESTService.getMenu();

        // Let Angular know the user is logged in.
        this._authService.set_user_authenticated(true);

        // Navigate to dashboard.
        this._router.navigate(['/app/splash']);

        this.displayProgressSpinner(false);
      },
        error => this.handleError(error)
    )
  }

  ngOnInit(): void {
    this._activatedRoute.queryParams
      .subscribe(params => {
        console.log(params);
        if (params['logged_out']) {
          this.successMessage = "You have been logged out.";
        }
        if (params['logged_out_inactive']) {
          this.successMessage = "You have been logged out due to inactivity.";
        }
      }
      );
  }

}
