import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from '../../base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';

@Component({
  selector: 'app-students-inventory',
  templateUrl: './students-inventory.component.html',
  styleUrls: ['./students-inventory.component.css']
})
export class StudentsInventoryComponent extends ViewTableComponent implements OnInit {

  override displayedColumns: string[] = [
    'name',
    'field_student_department',
    'address',
    'totalEnrolled',
    'lessonUsed',
    'remaining',
    'totalDueLessons',
    'miscService',
    // 'totalDue',
    'total_due_misc_field',
    'lastLesson',
    'futureLesson'
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Handle different checkbox based filters.
    let start, end;
    let filters = '?field_archive=1&';
    let nfa;
    let today = moment().format('YYYY-MM-DD');
    if (params) {
      for (const property in params) {
        // console.log(params[property])
        if (params[property]?.['parameter'] == 'Active') {
          // filters = filters + "status[]=active&";
          filters = filters + "&field_last_lesson_taken_value=" + moment().subtract(30, 'days').format('YYYY-MM-DD') + "--";
        }
        if (params[property]?.['parameter'] == 'Inactive') {
          filters = filters + "&field_last_lesson_taken_value=" + "--" + moment().subtract(30, 'days').format('YYYY-MM-DD');
        }
        if (params[property]?.['parameter'] == 'NFA') {
          filters = filters + "&field_nfa=1";
          filters = filters + "&field_total_lessons_remaining_value[value]=0";
          filters = filters + "&field_total_lessons_remaining_value[min]=";
          filters = filters + "&field_total_lessons_remaining_value[max]=";
          filters = filters + "&field_total_lessons_remaining_value_op=>";
        }
        if (params[property]?.['parameter'] == 'paid_finished') {
          filters = filters + "&field_paid_finished=true";
          filters = filters + "&field_total_lessons_paid_ahead_value=0";
          filters = filters + "&field_total_lessons_remaining_value[value]=0"
          filters = filters + "&field_total_lessons_remaining_value[min]=";
          filters = filters + "&field_total_lessons_remaining_value[max]=";
          filters = filters + "&field_total_lessons_remaining_value_op==";
        }

        if (params[property]?.['parameter'] == 'last_lesson_taken_start') {
          start = params[property]['value'];
        }
        if (params[property]?.['parameter'] == 'last_lesson_taken_end') {
          end = params[property]['value'];
        }

        // field_last_lesson_taken_value
        // event_date_and_time_value_start
        // event_date_and_time_value_end
      }

      if (start && end) {
        filters = filters + "&field_last_lesson_taken_value=" + start + "--" + end;
      }
    }

    this.filters = filters;

    // Load the new data with the filtering parameters.
    let endpoint: string = "/api/v2/students_inventory" + filters;

    this._drupalRESTService.httpGET(endpoint, params)
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.dataSource = new MatTableDataSource(data['results']);

        // // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }

}
