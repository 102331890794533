<div class="container-fluid">
    <h1 class="page-header">Miscellaneous Report</h1>

    <app-report-nav-menu [week]="this.week" [year]="this.year" [currentReport]="this.currentReport"></app-report-nav-menu>

    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                Filter
            </mat-panel-title>
            <mat-panel-description>
                <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
            </mat-panel-description>
        </mat-expansion-panel-header>
        <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
            <div class="row">

                <!-- Package Dropdown -->
                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>Select Misc Package...</mat-label>
                        <mat-select
                        name="field_package_code"
                        ngModel
                        (selectionChange)="onSubmit(f)">
                        <!-- <mat-option value="All">All</mat-option> -->
                        <mat-option
                        *ngFor="let package of this._taxonomyService.package_code"
                        [value]="package.id">{{ package.name }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Date Range Filter -->
                <div class="col-4">
                    <mat-form-field>
                    <mat-label>Enter a date range (Payments)</mat-label>
                    <mat-date-range-input [rangePicker]="picker">
                        <input name="field_date_and_time_value_start" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
                        <input name="field_date_and_time_value_end" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
                    </mat-date-range-input>
                    <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Week</mat-label>
                        <mat-select matInput [ngModel]=week name="week" (selectionChange)="onSubmit(f)">
                            <mat-option *ngFor="let element of WeeksForYearData" [value]="element.value">{{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    <mat-form-field class="full-width-field">
                        <mat-label>Year</mat-label>
                        <mat-select matInput [ngModel]=year name="year" (selectionChange)="onSubmit(f)">
                            <mat-option *ngFor="let element of LastFourYearsData" [value]="element.value"> {{ element.value }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="col-3">
                    &nbsp;
                </div>

                <!-- Event Start/End Date Range Filter -->
                <div class="col-4">
                    <mat-form-field>
                        <mat-label>When did the event start/end? (Reporting purposes)</mat-label>
                        <mat-date-range-input [rangePicker]="picker_event_start_end">
                            <input name="event_date_and_time_value_start" ngModel matStartDate placeholder="Start date">
                            <input name="event_date_and_time_value_end" ngModel matEndDate placeholder="End date">
                        </mat-date-range-input>
                        <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
                        <mat-datepicker-toggle matSuffix [for]="picker_event_start_end"></mat-datepicker-toggle>
                        <mat-date-range-picker #picker_event_start_end></mat-date-range-picker>
                    </mat-form-field>

                </div>

                <div class="col-3">
                    <p>Event Expenses: ${{ this.totalEventExpenses | number }}</p>
                    <input class="d-none" [ngModel]="totalEventExpenses" name="package_costs" matInput>

                    <p>Transportation Costs: ${{ this.totalTransportationCharges | number }}</p>
                    <input class="d-none" [ngModel]="totalTransportationCharges" name="transportation_costs" matInput>
                </div>

                <div class="col-2">
                </div>

                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>
            </div>


            <section class="section">
            </section>

        </form>

    </mat-expansion-panel>

    <mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
    <div class="row">
        <div class="col-md-12 mt-3">
            <div class="float-end">
                <button
                [disabled]="this.isDisabled"
                class="me-1"
                mat-raised-button color="primary"
                (click)="openDialog(SendUpdateAPIComponent, null, {
                    endpoint: 'sendMiscellaneousServiceReportToApi',
                    operation: 'Send API',
                    form: this.f.form.value
                });">Send API</button>
                <button
                [disabled]="this.isDisabled"
                class="me-1"
                mat-raised-button color="primary"
                (click)="openDialog(SendUpdateAPIComponent, null, {
                    endpoint: 'sendMiscellaneousServiceReportToApi',
                    operation: 'Update API',
                    form: this.f.form.value
                });">Update API</button>

                <button
                    [routerLink]="['/ami-reports']"
                    class="me-1"
                    mat-raised-button color="secondary"
                    >Back to list</button>
            </div>
        </div>
    </div>

    <table class="mat-table cdk-table mat-sort mt-4" role="table"
        *ngIf="data != undefined && data['#royalty_header_table'] != undefined">
        <thead role="rowgroup">
            <tr>
                <th class="mat-header-cell">Report Type</th>
                <th class="mat-header-cell">Prepared By</th>
                <th class="mat-header-cell">Year</th>
                <th class="mat-header-cell">Week Number</th>
                <th class="mat-header-cell">Date Range</th>
            </tr>
        </thead>
        <tr>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][0] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][1] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][2] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][3] }}</td>
            <td class="mat-row">{{ data['#royalty_header_table']['royalty_data_header']['#rows'][0][4] }}</td>
        </tr>
    </table>



    <div class="table-container">

        <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

            <!-- field_sale_date Column -->
            <ng-container matColumnDef="field_sale_date">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_sale_date">
                    Date
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.field_date_and_time | date }}</ng-container>
                </td>
            </ng-container>

            <!-- field_student Column -->
            <ng-container matColumnDef="field_student">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_student">
                    Participants
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.field_student }}</ng-container>
                </td>
            </ng-container>

            <!-- field_instructor_percentages Column -->
            <ng-container matColumnDef="field_instructor_percentages">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_instructor_percentages">
                    Teacher
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.field_first_name }} {{ element.field_last_name }}</ng-container>
                </td>
            </ng-container>

            <!-- field_enrollment_total_price Column -->
            <!-- <ng-container matColumnDef="field_enrollment_total_price">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_enrollment_total_price">
                    Total Charges Due
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element?.field_enrollment_total_price ? '$' + element?.field_enrollment_total_price : '' }}</ng-container>
                </td>
            </ng-container> -->

            <!-- field_total_payments_made Column -->
            <ng-container matColumnDef="field_total_payments_made">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_total_payments_made">
                    Amount of Payment
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ (element.field_gross_tuition) ? element.field_gross_tuition : '0' }}</ng-container>
                </td>
            </ng-container>

            <!-- field_total_payments_made Column -->
            <ng-container matColumnDef="field_enrollment_package_name">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_field_enrollment_package_nametotal_payments_made">
                    Package Name
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{element.field_enrollment_package_name}}</ng-container>
                </td>
            </ng-container>

            <!-- field_sps_code Column -->
            <ng-container matColumnDef="field_sps_code">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_sps_code">
                    SPS Code
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.field_sps_code }}</ng-container>
                </td>
            </ng-container>

            <!-- field_package_code Column -->
            <!-- <ng-container matColumnDef="field_package_code">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_package_code">
                                Package Code
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.field_package_code }}</ng-container>
                </td>
            </ng-container> -->

            <!-- payment_id Column -->
            <ng-container matColumnDef="payment_id">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by payment_id">
                    Reciept #
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container>{{ element.payment_id }}</ng-container>
                </td>
            </ng-container>

            <!-- total_charges_due Column -->
            <ng-container matColumnDef="total_charges_due">
                <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by total_charges_due">
                    Total Charges Due<!-- Gross Tuition -->
                </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <ng-container>${{ element.field_enrollment_total_price - element.field_total_payments_made }}</ng-container> -->
                    <ng-container>${{ element.field_enrollment_total_price }}</ng-container>
                </td>
            </ng-container>


            <!-- REPLACE Column -->
            <!-- <ng-container matColumnDef="REPLACE">
        <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
            REPLACE
        </th>
        <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.REPLACE">{{ element.REPLACE }}</ng-container>
        </td>
        </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <!-- Totals Table  -->
        <table class="mat-table cdk-table mat-sort mt-4" role="table"
            *ngIf="data != undefined && data['#royalty_header_table'] != undefined">
            <thead role="rowgroup">
                <tr>
                    <!-- <th class="mat-header-cell">Total</th> -->
                    <th class="mat-header-cell">Lesson Count</th>
                    <th class="mat-header-cell">Total Price</th>
                    <th class="mat-header-cell">Cash</th>
                    <th class="mat-header-cell">Misc Services</th>
                    <th class="mat-header-cell">Subject to Royalty</th>
                    <th class="mat-header-cell">Sundry</th>
                    <th class="mat-header-cell">Total Reciepts</th>
                </tr>
            </thead>
            <tr>
                <!-- <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['cash'] }}</td> -->
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['lesson_count'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['total_price'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['cash'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['miscellaneous_services'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['subject_to_royalty'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['sundry'] }}</td>
                <td class="mat-row">{{ data['#royalty_content_table']['staff_data_4_system']['#footer'][0].data['total_receipts'] }}</td>
            </tr>
        </table>

        <!-- Totals Summary Table  -->
        <mat-card>
            <mat-card-title>
            Deductions & Summary
            </mat-card-title>

            <mat-card-content>

                <div class="float-end mb-3">
                    <button
                    class="me-1"
                    (click)="openEntityComponent(MiscServiceDeductionsEntityComponent, 'deductions', 'miscservicededuction', 'create', '',
                    {
                        'week': this.week,
                        'year': this.year
                    })"
                    type="button"
                    mat-button>
                        Add Deduction
                </button>
                </div>

                <ng-container *ngIf="this.miscServiceDeductions?.[0] == undefined">
                    <!-- <div class="row"> -->
                        <!-- <p class="">Please add a deduction.</p> -->
                    <!-- </div> -->
                </ng-container>

                <table class="mat-table cdk-table mat-sort mt-4" role="table"
                *ngIf="this.miscServiceDeductions != undefined && this.miscServiceDeductions[0] != undefined">
                    <thead role="rowgroup">
                        <tr>
                            <th class="mat-header-cell">ID</th>
                            <th class="mat-header-cell">Name</th>
                            <th class="mat-header-cell">Week # / Year</th>
                            <th class="mat-header-cell">Event Expenses</th>
                            <th class="mat-header-cell">Transportation Costs</th>
                            <th class="mat-header-cell">&nbsp;</th>
                        </tr>
                    </thead>

                    <tr *ngFor="let deduction of this.miscServiceDeductions;">
                        <td class="mat-row">{{ deduction.id?.[0]?.value }}</td>
                        <td class="mat-row">{{ deduction.title?.[0]?.value }}</td>
                        <td class="mat-row">{{ deduction.field_week_report_number?.[0]?.value | json }} / {{ deduction.field_year_report_number?.[0]?.value }}</td>
                        <td class="mat-row">{{ deduction.field_event_expenses?.[0]?.value | json }}</td>
                        <td class="mat-row">{{ deduction.field_transportation_cost?.[0]?.value }}</td>
                        <td>
                            <button
                            mat-stroked-button
                            (click)="openEntityComponent(MiscServiceDeductionsEntityComponent, 'deductions', 'miscservicededuction', 'edit', deduction.id?.[0]?.value)"
                            mat-button>
                                Edit
                            </button>
                        </td>
                    </tr>
                </table>

                <table id="deductionSummaryTable" class="mat-table cdk-table mat-sort mt-4" role="table">
                    <thead role="rowgroup">
                        <tr>
                            <th class="mat-header-cell"></th>
                            <th class="mat-header-cell"></th>
                            <th class="mat-header-cell">Total Charges Due</th>
                            <th class="mat-header-cell">Amount of Payment</th>
                            <th class="mat-header-cell"></th>
                        </tr>
                    </thead>

                    <tr>
                        <td class="mat-row"></td>
                        <td class="mat-row">TOTALS:</td>
                        <td class="mat-row">${{ totalChargesDueMiscReport | number }}</td>
                        <td class="mat-row">${{ totalPaymentsMadeMiscReport | number }}</td>
                    </tr>

                    <tr>
                        <td>Attach Substantiating Detail per Policy Release #460</td>
                        <td colspan="2" class="text-center mat-row"><b>(D-O-R ONLY) DEDUCT</b></td>
                        <td class="mat-row"></td>
                    </tr>

                    <tr>
                        <td style="width: 50%;" class="mat-row" rowspan="5">I have checked this statement and certify that it is correct and complete.  I also certify that non of the above listed enrollments or payments resulted in a violation of the Arthur Murray International, Inc. Dollar Limitation Policy or limitations set by other regulatory agencies.  I further certify that all the above have been enrolled on Arthur Murray International, Inc. approved student enrollment agreements.</td>
                    </tr>

                    <!-- TRANSPORTATION CHARGES -->
                    <tr>
                        <td class="mat-row">TRANSPORTATION CHARGES</td>
                        <td class="mat-row">${{ this.totalTransportationCharges | number }}</td>
                        <td class="mat-row"></td>
                    </tr>

                    <!-- EXPENSES -->
                    <tr>
                        <td class="mat-row">EVENT EXPENSES</td>
                        <td class="mat-row">${{ this.totalEventExpenses | number }}</td>
                        <td class="mat-row"></td>
                    </tr>

                    <!-- LESS TOTAL DEDUCTIONS -->
                    <tr>
                        <td class="mat-row text-center" colspan="2">LESS TOTAL DEDUCTIONS</td>
                        <td class="mat-row">${{ totalDeductions | number }}</td>
                    </tr>

                    <!-- TOTAL SUBJECT TO PERCENTAGE To Percentage -->
                    <tr>
                        <td class="mat-row text-center" colspan="2">TOTAL SUBJECT TO PERCENTAGE</td>
                        <td class="mat-row">${{ this.totalSubjectToPercentage | number }}</td>
                    </tr>

                    <!-- Royalty AMT Due -->
                    <tr>
                        <td class="mat-row"></td>
                        <td class="mat-row">Royalty Amount Due At</td>
                        <td class="mat-row">{{ this.royaltyPercentage }}%</td>
                        <td class="mat-row">${{ this.royaltyAmountDue | number }}</td>
                    </tr>

                    <!-- Less Fees Already Paid -->
                    <tr>
                        <td class="mat-row"></td>
                        <td class="mat-row text-center" colspan="2">LESS FEES ALREADY PAID (-)</td>
                        <td class="mat-row">${{ lessFeesAlreadyPaid | number }}</td>
                    </tr>

                    <!-- Net Due -->
                    <tr>
                        <td class="mat-row"></td>
                        <td class="mat-row text-center" colspan="2">NET DUE ** or (CREDIT DUE)</td>
                        <td class="mat-row">${{netDueOrCreditDue | number}}</td>
                    </tr>

                    <!-- ADDITIONAL AMOUNT DUE -->
                    <!-- <tr>
                        <td class="mat-row">ADDITIONAL AMOUNT DUE</td>
                        <td class="mat-row">0</td>
                    </tr> -->
                </table>
            </mat-card-content>
        </mat-card>


        <ng-container *ngIf="this.paginator?.length == 0">
            <div>
                Please select criteria to generate a report.
            </div>
        </ng-container>

        <mat-paginator (page)="pageChanged($event)"
        [pageSizeOptions]="onPageSizeOptions()" [length]="30"
        [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex"
        aria-label="Select page of view"></mat-paginator>

    </div>
</div>
