import { Component, Input, OnInit, ViewChild, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { FormBuilder, FormGroup, Validators, NgForm, FormArray, FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import _ from 'lodash';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseFormComponent } from '../base-form/base-form.component';
import { CreditCardReadInputComponent } from '../credit-card-read-input/credit-card-read-input.component';

@Component({
  selector: 'app-payments-entity',
  templateUrl: './payments-entity.component.html',
  styleUrls: ['./payments-entity.component.css']
})
export class PaymentsEntityComponent extends BaseDialogComponent implements OnInit {

  override eckType = "payments";
  override bundle = "payment";
  override entityId = this._injectedDialogData['data']['EntityID'];

  CreditCardReadInputComponent = CreditCardReadInputComponent;

  paymentDeleteForm: FormGroup;
  field_enrollment_name: any;
  __field_enrollment_name: any;

  instructorPercentageEditMode: boolean; // delete

  override ngOnInit(): void {
    this.initActionType = this._injectedDialogData['data']['action'];
    this.actionType = this._injectedDialogData['data']['action'];

    let formGroup = {
      field_payment_id: [this.paymentID, Validators.required],
      // field_customer_name: [null],
      field_date_and_time: [this.todaysDate, Validators.required],
      field_gross_tuition: [null, Validators.required],
      field_student_name: [null, Validators.required],
      field_scheduled_payment: this._formBuilder.group({
        'target_id': [null]
      }),
      field_payment_type: [null, Validators.required],
      cc_number: [null],
      cc_cvv: [null],
      cc_expiration: [null],
      cc_expiration_month: [null],
      cc_expiration_year: [null],
      cc_postal_code: [null],
      field_stripe_charge_id: [this.entityData?.field_stripe_charge_id],
      reason_to_refund: [null],
      field_payments_use_card: [null],
    }

    let formGroupEdit;

    // Support for single or multiple enrollments.
    if (this.initActionType == "create") {
      formGroupEdit = {
        ...formGroup,
        field_enrollment_name: new FormArray([
          this._formBuilder.group({
            'target_id': [null]
          })
        ]),
      }
    } else {
      formGroupEdit = {
        ...formGroup,
        field_enrollment_name: this._formBuilder.group({
          'target_id': [null, Validators.required]
        }),
      }
    }

    this.paymentForm = this._formBuilder.group(formGroupEdit);

    this.paymentDeleteForm = this._formBuilder.group({
      field_reason_to_delete: [null, Validators.required],
    });


    // Conditional validation for when Stripe is selected.
    this.paymentForm.get('field_payment_type').valueChanges
    .subscribe(value => {
      console.log('here', value)
      console.log('this.initActionType', this.initActionType)
      if(value == 1407 /* Stripe */ && this.initActionType == 'create') {
        this.paymentForm.controls['field_payments_use_card'].setValidators([Validators.required]);
      } else {
        this.paymentForm.controls['field_payments_use_card'].clearValidators();
      }
    }
);
  }

  override ngAfterViewInit() {
    setTimeout(() => {
      this._loadFieldsData(this.injectedDialogData['data']['fieldsData'], this.paymentForm);

      if (this.initActionType == 'create' || this.initActionType == 'delete' || this.initActionType == 'refund') {
        this.getUniquePaymentID()
          .subscribe(data => {
            this.paymentID = data;
            // this.setFormMode("create");
            if (this.initActionType == 'refund') {
              this.paymentSaleDate = moment();

            // console.log('this.paymentForm', this.paymentForm.value)
            this._loadFieldsData(this.injectedDialogData['data']['fieldsData'], this.paymentForm.value);

            // Reset the field_payment_type field if it's not a stripe payment.
            if (this.paymentForm.value.field_payment_type != 1407 && this.paymentForm.value.field_status != 'Refund') {
              this.paymentForm.controls['field_date_and_time'].reset();
              this.paymentForm.controls['field_payment_type'].reset();
            }

            this.paymentForm.controls['reason_to_refund'].setValue('requested_by_customer');
          }
        });
      }
    }, 500); // TODO: This is really bad to rely on a timeout. Needs to be a observable or subject.
  }

  onSubmitPayment(f: NgForm, drop = false) {
    this.displayProgressSpinner(true);
    console.log('onSubmitPayment called...');

    console.log('f.value', f.value);
    console.log('this.initActionType', this.initActionType);

    // Support for single or multiple enrollments.
    if (this.initActionType == "create" || this.initActionType == "refund") {
      this.errorMessage = "";

      // Copy the object to not modify binded data.
      let values = _.cloneDeep(f.value);
      this.__alterValues(values);

      if (this.initActionType == "create") {
        // Change "field_enrollment_name" to "enrollment_ids"
        values.enrollment_ids = values.field_enrollment_name;
      } else {
        // Refund
        values.enrollment_ids = [ values.field_enrollment_name ];
        values.field_gross_tuition = -(Math.abs(values.field_gross_tuition));
        values.field_stripe_charge_id = this.entityData?.field_stripe_charge_id;
      }

      // Support for refunds.
      if (this.initActionType == "refund") {
        this.paymentForm.value.field_gross_tuition = -(Math.abs(this.paymentForm.value.field_gross_tuition));
        this.actionType == "refund"
        // this.onSubmit(this.paymentForm);
      }

      let body = {
        ...values,
      }

      this._drupalRESTService.httpPOST('/api/v1/postPayment', body).subscribe(data => {
        console.log('data', data)

        this.displayProgressSpinner(false);

        if (drop) {
          let params = [
            { parameter: 'enrollment_id', value: this.paymentForm.value.field_enrollment_name.target_id},
            { parameter: 'drop_date', value: moment(this.paymentForm.value.field_date_and_time).format("YYYY/MM/DD") },
          ];
          this._drupalRESTService.httpGET('/api/v1/dropEnrollment', params)
          .subscribe(data => {
            this.displayProgressSpinner(false);
            console.log(data)

            // TODO: Get proper messages for these API calls.
            if (data['success']) {
              this.closeDialog();

              this.displayProgressSpinner(false);
              this.closeDialog();
            }
            // if (data['success'] == false && data['message']) {
              // this.errorMessage = data['message'];
            // }
          },
            error => {
              this.errorMessage = "There was a problem dropping this enrollment.";
              this.handleError(error)
              this.displayProgressSpinner(false);
            });
        } else {
          this.displayProgressSpinner(false);
          this.closeDialog();
        }
      },
        error => this.handleError(error)
      )
    } else {
        // Otherwise submit as normal to eckEntityWrapper.
        this.onSubmit(this.paymentForm);
    }
  }

  /**
   * @param form
   */
  override onDeleteSubmit(form: NgForm) {
    this.displayProgressSpinner(true);
    console.log('onDeleteSubmit called...', this.entityId);
    this._entityRESTService.getEntity(this.eckType, this.bundle, this.entityId).subscribe(data => {
      let body = data;
      body = {
        field_status: 'Deleted',
        field_reason_to_delete: this.paymentDeleteForm.value.field_reason_to_delete,
        field_enrollment_name: {target_id: data?.['field_enrollment_name']?.['id']},
        field_student_name: data?.['field_student_name']?.['id'],
      };

      this._entityRESTService.patchEntity(this.eckType, this.bundle, this.entityId, body).subscribe(data => {
        this.displayProgressSpinner(false);
        this.closeDialog();

        // Delete the entity.
        this._entityRESTService.deleteEntity(this.eckType, this.bundle, this.entityId).subscribe(data => {
          this.displayProgressSpinner(false);
          this.closeDialog();
        }), error => this.handleError(error)

      },
      error => this.handleError(error)
      );
    });
    let body = {
      ...this._entityData,
      field_status: 'Deleted'
    };
    let type = this.eckType;
    let bundle = this.bundle;
    let id = this._injectedDialogData['data']['EntityID'];
  }

}
