<app-entity-mat-dialog-title title="Sign Enrollment"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>

					<form [formGroup]="this?.agreementFormGroup">

						<input class="d-none" formControlName="field_nothing">
						<!-- <input formControlName="firstName"> -->

						<p class="text-center"><img style="max-width:350px;" src="assets/images/AMDCBlackHorizontalJPG.jpg" /></p>

						<h3>{{ this.entityData?.title }}</h3>

						<div class="row">
								<div class="col-12">

										<p><strong>Student Name:</strong> {{ entityData?.['field_student']?.['title'] }} ({{ entityData?.['field_student']?.['id'] }})</p>

										<p><strong>Personal Lessons:</strong> {{ entityData?.['field_enrollment_lesson_count'] }}</p>

										<!-- Test for single payment -->
										<ng-container *ngIf="!is_array(this.entityData?.field_scheduled_payments)">
												<!-- TODO: Calculate "Initial Payment" by adding up all down payments. -->
												<!-- <p>Initial Payment: {{ this.entityData?.field_scheduled_payments?.title }}</p> -->

												<p>Payment Arrangement:</p>
												<div class="mb-2">
														<li>Amount: ${{ this.entityData?.field_scheduled_payments?.[0]?.field_payment_amount }} - Due: {{ this.entityData?.field_scheduled_payments?.[0]?.field_payment_date }}</li>
												</div>
										</ng-container>

										<!-- Test for multiple payments -->
										<ng-container *ngIf="entityData?.field_scheduled_payments && is_array(entityData?.field_scheduled_payments) && objLength(this.entityData?.field_scheduled_payments) > 0">
												<!-- TODO: Calculate "Initial Payment" by adding up all down payments. -->
												<!-- <p>Initial Payment: {{ this.entityData?.field_scheduled_payments?.title }}</p> -->
												<p>Payment Arrangement(s):</p>
												<div *ngFor="let payment of this.entityData.field_scheduled_payments" class="mb-2">
														<li>Amount: ${{ payment?.[0]?.field_payment_amount }} - Due: {{ (payment?.[0]?.field_payment_date != '') ? payment?.[0]?.field_payment_date : 'TBD' }}</li>
												</div>
										</ng-container>

										<p><strong>Finance Charge:</strong> {{ entityData?.field_finance_charge ? this.entityData?.field_finance_charge : '0' }}%</p>

										<p><strong>Discount:</strong>
												<ng-container *ngIf="this.entityData?.field_discount_type == 'flat'">
														${{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}
												</ng-container>
												<ng-container *ngIf="this.entityData?.field_discount_type == 'percentage'">
														{{ entityData?.field_discount ? this.entityData?.field_discount : '0' }}%
												</ng-container>
												<ng-container *ngIf="this.entityData?.field_discount_type != 'percentage' && this.entityData?.field_discount_type != 'flat'">
														0%
												</ng-container>
										</p>

										<p><strong>Taxes:</strong> {{ entityData?.field_tax_percentage ? this.entityData?.field_tax_percentage : '0' }}%</p>

										<p><strong>APR:</strong> {{ entityData?.field_finance_apr ? this.entityData?.field_finance_apr : '0' }}%</p>

										<p><strong>Enrollment Date</strong></p>
										<p>{{ this.entityData?.['field_sale_date'] | date }}</p>

										<!-- <p> -->
												<!-- Address:<br /> -->
												<!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line1">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line1 }},<br /></span> -->
												<!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.address_line2">{{ this.studentAccountEntityData?.field_contacts?.field_address?.address_line2 }}<br /></span> -->
												<!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.locality">{{ this.studentAccountEntityData?.field_contacts?.field_address?.locality }},</span> -->
												<!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area">{{ this.studentAccountEntityData?.field_contacts?.field_address?.administrative_area }}</span> -->
												<!-- <span *ngIf="this.studentAccountEntityData?.field_contacts?.field_address?.postal_code">{{ this.studentAccountEntityData?.field_contacts?.field_address?.postal_code }}</span> -->
										<!-- </p> -->

										<!-- <p>
												Email: {{ this.studentAccountEntityData?.field_contacts?.field_email }}
										</p> -->
										<!-- <p>
												Date:
										</p> -->
										<!-- <p>
												Phone: {{ this.studentAccountEntityData?.field_contacts?.field_cell_phone }}
										</p> -->
								</div>

								<div class="col-6">
								</div>
						</div>

						<p>
								Notice to Buyer: Do not sign this agreement before you read it or if it contains any blank spaces. You are entitled to a copy of the agreement you sign. Keep this agreement to protect your legal rights. You WILL NOT have to pay a penalty if paid off early. You MAY be entitled to a refund or part of the finance charge, under rule 78, pro‐rata or actuarial method whichever is applicable in your state.
						</p>

						<p>
								It is agreed that the Studio’s obligation for furnishing instruction under this agreement shall expire on
								<strong>{{ this.entityData?.field_expiration_date | date }}</strong>
								, or two years from the date of this agreement whichever occurs first.
						</p>

						<p class="text-center">
								<small><a class="cursor-pointer" (click)="printPage(true, false)">Click here to view back of agreement (fully included and opened when printed or emailed)</a></small>
						</p>

						<div class="row pb-3 pt-3">
							<p *ngIf="this.entityData?.['field_sale_date']">Signed on: {{ this.entityData?.['field_sale_date'] | date }}</p>

							<div class="col-6">
									<p>Studio Representative:</p>
									<canvas *ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url'] == null" width="250px" height="125px" class="signature-canvas" #SignatureStudio></canvas>

									<div *ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']">
											<p><strong>Executive Approval:</strong></p>
											<img class="img-fluid" [src]="this._enrollmentEntityData?.['field_representative_signature'][0]?.['url']" />
									</div>
									<button class="mt-1" type="button" mat-stroked-button (click)="signatureClearStudio()" *ngIf="this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url'] == null">Clear</button>
							</div>

							<div class="col-6">
									<p>Student Approval:</p>
									<canvas *ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url'] == null" width="250px" height="125px" class="signature-canvas" #SignatureStudent></canvas>

									<div *ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']">
											<p><strong>Student Signature:</strong></p>
											<img class="img-fluid" [src]="this._enrollmentEntityData?.['field_signature']?.[0]?.['url']" />
									</div>
									<button class="mt-1" type="button" mat-stroked-button (click)="signatureClearStudent()" *ngIf="this._enrollmentEntityData?.['field_signature']?.[0]?.['url'] == null">Clear</button>
							</div>
						</div>

						<div class="row mt-3">
								<div class="col-6">
										<button (click)="openDrupalLink('/print/pdf/packages/' + this.entityData?.id)"  class="me-1" type="button" mat-stroked-button color="primary">
												Print
										</button>
										<button (click)="openDrupalLink('/amt_enrollment/send-enrollment-email/' + this.entityData?.id)" class="me-1" type="button" mat-stroked-button color="primary">
												Email
										</button>
								</div>
								<div class="col-6">
										<div class="pull-end">
												<button
												[disabled]="(!detectSignatureStudio && !this._enrollmentEntityData?.['field_representative_signature']?.[0]?.['url']) || (!detectSignatureStudent && !this._enrollmentEntityData?.['field_signature']?.[0]?.['url'])"
												class="me-1"
												type="button"
												mat-stroked-button
												(click)="onSubmitSignatures('save_and_add')"
												color="primary">
														Save and Add
												</button>
										</div>
								</div>
						</div>

						<div class="mt-3 d-none">
							<!-- TODO: Display this only when there is a stepper. -->
							<!-- <button class="me-1" mat-button matStepperPrevious>Back</button> -->
							<!-- <button mat-button matStepperNext>Next</button> -->
						</div>
					</form>

		</div>

</div>
