<h1 class="page-header">Inquiry Report</h1>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Filter
    </mat-panel-title>
    <mat-panel-description>
      <app-filter-results-panel [parameters]=queryParams></app-filter-results-panel>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
    <div class="row">

      <!-- Date Range Filter -->
      <div class="col-3">
        <mat-form-field>
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input name="start_date" (dateChange)="onSubmit(f)" ngModel matStartDate placeholder="Start date">
            <input name="end_date" (dateChange)="onSubmit(f)" ngModel matEndDate placeholder="End date">
          </mat-date-range-input>
          <!-- <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>

      <!-- Enrollment Autocomplete -->
      <!-- <div class="col-4">
        <mat-form-field class="full-width-field">
          <mat-label>Enrollment</mat-label>
          <input name="field_enrollment_name" (input)="onEnrollmentAutocomplete($event)" matInput ngModel
            [matAutocomplete]="autoCompleteEnrollment">
          <button type="button" matSuffix mat-icon-button aria-label="manage_accounts">
            <mat-icon>manage_accounts</mat-icon>
          </button>
        </mat-form-field>
        <mat-autocomplete #autoCompleteEnrollment="matAutocomplete" (optionSelected)="onSubmit(f)">
          <mat-option *ngFor="let option of autoCompleteEnrollmentOptions" [value]="option.label + ' ' + '(' + option.value + ')'">
            {{option.label}} ({{option.value}})
          </mat-option>
        </mat-autocomplete>
      </div> -->


      <!-- <div class="col-4">
          <mat-form-field class="full-width-field">
            <mat-label>Select Department...</mat-label>
            <mat-select
              name="field_department_name"
              ngModel
              (selectionChange)="onSubmit(f)">
              <mat-option value="all">All</mat-option>
              <mat-option
              *ngFor="let department of departmentData"
              [value]="department.key">{{ department.value }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->

      <section class="section">
        <div class="float-end bring-inline">
          <button (click)="resetForm()" color="primary" mat-flat-button class="me-1" mat-button>Clear
            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
          </button>
        </div>

      </section>

    </div>

    <section class="section">
    </section>

  </form>

</mat-expansion-panel>

<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
<div class="row">
  <div class="col-md-12 mt-3">
    <div class="float-end">
      <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
      <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
    </div>
  </div>
</div>

<div class="table-container">

  <table mat-table #displayTable [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="mt-4">

    <!-- inquiry_taker_first_name Column -->
    <ng-container matColumnDef="inquiry_taker_first_name">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by inquiry_taker_first_name">
        Inquiry Taker
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.inquiry_taker_first_name }} {{ element.inquiry_taker_last_name }} </td>
    </ng-container>

    <!-- student_account_title Column -->
    <ng-container matColumnDef="student_account_title">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by student_account_title">
        Name
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.student_account_title }} </td>
    </ng-container>

    <!-- interests Column -->
    <ng-container matColumnDef="interests">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by interests">
        Interests
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.interests }} </td>
    </ng-container>

    <!-- contact_date Column -->
    <ng-container matColumnDef="contact_date">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by contact_date">
        Contact Date
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.contact_date | date }} </td>
    </ng-container>

    <!-- field_booked_on_value Column -->
    <ng-container matColumnDef="field_booked_on_value">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_booked_on_value">
        Date Booked
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_booked_on_value | date }} </td>
    </ng-container>

    <!-- field_showed_value Column -->
    <ng-container matColumnDef="field_showed_value">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by field_showed_value">
        Date Showed
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_showed_value | date }} </td>
    </ng-container>

    <!-- outcome Column -->
    <ng-container matColumnDef="outcome">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by outcome">
        Outcome
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.outcome }} </td>
    </ng-container>

    <!-- phone Column -->
    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by phone">
        Phone
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_cell_phone_value }} </td>
    </ng-container>

    <!-- email Column -->
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by email">
        email
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.field_email_value }} </td>
    </ng-container>

    <!-- REPLACE Column -->
    <!-- <ng-container matColumnDef="REPLACE">
      <th mat-header-cell *matHeaderCellDef sortActionDescription="Sort by REPLACE">
        REPLACE
      </th>
      <td mat-cell *matCellDef="let element"> {{ element.REPLACE }} </td>
    </ng-container> -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator (page)="pageChanged($event)" [pageSizeOptions]="onPageSizeOptions()" [length]="30"
    [pageSize]="paginationTotalPages" [pageIndex]="paginationIndex" aria-label="Select page of view"></mat-paginator>

</div>
