<app-entity-mat-dialog-title title="Student Contact"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

        <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate">
        </mat-progress-spinner>

        <mat-tab-group [(selectedIndex)]="this.selectedIndex" (selectedTabChange)="setFormMode($event.index)" animationDuration="0ms" #tabs>

            <mat-tab label="View" [disabled]="this.initActionType == 'create'">
                <app-entity-mat-tab-view [entityData]="entityData"></app-entity-mat-tab-view>
            </mat-tab>

            <mat-tab label="{{(this.initActionType == 'create' ? 'Create' : 'Edit')}}">
                <app-error-message [message]=this.errorMessage></app-error-message>

                <!-- Form -->
                <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" >

                        <div class="container">

                            <div class="row">


                                      <div class="col-6">
                                          <mat-form-field class="full-width-field">
                                              <mat-label>First Name</mat-label>
                                              <input
                                                  name="field_first_name"
                                                  required
                                                  matInput
                                                  ngModel>
                                          </mat-form-field>
                                      </div>

                                      <div class="col-6">
                                          <mat-form-field class="full-width-field">
                                              <mat-label>Last Name</mat-label>
                                              <input
                                                  name="field_last_name"
                                                  required
                                                  matInput
                                                  ngModel>
                                          </mat-form-field>
                                      </div>

                                      <!-- Address  -->
                                      <!-- TODO: Dislay hidden. Need to get addresses working and integrated with th eckEntityWrapper API. -->
                                      <div
                                      class="row d-none"
                                      ngModelGroup="field_address"
                                      #contact="ngModelGroup">
                                          <div class="col-6">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Company</mat-label>
                                                  <input
                                                      name="organization"
                                                      matInput
                                                      ngModel>
                                              </mat-form-field>
                                          </div>

                                          <div class="col-12">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Street Address</mat-label>
                                                  <input ngModel name="address_line1" matInput>
                                              </mat-form-field>
                                          </div>

                                          <!-- <div class="col-12">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Street Address</mat-label>
                                                  <input ngModel name="field_address[address][address_line2]" matInput>
                                              </mat-form-field>
                                          </div> -->

                                          <div class="col-5">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>City</mat-label>
                                                  <input ngModel name="locality" matInput>
                                              </mat-form-field>
                                          </div>
                                          <div class="col-5">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>State</mat-label>
                                                  <input ngModel name="administrative_area" matInput>
                                              </mat-form-field>
                                          </div>
                                          <div class="col-2">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Zip</mat-label>
                                                  <input ngModel name="postal_code" matInput>
                                              </mat-form-field>
                                          </div>
                                      </div>

                                      <div class="row">

                                          <div class="col-3">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Home Phone</mat-label>
                                                  <input ngModel name="field_home_phone" matInput>
                                              </mat-form-field>
                                          </div>

                                          <div class="col-3">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Cell Phone</mat-label>
                                                  <input ngModel name="field_cell_phone" matInput>
                                              </mat-form-field>
                                          </div>

                                          <div class="col-6">
                                              <mat-form-field class="full-width-field">
                                                  <mat-label>Email Address</mat-label>
                                                  <input required ngModel name="field_email" matInput>
                                              </mat-form-field>
                                          </div>

                                          <!-- TODO: Add support for this field though the API -->
                                          <!-- <div class="col-12 mt-3">
                                              <mat-label>Lesson Reminders:</mat-label>
                                              <mat-checkbox
                                                  ngModel
                                                  name="field_notification_preference[call]"
                                                  value="call"
                                                  class="">Phone Call</mat-checkbox>
                                              <mat-checkbox
                                                  ngModel
                                                  name="field_notification_preference[SMS]"
                                                  value="SMS"
                                                  class="">Text</mat-checkbox>
                                              <mat-checkbox
                                                  ngModel
                                                  name="field_notification_preference[Email]"
                                                  value="Email"
                                                  class="">Email</mat-checkbox>
                                              <mat-checkbox
                                                  ngModel
                                                  name="field_notification_preference[Both]"
                                                  value="Both"
                                                  class="">Both</mat-checkbox>
                                          </div> -->

                                          <div class="col-12 mt-3">
                                              <div class="row">
                                                  <mat-label>Attending Lessons</mat-label>
                                              </div>
                                              <mat-radio-group name="field_attending_lessons" ngModel aria-label="Attending Lessons">
                                                  <mat-radio-button value="solo">Solo</mat-radio-button>
                                                  <mat-radio-button value="partner">With Partner</mat-radio-button>
                                              </mat-radio-group>
                                          </div>
                                          <div class="col-12 mt-3">
                                              <div class="row">
                                                  <mat-label>Gender</mat-label>
                                              </div>
                                              <mat-radio-group name="field_gender" ngModel aria-label="Gender">
                                                  <mat-radio-button value="1">Male</mat-radio-button>
                                                  <mat-radio-button value="0">Female</mat-radio-button>
                                              </mat-radio-group>
                                          </div>
                                      </div>
                            </div>

                        </div>
                </form>
            </mat-tab>

            <mat-tab label="Delete" [disabled]="this.initActionType == 'create'" *ngIf="this.actionType != 'create'">
                <app-entity-confirm-delete></app-entity-confirm-delete>
            </mat-tab>

        </mat-tab-group>

    </div>

</div>

<mat-dialog-actions align="end">
    <ng-container *ngIf="this.actionType === 'create'">
        <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'edit'">
        <ng-container *ngIf="this.initActionType == 'edit'">
            <button type="button" (click)="setFormMode('delete')" mat-raised-button color="accent">Delete</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
        <ng-container *ngIf="this.initActionType == 'create'">
            <button type="button" (click)="closeDialog()" mat-raised-button color="accent">Cancel</button>
            <button type="button" (click)="onSubmit(f);" mat-raised-button color="accent">Save</button>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="this.actionType === 'delete'">
        <button (click)="setFormMode('edit')" type="submit" mat-raised-button color="accent">Cancel</button>
        <!-- <button (click)="onDeleteSubmit(this._injectedDialogData['data']['EntityID'])" type="submit" mat-raised-button
            color="accent">Delete</button> -->
    </ng-container>
</mat-dialog-actions>

