import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import moment from 'moment';
import { BaseDialogComponent } from '../base-dialog/base-dialog.component';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-student-department',
  templateUrl: './student-department.component.html',
  styleUrls: ['./student-department.component.css']
})
export class StudentDepartmentComponent extends BaseSimpleDialogComponent {

  override ngAfterContentInit(): void {
    console.log('this._injectedDialogData["data"]');
    console.log(this._injectedDialogData['data']);
  }

  override onSubmit(form: NgForm) {
    this.displayProgressSpinner(true);

    let values = form.value;

    // Build request...
    let params: { parameter: any, value: any }[] = [];

    console.log('!!!values')
    console.log(values)

    // update-student-department endpoint params.
    params = [
      { parameter: 'id', value: this._injectedDialogData['data']['student_id'] }, // student id
      // { parameter: 'balance', value: values['balance'] }, // balance
      { parameter: 'student-department', value: values['student-department'] }, // student-department
    ];

    // console.log('params')
    // console.log(params)

    this._drupalRESTService.httpGET('/api/v1/update-student-department', params)
      .subscribe(data => {
        this.displayProgressSpinner(false);
        console.log(data)

        // TODO: Get proper messages for these API calls.
        if (data['success']) {
          this.closeDialog();
        }
        // if (data['success'] == false && data['message']) {
        // this.errorMessage = data['message'];
        // }
      },
        error => {
          this.displayProgressSpinner(false);
          this.errorMessage = "There was a problem sending this report.";
        });

  }

}
