<div style="display: block; width: 100%; height: 50px;"></div>
<div style="display: block; width: 100%; height: 50px; margin-bottom: 20px; margin-top: -60px;"
  class="menu-toolbar-mat">
  <div style="display: flex; flex-direction: row; align-items: center; padding: 20px;">
    <h4 class="logoText" style="margin-right: 30px;">Arthur Murray International</h4>
    <p class="menu-item" style="margin-right: 20px;">Menu item 1</p>
    <p class="menu-item" style="margin-right: 20px;">Menu item 2</p>
    <p class="menu-item" style="margin-right: 20px;">Menu item 3</p>
  </div>
</div>
<div style="display:block; width: 100%; height: 50px; margin-bottom: 50px;">
  <div style="display: flex; flex-direction: row; align-items: center; font-size: 12px;">
    <div style="display: block; width: 5.5%; height: 40px;" class="logo-text-color">logo-text-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="save-button-color">save-button-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="edit-button-color">edit-button-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="raised-button-color">raised-button-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="cancel-button-color">cancel-button-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="bright-accent">bright-accent</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="warning-color">warning-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="table-header-background">table-header-background</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="table-background-color">table-background-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="toolbar-background">toolbar-background</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="toolbar-text-color">toolbar-text-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="card-background-color">card-background-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="menu-item-color">menu-item-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="expansion-background-color">expansion-background-color</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="form-field-background">form-field-background</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="card-button-button1">card-button-button1</div>
    <div style="display: block; width: 5.5%; height: 40px;" class="card-button-button2">card-button-button2</div>
  </div>
</div>
<h1 class="page-header">This is a Page Header</h1>

<div style="margin: auto; max-width: 1280px; margin-top: 40px;">
  <h1>Text Input Styles</h1>
  <form class="example-form" style="width: 620px; display: inline-block;">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Number</mat-label>
      <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
        [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>
  <form class="example-form" style="width: 620px; display: inline-block; margin-left: 40px">
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Number</mat-label>
      <input type="text" placeholder="Pick one" aria-label="Number" matInput [formControl]="myControl"
        [matAutocomplete]="auto">
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </form>


  <!-- <p>
    <span matBadge="4" matBadgeOverlap="false">Text with a badge</span>
  </p>

  <p>
      <span matBadge="1" matBadgeSize="large">Text with large badge</span>
  </p> -->

  <!-- <p>
    Button with a badge on the left
    <button mat-raised-button color="primary"
        matBadge="8" matBadgePosition="before" matBadgeColor="accent">
      Action
    </button>
  </p>

  <p>
      Button toggles badge visibility
      <button mat-raised-button matBadge="7" [hidden]="hidden" (click)="toggleBadgeVisibility()">
          Hide
      </button>
  </p> -->

  <!-- <p>
    Icon with a badge
    <mat-icon matBadge="15" matBadgeColor="warn">home</mat-icon> -->
  <!-- Include text description of the icon's meaning for screen-readers -->
  <!-- <span class="cdk-visually-hidden">
        Example with a home icon with overlaid badge showing the number 15
      </span>
  </p> -->

  <h1 style="margin-top: 30px;">Button Styles</h1>
  <section>
    <div class="example-label">Basic</div>
    <div class="example-button-row" style="margin-bottom: 20px;">
      <button mat-button style="margin-right: 10px">Basic</button>
      <button mat-button color="primary" style="margin-right: 10px">Primary</button>
      <button mat-button color="accent" style="margin-right: 10px">Accent</button>
      <button mat-button color="warn" style="margin-right: 10px">Warn</button>
      <button mat-button color="cancel" style="margin-right: 10px">Cancel</button>
      <button mat-button disabled style="margin-right: 10px">Disabled</button>
      <!-- disabled class just adds an overlay to whatever button class -->
      <button class="mat-accent" mat-button disabled>Disabled</button>
      <a mat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Raised</div>
    <div class="example-button-row" style="margin-bottom: 20px;">
      <button mat-raised-button style="margin-right: 10px">Basic</button>
      <button mat-raised-button color="primary" style="margin-right: 10px">Primary</button>
      <button mat-raised-button color="accent" style="margin-right: 10px">Accent</button>
      <button mat-raised-button color="warn" style="margin-right: 10px">Warn</button>
      <button mat-raised-button disabled style="margin-right: 10px">Disabled</button>
      <a mat-raised-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Stroked</div>
    <div class="example-button-row" style="margin-bottom: 20px;">
      <button mat-stroked-button style="margin-right: 10px">Basic</button>
      <button mat-stroked-button color="primary" style="margin-right: 10px">Primary</button>
      <button mat-stroked-button color="accent" style="margin-right: 10px">Accent</button>
      <button mat-stroked-button color="warn" style="margin-right: 10px">Warn</button>
      <button mat-stroked-button disabled style="margin-right: 10px">Disabled</button>
      <a mat-stroked-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Flat</div>
    <div class="example-button-row" style="margin-bottom: 20px;">
      <button mat-flat-button style="margin-right: 10px">Basic</button>
      <button mat-flat-button color="primary" style="margin-right: 10px">Primary</button>
      <button mat-flat-button color="accent" style="margin-right: 10px">Accent</button>
      <button mat-flat-button color="warn" style="margin-right: 10px">Warn</button>
      <button mat-flat-button disabled style="margin-right: 10px">Disabled</button>
      <a mat-flat-button href="https://www.google.com/" target="_blank">Link</a>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Icon</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
          style="margin-right: 10px">
          <mat-icon>more_vert</mat-icon>
        </button>
        <button mat-icon-button color="primary" aria-label="Example icon button with a home icon"
          style="margin-right: 10px">
          <mat-icon>home</mat-icon>
        </button>
        <button mat-icon-button color="accent" aria-label="Example icon button with a menu icon"
          style="margin-right: 10px">
          <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button color="warn" aria-label="Example icon button with a heart icon"
          style="margin-right: 10px">
          <mat-icon>favorite</mat-icon>
        </button>
        <button mat-icon-button disabled aria-label="Example icon button with a open in new tab icon"
          style="margin-right: 10px">
          <mat-icon>open_in_new</mat-icon>
        </button>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-fab color="primary" aria-label="Example icon button with a delete icon">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-fab color="accent" aria-label="Example icon button with a bookmark icon">
            <mat-icon>bookmark</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-fab color="warn" aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-fab disabled aria-label="Example icon button with a heart icon">
            <mat-icon>favorite</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>
  <mat-divider></mat-divider>
  <section>
    <div class="example-label">Mini FAB</div>
    <div class="example-button-row">
      <div class="example-flex-container">
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
            <mat-icon>menu</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-mini-fab color="accent" aria-label="Example icon button with a plus one icon">
            <mat-icon>plus_one</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-mini-fab color="warn" aria-label="Example icon button with a filter list icon">
            <mat-icon>filter_list</mat-icon>
          </button>
        </div>
        <div class="example-button-container" style="display:inline-block; margin-right: 10px">
          <button mat-mini-fab disabled aria-label="Example icon button with a home icon">
            <mat-icon>home</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </section>


  <mat-card class="example-card">
    <mat-card-subtitle>Dog Breed</mat-card-subtitle>
    <mat-card-title>Shiba Inu</mat-card-title>
    <mat-card-content>
      <p>This card has divider and indeterminate progress as footer</p>
      <p>{{ longText }}</p>
    </mat-card-content>
    <mat-divider inset></mat-divider>
    <mat-card-actions>
      <button mat-button class="mat-button-wrapper">LIKE</button>
      <button mat-button>SHARE</button>
    </mat-card-actions>
    <mat-card-footer>
      <!-- <mat-progress-bar mode="indeterminate"></mat-progress-bar> -->
    </mat-card-footer>
  </mat-card>



  <mat-card class="example-card">
    <mat-card-content>
      <h2 class="example-h2">Checkbox configuration</h2>

      <section class="example-section">
        <mat-checkbox class="example-margin" [(ngModel)]="checked">Checked</mat-checkbox>
        <mat-checkbox class="example-margin" [(ngModel)]="indeterminate">Indeterminate</mat-checkbox>
      </section>

      <section class="example-section">
        <label class="example-margin">Align:</label>
        <mat-radio-group [(ngModel)]="labelPosition">
          <mat-radio-button class="example-margin" value="after">After</mat-radio-button>
          <mat-radio-button class="example-margin" value="before">Before</mat-radio-button>
        </mat-radio-group>
      </section>

      <section class="example-section">
        <mat-checkbox class="example-margin" [(ngModel)]="disabled">Disabled</mat-checkbox>
      </section>
    </mat-card-content>
  </mat-card>

  <div style="display: flex; align-items: center; justify-content: space-between; margin-top: 30px;">
    <mat-card class="result example-card" style="width: 60%;">
      <mat-card-content>
        <h2 class="example-h2">Result</h2>

        <section class="example-section">
          <mat-checkbox class="example-margin" [(ngModel)]="checked" [(indeterminate)]="indeterminate"
            [labelPosition]="labelPosition" [disabled]="disabled">
            I'm a checkbox
          </mat-checkbox>
        </section>
      </mat-card-content>
    </mat-card>

    <div style="display: block; width: 37%; align-self: stretch;" class="callout-section"></div>
  </div>

  <div class="example-action-buttons" style="margin-top:20px;">
    <button mat-button (click)="accordion.openAll()" class="mat-expand">Expand All</button>
    <button mat-button (click)="accordion.closeAll()" class="mat-collapse">Collapse All</button>
  </div>
  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Personal data
        </mat-panel-title>
        <mat-panel-description>
          Type your name and age
          <mat-icon>account_circle</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field appearance="fill">
        <mat-label>First name</mat-label>
        <input matInput>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Age</mat-label>
        <input matInput type="number" min="1">
      </mat-form-field>

    </mat-expansion-panel>
    <mat-expansion-panel disabled>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Destination
        </mat-panel-title>
        <mat-panel-description>
          Type the country name
          <mat-icon>map</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field appearance="fill">
        <mat-label>Country</mat-label>
        <input matInput>
      </mat-form-field>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          Day of the trip
        </mat-panel-title>
        <mat-panel-description>
          Inform the date you wish to travel
          <mat-icon>date_range</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <mat-form-field appearance="fill">
        <mat-label>Date</mat-label>
        <input matInput [matDatepicker]="picker" (focus)="picker.open()" readonly>
      </mat-form-field>
      <mat-datepicker #picker></mat-datepicker>
    </mat-expansion-panel>
  </mat-accordion>




  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" style="margin-top: 50px;">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef> Weight </th>
      <td mat-cell *matCellDef="let element"> {{element.weight}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="symbol">
      <th mat-header-cell *matHeaderCellDef> Symbol </th>
      <td mat-cell *matCellDef="let element"> {{element.symbol}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
