import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './authenticate/forgot-password/forgot-password.component';
import { LoginComponent } from './authenticate/login/login.component';
import { LogoutComponent } from './authenticate/logout/logout.component';
import { WeekViewComponent } from './components/appointments/week-view/week-view.component';
import { AuthenticateComponent } from './components/authenticate/authenticate.component';

import { DashboardComponent } from './components/dashboard/dashboard.component'
import { DayViewComponent } from './components/day-view/day-view.component';
import { StudentAccountEntityComponent } from './components/forms/student-account-entity/student-account-entity.component';
import { MatHtmlComponent } from './components/mat-html/mat-html.component';
import { AmiReportsComponent } from './components/reports/ami-reports/ami-reports.component';
import { AmiStaffPerformanceComponent } from './components/reports/ami-staff-performance/ami-staff-performance.component';
import { CanceledEventsComponent } from './components/reports/canceled-events/canceled-events.component';
import { ChatSuccessRateComponent } from './components/reports/chat-success-rate/chat-success-rate.component';
import { EnrollmentsViewComponent } from './components/reports/enrollments-view/enrollments-view.component';
import { InquiryViewComponent } from './components/reports/inquiry-view/inquiry-view.component';
import { LessonsListReportComponent } from './components/reports/lessons-list-report/lessons-list-report.component';
import { PackageListComponent } from './components/reports/package-list/package-list.component';
import { PaymentsReportViewComponent } from './components/reports/payments-report-view/payments-report-view.component';
import { ProjectionsReportComponent } from './components/reports/projections-report/projections-report.component';
import { RoyaltyReportComponent } from './components/reports/royalty-report/royalty-report.component';
import { StudentsInventoryComponent } from './components/reports/students-inventory/students-inventory.component';
import { StudioBusinessSummaryComponent } from './components/reports/studio-business-summary/studio-business-summary.component';
import { StudentDashboardComponent } from './components/student/student-dashboard/student-dashboard.component';
import { StudentsListComponent } from './components/student/students-list/students-list.component';
import { CashReportComponent } from './components/reports/cash-report/cash-report.component';
import { PaymentsDueComponent } from './components/reports/payments-due/payments-due.component';
import { PaymentsPastDueComponent } from './components/reports/payments-past-due/payments-past-due.component';
import { PaymentsMadeEncompassingComponent } from './components/reports/payments-made-encompassing/payments-made-encompassing.component';
import { PaymentsMadeLessonComponent } from './components/reports/payments-made-lesson/payments-made-lesson.component';
import { PaymentsMadeMiscComponent } from './components/reports/payments-made-misc/payments-made-misc.component';
import { PaymentsMadeSundryComponent } from './components/reports/payments-made-sundry/payments-made-sundry.component';
import { InquiriesComponent } from './components/reports/inquiries/inquiries.component';
import { CancellationsComponent } from './components/reports/cancellations/cancellations.component';
import { ServiceSalesComponent } from './components/reports/service-sales/service-sales.component';
import { CounselorsComponent } from './components/reports/counselors/counselors.component';
import { SupervisorsComponent } from './components/reports/supervisors/supervisors.component';
import { AppointmentSheetComponent } from './components/reports/appointment-sheet/appointment-sheet.component';
import { PackageListRetiredComponent } from './components/reports/package-list-retired/package-list-retired.component';
import { AmiMiscReportComponent } from './components/reports/ami-misc-report/ami-misc-report.component';
import { StudentListArchivedComponent } from './components/student/student-list-archived/student-list-archived.component';
import { SplashComponent } from './components/splash/splash.component';
import { ConfigurationComponent } from './components/configuration/configuration.component';
import { EnrollmentTrackingComponent } from './components/reports/enrollment-tracking/enrollment-tracking.component';

const routes: Routes = [
  { path: '', redirectTo: '/splash', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent},
  { path: 'splash', component: SplashComponent},
  { path: 'configuration', component: ConfigurationComponent},

  // Authenticate
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/logout', component: LogoutComponent },
  { path: 'auth/register', component: AuthenticateComponent },
  { path: 'auth/forgot-password', component: ForgotPasswordComponent },

  // Appointments
  { path: 'day-view', component: DayViewComponent },
  { path: 'week-view', component: WeekViewComponent },

  // Reports
  { path: 'inquiry', component: InquiryViewComponent },
  { path: 'projections-report', component: ProjectionsReportComponent },
  { path: 'canceled-views', component: CanceledEventsComponent },
  { path: 'students-inventory', component: StudentsInventoryComponent },
  { path: 'chat-success-rate', component: ChatSuccessRateComponent },
  // { path: 'reports/cash_report', component: CashReportComponent },
  { path: 'lessons-list', component: LessonsListReportComponent },
  { path: 'ami-staff-performance', component: AmiStaffPerformanceComponent },
  { path: 'studio-business-summary', component: StudioBusinessSummaryComponent },
  { path: 'royalty-report', component: RoyaltyReportComponent },
  { path: 'miscellaneous-report', component: AmiMiscReportComponent },

  { path: 'reports/cash-report', component: CashReportComponent },
  { path: 'reports/payments_due', component: PaymentsDueComponent },
  { path: 'reports/payments_past_due', component: PaymentsPastDueComponent },
  { path: 'reports/payments_made_encompassing', component: PaymentsMadeEncompassingComponent },
  { path: 'reports/payments_made_lesson', component: PaymentsMadeLessonComponent },
  { path: 'reports/payments_made_misc', component: PaymentsMadeMiscComponent },
  { path: 'reports/payments_made_sundry', component: PaymentsMadeSundryComponent },

  { path: 'reports/enrollment-tracking', component: EnrollmentTrackingComponent },
  { path: 'reports/inquiries', component: InquiriesComponent },
  { path: 'reports/cancellations', component: CancellationsComponent },
  { path: 'reports/service-sales', component: ServiceSalesComponent },
  { path: 'reports/counselors', component: CounselorsComponent },
  { path: 'reports/supervisors', component: SupervisorsComponent },
  { path: 'reports/appointment-sheet', component: AppointmentSheetComponent },

  { path: 'ami-reports', component: AmiReportsComponent },

  // Entities (More Reports)
  { path: 'packages-list', component: PackageListComponent },
  { path: 'packages-list-retired', component: PackageListRetiredComponent },
  { path: 'enrollment', component: EnrollmentsViewComponent },
  { path: 'payment-reports', component: PaymentsReportViewComponent },

  // Student Accounts, Dashboards
  { path: 'admin/content/student_accounts/add/student_account', component: StudentAccountEntityComponent},
  { path: 'students-list', component: StudentsListComponent},
  { path: 'students-list-retired', component: StudentListArchivedComponent},
  { path: 'students/:id', component: StudentDashboardComponent},

  // Dev for andy
  { path: 'andy', component: MatHtmlComponent},

  // Important: make sure this route is at the end.
  { path: '**', redirectTo: 'splash'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
