<div style="height:64px;" class="page-empty-header"></div>

<div class="container-fluid">
	<div class="row">
		<div style="background:#215DAB; height: calc(100vh - 64px);" class="col-3">
			<app-configuration-dashboard-menu></app-configuration-dashboard-menu>
		</div>

		<div class="col-9" style="padding-left: 2.5rem;padding-right: 2.5rem;padding-top:2.5rem;">
			<h2>Calendar Settings</h2>
			<p></p>

			<mat-card>
				<mat-card-header>
					<mat-card-title>Actions Buttons</mat-card-title>
					<mat-card-subtitle>Start</mat-card-subtitle>
				</mat-card-header>
				<mat-card-actions>
					<button mat-button>LIKE</button>
					<button mat-button>SHARE</button>
				</mat-card-actions>
			</mat-card>
			<br>
			<mat-card>
				<mat-card-header>
					<mat-card-title>Actions Buttons</mat-card-title>
					<mat-card-subtitle>End</mat-card-subtitle>
				</mat-card-header>
				<mat-card-actions align="end">
					<button mat-button>LIKE</button>
					<button mat-button>SHARE</button>
				</mat-card-actions>
			</mat-card>


		</div>
	</div>

</div>
