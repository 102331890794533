<!-- Filter results by &nbsp;
<ng-container *ngFor="let param of parameters">
    <span *ngIf="param.value !== '' &&
        param.value !== '' &&
        param.value !== null &&
        param.value !== undefined &&
        param.parameter !== 'page'">{{ param.parameter + ' = ' +
        param.value
        }};&nbsp;</span>
<</ng-container> -->

<mat-chip-list #chipList aria-label="Video keywords" multiple>

    <mat-chip
        *ngFor="let parameter of prettyParameters"
        (removed)="removeKeyword(parameter)">
      {{ parameter.parameter }}:
      {{ parameter.value }}
    </mat-chip>

</mat-chip-list>
