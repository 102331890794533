import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';

@Component({
  selector: 'app-update-group-lesson',
  templateUrl: './update-group-lesson.component.html',
  styleUrls: ['./update-group-lesson.component.css']
})
export class UpdateGroupLessonComponent extends BaseSimpleDialogComponent {

  displayedColumns: string[] = ['select', 'title', 'instructor', 'time', 'status'];

  override onSubmit(form: NgForm) {

    let type = 'group_lesson';
    let dateTime = new Date();

    console.log('form.form.value')
    console.log(form.form.value)
    console.log(this.selection['_selected'])

    // Build request...
    let body:any = {}
    let events = [];

    // Grab selected ID's from selection.
    for (const property in this.selection['_selected']) {
      events[property] = this.selection['_selected'][property].id
      body = {
        ...body,
        events
      }
    }

    // Add status, and other various params the endpoint is expecting.
    body = {
      ...body,
      'status_id': form.form.value.lessons_status_selectbox,
      'type': type,
      'dateTime': dateTime,
    };

    this._drupalRESTService.httpPOST('/api/v1/updateLesson', body, [{ parameter: '_format', value :'json' }])
      .subscribe(data => {
        console.log(data)
        if (data['success']) {
          this.closeDialog();
        }
        if (data['message']) {
          this.errorMessage = data['message'];
        }
      },
      error => {
        // console.log('error', error);
        this.displayProgressSpinner(false);
        // this.errorMessage = "There was a problem posting this lesson.";
        this.errorMessage = error.error.message;
      });

  }

  ngOnInit(): void {
    console.log(this._injectedDialogData['eventData'])

    this.dataSource = new MatTableDataSource(
      this.filterEventLessonType(this._injectedDialogData['eventData'], 'group_lesson')
    );
  }
}
