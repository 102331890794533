import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ViewTableComponent } from 'src/app/components/base/view-table/view-table.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import moment from 'moment';
import { MY_DATE_FORMATS } from 'src/app/components/reports/payments-report-view/payments-report-view.component';

@Component({
  selector: 'app-payment-reports',
  templateUrl: './payment-reports.component.html',
  styleUrls: ['./payment-reports.component.css'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: MY_DATE_FORMATS }
  ]
})

export class PaymentReportsComponent extends ViewTableComponent implements OnInit {

  @Input() drupal_student_id: number;
  @Input() drupal_student_name: string;
  @Input() drupal_account_id: string;

  override displayedColumns: string[] = [
    'field_student_name',
    'field_payment_id',
    // 'field_customer_name',
    'field_date_and_time',
    'field_gross_tuition',
    'field_enrollment_name',
    'field_payment_type',
    // 'field_status',
    // 'nothing',
    'field_teacher',
    'edit',
  ];

  override getData(params: { parameter: string; value: string; }[]) {
    // Show Progress Bar (Loading indicator)
    this.ShowProgressBar = true;
    this.queryParams = params;

    // Add sorting paramaters, if available.
    if (this.sortParams !== undefined) {
      this.queryParams.push(this.sortParams)
    }

    // Load the new data with the filtering parameters.
    this._utilityService.getPaymentReportStudent(
      this.drupal_student_id,
      this.queryParams
    )
      .subscribe((data) => {
        // ShowProgressBar
        this.ShowProgressBar = false;

        this.data = data;
        this.dataSource = new MatTableDataSource(data['results']);

        // Pagination
        if (data['results']) {
          this.paginator.length = data['count'];
          this.paginator.pageSize = this.pageSize;
          this.paginator.pageIndex = this.paginationIndex;
        }
      },
        error => this.handleError(error)
      )
  }



}
