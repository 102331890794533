import { Component, Input, OnInit } from '@angular/core';
import { BaseComponent } from '@fullcalendar/core';
import { SafeHtmlPipe } from 'src/app/safe-html.pipe';
import { BaseDialogComponent } from '../../forms/base-dialog/base-dialog.component';
import { BaseFormComponent } from '../../forms/base-form/base-form.component';

@Component({
  selector: 'app-error-message',
  templateUrl: './error-message.component.html',
  styleUrls: ['./error-message.component.css']
})
export class ErrorMessageComponent extends BaseFormComponent {
  @Input() message: any;
}
