<app-entity-mat-dialog-title title="Student Dashboard"></app-entity-mat-dialog-title>

<div [ngClass]="{
        'loading-shade': showProgressSpinner
     }">

    <div mat-dialog-content>

			<!-- Student Summary  -->
			<div *ngIf="studentDashboardData" id="student-summary" class="row
					justify-content-center">
					<h3 class="text-center"><b>{{ studentDashboardData['#studentName'] }}</b>
							Summary</h3>
					<div class="col-2 text-center">
							<p style="border: 12px solid #68ff94;" class="summary-hero">
									{{ studentDashboardData['#totalLessonsEnrolled'] }}
							</p>
							<div>Total Lessons Enrolled</div>
					</div>

					<div class="col-2 text-center">
							<p style="border: 12px solid #68ff94;" class="summary-hero">
									{{ studentDashboardData['#totalLessonsTaken'] }}
							</p>
							<div>Total Lessons Taken</div>
					</div>

					<div class="col-2 text-center">
							<p style="border: 12px solid #FCC761;" class="summary-hero">
									{{ studentDashboardData['#totalLessonsRemaining'] }}
							</p>
							<div>Total Lessons Remaining</div>
					</div>

					<div class="col-2 text-center">
							<p style="border: 12px solid #E5E5E1;" class="summary-hero">
									{{ studentDashboardData['#totalLessonsPaidAhead'] ? studentDashboardData['#totalLessonsPaidAhead'] : '0' }}
							</p>
							<div>Total Lessons Paid Ahead</div>
					</div>

					<div class="col-2 text-center">
							<p style="border: 12px solid #0077B7;" class="summary-hero">
									{{ studentDashboardData['#totalFutureLessons'] }}
							</p>
							<div>Total Future Lessons</div>
					</div>
			</div>

			<div class="">
					<div class="row justify-content-center">
							<div class="col-12">
									<!-- Student Basic/Inquiry Info -->
									<mat-card *ngIf="studentDashboardData" id="student-basic-info" class="">
											<mat-card-title>
													Student Information
											</mat-card-title>
											<mat-card-content>
													<table *ngIf="studentDashboardData">
															<tr>
																	<th>
																			First Name
																	</th>
																	<th>
																			Last Name
																	</th>
																	<th>
																			Cell
																	</th>
																	<th>
																			Email
																	</th>
																	<th>
																			Gender
																	</th>
																	<th>
																			<!-- TODO -->
																			<!-- Edit -->
																	</th>
															</tr>

															<tr>
																	<td>{{ studentDashboardData['#studentBasicInfo']['#rows']['0']['name'] }}</td>
																	<td>{{ studentDashboardData['#studentBasicInfo']['#rows']['0']['lastName'] }}</td>
																	<td>{{ studentDashboardData['#studentBasicInfo']['#rows']['0']['cell'] }}</td>
																	<td>{{ studentDashboardData['#studentBasicInfo']['#rows']['0']['email'] }}</td>
																	<td>{{ studentDashboardData['#studentBasicInfo']['#rows']['0']['gender'] }}</td>
																	<td>
																			<!-- Edit -->
																	</td>
															</tr>
													</table>
											</mat-card-content>
											<mat-card-actions>
													<!-- <button class="me-1" mat-raised-button (click)="this._dialogService.openDialog(InquiryComponent)" color="primary"
															mat-button>Edit User</button> -->

													<button
															class="me-1"
															mat-stroked-button
															(click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', studentAccountID)"
															mat-button>
															Edit Student Account
													</button>

													<button
															class="me-1"
															mat-stroked-button
															(click)="openEntityComponent(StudentRecordEntityComponent, 'contacts', 'student_record', 'edit', user.id)"
															mat-button>
															Edit User
													</button>

													<button
															class="me-1"
															mat-raised-button color="primary"
															(click)="openDialog(StudentDepartmentComponent, {
																	height: 'auto',
																	width: '600px',
																	data: {
																			student_id: this.user.id
																	}
													});">Department</button>

													<button
															class="me-1"
															mat-raised-button color="primary"
															(click)="openDialog(SetOpeningBalanceComponent, {
																	height: 'auto',
																	width: '600px',
																	data: {
																			student_id: this.user.id
																	}
													});">Set Opening Balance</button>

													<button
															class="me-1"
															mat-raised-button color="primary"
															(click)="openDialog(PaidLessonAuditComponent, {
																	height: 'auto',
																	width: '600px',
																	data: {
																			student_id: this.user.id
																	}
													});">Audit</button>


													<!-- <button
													class="me-1"
													mat-raised-button color="primary"
													(click)="openDialog(SendUpdateAPIComponent, {
															height: 'auto',
															width: '600px',
															data: {
																	operation: 'asd'
															}
													});">Send API</button> -->

													<!-- <button class="me-1" (click)="openDialog(LessonComponent)" color="primary" mat-button>Department</button> -->
													<!-- <button class="me-1" (click)="openDialog(LessonComponent)" color="primary" mat-button>Set Opening Balance</button> -->

											</mat-card-actions>
									</mat-card>
							</div>
					</div>

					<mat-card>
							<mat-card-title>
									Upcoming Lessons
							</mat-card-title>
							<app-student-future-lessons [drupal_student_id]="this.user.id"></app-student-future-lessons>
					</mat-card>

					<div class="row justify-content-center">

							<div class="col-12">
									<!-- Student Basic/Inquiry Info -->
									<mat-card *ngIf="studentDashboardData" class="">
											<mat-card-title>
													Inquiry Details
											</mat-card-title>
											<mat-card-content>
													<table *ngIf="studentDashboardData">
															<tr>
																	<th>Inquired</th>
																	<th>Booked On</th>
																	<th>Showed</th>
																	<th>Inquiry Method</th>
																	<th>Inquiry Taker</th>
																	<th>Notes</th>
															</tr>

															<tr>
																	<td>
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] == '') ? 'Not inquired.' : '' }}
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquired'] : '') | date }}
																	</td>
																	<td>
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] == '') ? 'Not yet booked.' : '' }}
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['bookedOn'] : '') | date }}
																	</td>
																	<td>
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] == '') ? 'Not showed yet.' : '' }}
																			{{ (studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] != '' ? studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['showed'] : '') | date }}
																	</td>
																	<td>
																			{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquiryMethod'] }}
																	</td>
																	<td>
																			{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['inquiryTaker'] }}
																	</td>
																	<td>
																			{{ studentDashboardData?.['#inquiry']?.['#rows']?.['0']?.['notes'] }}
																	</td>
															</tr>
													</table>
													<button
															class="me-1"
															mat-raised-button color="secondary"
															(click)="openEntityComponent(StudentInquiryDetailsComponent, 'student_accounts', 'student_account', 'edit', studentAccountID)"
															>
															Edit Inquiry Information
													</button>
											</mat-card-content>
									</mat-card>

							</div>
					</div>
			</div>

			<div class="container-fluid mt-4" style="min-height:600px;">
					<mat-tab-group *ngIf="studentDashboardObservable | async" animationDuration="0ms">

							<mat-tab label="Lesson List">

									<div class="">
											<div class="col-md-12 mt-3">
													<div class="float-end pb-3">
															<button class="addLesson" mat-flat-button
																	(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lessons', 'create', null, {
																			'__field_students_inline_form': {
																					'type': 'attendees',
																					'bundle': 'attendance',
																					'field_student_account': studentDashboardData['#studentName'] + ' (' + studentAccountID + ')'
																			}
																	})">
																	Add Lesson
															</button>
													</div>
											</div>
									</div>



									<table class="mb-3" mat-table matSort [dataSource]="lessonList2Rows">

										<!-- Date  -->
										<ng-container matColumnDef="date">
												<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
												<td mat-cell *matCellDef="let element">
													<span class="text-nowrap">
														{{element.date_and_time | date:'medium'}}
													</span>
												</td>
										</ng-container>

										<!-- Service  -->
										<ng-container matColumnDef="service">
												<th mat-header-cell *matHeaderCellDef>Service</th>
												<td mat-cell *matCellDef="let element">
													{{ element.service }}
												</td>
										</ng-container>

										<!-- Length/Duration  -->
										<ng-container matColumnDef="length">
												<th mat-header-cell *matHeaderCellDef>Length</th>
												<td mat-cell *matCellDef="let element">
													{{ element.duration }}
												</td>
										</ng-container>

										<!-- Teacher  -->
										<ng-container matColumnDef="teacher">
												<th mat-header-cell *matHeaderCellDef>Teacher</th>
												<td mat-cell *matCellDef="let element">
													{{ element.teacher }}
												</td>
										</ng-container>

										<!-- Contacts Present  -->
										<ng-container matColumnDef="contacts_present">
												<th mat-header-cell *matHeaderCellDef>Contacts Present</th>
												<td mat-cell *matCellDef="let element">
													{{element.contacts_present}}
												</td>
										</ng-container>

										<!-- Status  -->
										<ng-container matColumnDef="status">
												<th mat-header-cell *matHeaderCellDef>Status</th>
												<td mat-cell *matCellDef="let element">
														<!-- <span [ngClass]="{
																'amt-bg-red': element[5].style,
																'badge' : element[5].style
																}">
																{{ element[5].data }}
														</span> -->
														<span class="amt-bg-red badge">{{ element.status == '59' ? 'Showed' : '' }}</span>
												</td>
										</ng-container>

										<!-- Taken / Rem  -->
										<ng-container matColumnDef="taken / rem">
												<th mat-header-cell *matHeaderCellDef>Taken / Rem</th>
												<td mat-cell *matCellDef="let element">
														{{ element.taken }} / {{ element.remaining }}
												</td>
										</ng-container>

										<!-- Edit  -->
										<ng-container matColumnDef="edit">
												<th mat-header-cell *matHeaderCellDef>Edit</th>
												<td mat-cell *matCellDef="let element">
													<mat-icon
													class="cursor-pointer"
													*ngIf="element.bundle == 'lesson'"
													(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.id)">
														edit_square
													</mat-icon>
												</td>
										</ng-container>

										<!-- Row shown when there is no matching data. -->
										<tr class="mat-row" *matNoDataRow>
												<td class="mat-cell" colspan="4">No data matching the filter.</td>
										</tr>

										<tr mat-header-row *matHeaderRowDef="lessonListColumns"></tr>
										<tr [ngClass]="{'bg-success': row?.[1] == 'Enrollment' }" mat-row *matRowDef="let row; columns: lessonListColumns;"></tr>
								</table>



									<!-- TABLE  -->
									<table class="d-none table mb-3" mat-table matSort [dataSource]="lessonListRows">

											<!-- Date  -->
											<ng-container matColumnDef="date">
													<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
													<td mat-cell *matCellDef="let element">
															{{ element[0].data | date }}
													</td>
											</ng-container>

											<!-- Time  -->
											<ng-container matColumnDef="time">
													<th mat-header-cell *matHeaderCellDef>Time</th>
													<td mat-cell *matCellDef="let element">
															{{ element[1].data }}
													</td>
											</ng-container>

											<!-- Service  -->
											<ng-container matColumnDef="service">
													<th mat-header-cell *matHeaderCellDef>Service</th>
													<td mat-cell *matCellDef="let element">
															{{ element[2] }}
													</td>
											</ng-container>

											<!-- Length  -->
											<ng-container matColumnDef="length">
													<th mat-header-cell *matHeaderCellDef>Length</th>
													<td mat-cell *matCellDef="let element">
															{{ element[3] }}
													</td>
											</ng-container>

											<!-- Teacher  -->
											<ng-container matColumnDef="teacher">
													<th mat-header-cell *matHeaderCellDef>Teacher</th>
													<td mat-cell *matCellDef="let element">
															{{ element[4] }}
													</td>
											</ng-container>

											<!-- Contacts Present  -->
											<ng-container matColumnDef="contacts_present">
													<th mat-header-cell *matHeaderCellDef>Contacts Present</th>
													<td mat-cell *matCellDef="let element">
															<span>
																	{{ (element[1] !== 'Enrollment') ? element['contacts_present'] : '' }}
															</span>
													</td>
											</ng-container>

											<!-- Status  -->
											<ng-container matColumnDef="status">
													<th mat-header-cell *matHeaderCellDef>Status</th>
													<td mat-cell *matCellDef="let element">
															<span [ngClass]="{
																	'amt-bg-red': element[5].style,
																	'badge' : element[5].style
																	}">
																	{{ element[5].data }}
															</span>
													</td>
											</ng-container>

											<!-- Taken / Rem  -->
											<ng-container matColumnDef="taken / rem">
													<th mat-header-cell *matHeaderCellDef>Taken / Rem</th>
													<td mat-cell *matCellDef="let element">
															{{ element[6] }}
													</td>
											</ng-container>

											<!-- Edit  -->
											<ng-container matColumnDef="edit">
													<th mat-header-cell *matHeaderCellDef>Edit</th>
													<td mat-cell *matCellDef="let element">
															<button *ngIf="element[1] !== 'Enrollment'" mat-flat-button color="accent"
																	(click)="openEntityComponent(EventLessonEntityComponent, 'events', 'lesson', 'edit', element.id)">
																	Edit Lesson
															</button>
													</td>
											</ng-container>

											<!-- Row shown when there is no matching data. -->
											<tr class="mat-row" *matNoDataRow>
													<td class="mat-cell" colspan="4">No data matching the filter "{{input.value}}"</td>
											</tr>

											<tr mat-header-row *matHeaderRowDef="lessonListColumns"></tr>
											<tr [ngClass]="{'bg-success': row?.[1] == 'Enrollment' }" mat-row *matRowDef="let row; columns: lessonListColumns;"></tr>
									</table>
									<!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->

							</mat-tab>

							<mat-tab label="Lesson Enrollments">
									<ng-template matTabContent>
											<app-lesson-student-report [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'DE,Bonus/Comp Lesson,Extension,Pre-Original,Original,Renewals,Transfer In,Uncategorized'" [drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
									</ng-template>
							</mat-tab>

							<mat-tab label="Miscellaneous">
									<ng-template matTabContent>
											<!-- <app-misc-student-report [drupal_studentAccount_id]="studentAccountID"></app-misc-student-report> -->
											<app-lesson-student-report [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Miscellaneous'" [drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
									</ng-template>
							</mat-tab>

							<mat-tab label="Sundry">
									<ng-template matTabContent>
											<!-- <app-sundry-student-report [drupal_studentAccount_id]="studentAccountID"></app-sundry-student-report> -->
											<app-lesson-student-report [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Sundry'" [drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
									</ng-template>
							</mat-tab>

							<mat-tab label="Non Unit Lessons">
									<ng-template matTabContent>
											<app-non-unit-lessons [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [drupal_studentAccount_id]="studentAccountID"></app-non-unit-lessons>
									</ng-template>
							</mat-tab>

							<mat-tab label="Non Unit Enrollments">
									<ng-template matTabContent>
											<div class="mt-3">
													<strong>Master Class Enrolled:</strong> {{ studentDashboardData['#totalMasterclassEnrolled'] }} •
													<strong>Master Class Taken:</strong> {{ studentDashboardData['#totalMasterclassTaken'] }} •
													<strong>Coaching Enrolled:</strong> {{ studentDashboardData['#totalCoachingEnrolled'] }} •
													<strong>Coaching Taken:</strong> {{ studentDashboardData['#totalCoachingTaken'] }}
											</div>
											<app-lesson-student-report [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [enrollmentsToLoad]="'Non-Unit'" [drupal_studentAccount_id]="studentAccountID"></app-lesson-student-report>
									</ng-template>
							</mat-tab>

							<mat-tab label="Payments">
									<ng-template matTabContent>
											<app-payment-reports [drupal_student_name]="studentDashboardData['#studentName']" [drupal_account_id]="studentAccountID" [drupal_student_id]="this.user.id"></app-payment-reports>
									</ng-template>
							</mat-tab>

							<mat-tab label="Service List">

									<div class="">
											<div class="col-md-12 pt-3 pb-3">
													<div class="float-end">
															<button class="addService" mat-flat-button
															(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'create', null, {
																			'__field_students_inline_form': {
																					'type': 'attendees',
																					'bundle': 'attendance',
																					'field_student_account': studentDashboardData['#studentName'] + ' (' + studentAccountID + ')'
																			}
																	})">
																	Add Service
															</button>
													</div>
											</div>
									</div>

									<!-- TABLE  -->
									<table *ngIf="studentServicesRows" class="table" mat-table [dataSource]="studentServicesRows">

											<!-- Date  -->
											<ng-container matColumnDef="date">
													<th mat-header-cell *matHeaderCellDef>Date</th>
													<td mat-cell *matCellDef="let element">{{ element[0].data }}</td>
											</ng-container>

											<!-- Time  -->
											<ng-container matColumnDef="time">
													<th mat-header-cell *matHeaderCellDef>Time</th>
													<td mat-cell *matCellDef="let element">{{ element[1].data }}</td>
											</ng-container>

											<!-- Service  -->
											<ng-container matColumnDef="service">
													<th mat-header-cell *matHeaderCellDef>Service</th>
													<td mat-cell *matCellDef="let element">{{ element[2] }}</td>
											</ng-container>

											<!-- Length  -->
											<ng-container matColumnDef="length">
													<th mat-header-cell *matHeaderCellDef>Length</th>
													<td mat-cell *matCellDef="let element">{{ element[3] }}</td>
											</ng-container>

											<!-- Teacher  -->
											<ng-container matColumnDef="teacher">
													<th mat-header-cell *matHeaderCellDef>Teacher</th>
													<td mat-cell *matCellDef="let element">{{ element[4] }}</td>
											</ng-container>

											<!-- Status  -->
											<ng-container matColumnDef="status">
													<th mat-header-cell *matHeaderCellDef>Status</th>
													<td mat-cell *matCellDef="let element"><span [ngClass]="{
																	'amt-bg-red': element[5].style,
																	'badge' : element[5].style
																	}">{{ element[5].data }}</span></td>
											</ng-container>

											<!-- Edit  -->
											<ng-container matColumnDef="edit">
													<th mat-header-cell *matHeaderCellDef>Edit</th>
													<td mat-cell *matCellDef="let element">
														<mat-icon
														class="cursor-pointer"
														(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
															edit_square
														</mat-icon>
															<!-- <button mat-flat-button color="accent"
																	(click)="openEntityComponent(EventServicesEntityComponent, 'events', 'services', 'edit', element.id)">
																	Edit Service
															</button> -->
													</td>
											</ng-container>

											<tr mat-header-row *matHeaderRowDef="studentServicesColumns"></tr>
											<tr mat-row *matRowDef="let row; columns:
													studentServicesColumns;"></tr>
									</table>

									<!-- <app-generic-table [Columns]="studentServices['#header']" [Rows]="studentServices['#rows']"></app-generic-table> -->
							</mat-tab>

					</mat-tab-group>
			</div>



		</div>
</div>
