<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>
            Filter
        </mat-panel-title>
        <mat-panel-description>
            <app-filter-results-panel [parameters]=queryParams>
            </app-filter-results-panel>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <form (ngSubmit)="onSubmit(f)" #f=ngForm class="form">
        <div class="container">
            <div class="row">

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Payment ID</mat-label>
                        <input
                            matInput
                            ngModel
                            name="field_payment_id_value"
                            (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Customer Name</mat-label>
                        <input
                            matInput
                            ngModel
                            name="field_customer_name_value"
                            (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Choose a date</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerMin"
                            name="field_date_and_time_value[min]"
                            ngModel
                            (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerMin"></mat-datepicker-toggle>
                        <mat-datepicker #pickerMin></mat-datepicker>
                    </mat-form-field>
                </div>

                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Through</mat-label>
                        <input
                            matInput
                            [matDatepicker]="pickerMax"
                            name="field_date_and_time_value[max]"
                            ngModel
                            (dateChange)="onSubmit(f)">
                        <mat-datepicker-toggle matSuffix [for]="pickerMax"></mat-datepicker-toggle>
                        <mat-datepicker #pickerMax></mat-datepicker>
                    </mat-form-field>
                </div>


                <div class="col-2">
                    <mat-form-field class="full-width-field">
                        <mat-label>Status</mat-label>
                        <mat-select
                            matInput
                            ngModel
                            name="field_status_value"
                            (blur)="onSubmit(f)"
                            (selectionChange)="onSubmit(f)">
                            <mat-option value="All" selected="selected">- Any -</mat-option>
                            <mat-option value="Paid">Paid</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <section class="section">

                    <div class="float-end bring-inline">
                        <button (click)="resetForm()" color="primary"
                            mat-flat-button
                            class="me-1" mat-button>Clear
                            <!-- <mat-icon (click)="resetForm()" aria-hidden="false" aria-label="Menu dropdown" class=""></mat-icon> -->
                        </button>
                    </div>

                </section>


            </div>
        </div>


        <section class="section">
        </section>

    </form>

</mat-expansion-panel>

<mat-progress-bar *ngIf="ShowProgressBar" mode="indeterminate"></mat-progress-bar>
<!-- <div class=""> -->
    <!-- <div class="col-md-12 mt-3"> -->
        <!-- <div class="float-end"> -->
            <!-- <button (click)="onPrint()" color="primary" mat-flat-button class="me-1" mat-button>Print</button> -->
            <!-- <button (click)="onDownloadCSV()" color="primary" mat-flat-button class="me-1 warning" mat-button>CSV</button> -->
        <!-- </div> -->
    <!-- </div> -->
<!-- </div> -->

<div class="">
    <div class="col-md-12">
        <div class="float-end pt-3 pb-3">

            <!-- <button mat-stroked-button color="primary"
                (click)="this._dialogService.openDialog(PaymentsEntityComponent, 'defaultWithData', {
                    data: {
                        EntityID: null,
                        eckType: 'events',
                        bundle: 'services',
                        action: 'create',
                        fieldsData: {
                            field_student_name: drupal_student_name + ' (' + drupal_account_id + ')'
                        } ?? '' }  })" mat-button>
                Add Payment
            </button> -->

            <button mat-flat-button class="payment"
                (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'create', null, {
                    field_student_name: drupal_student_name + ' (' + drupal_account_id + ')'
                })" mat-button>
                Add Payment
            </button>
        </div>
    </div>
</div>

<div class="table-container">

    <table mat-table #displayTable [dataSource]="dataSource"
        matSort
        (matSortChange)="sortData($event)"
        class="mt-4">

        <!-- field_student_name Column -->
        <ng-container matColumnDef="field_student_name">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Student Name">
                Student Name
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_student_name
                }} </td>
        </ng-container>

        <!-- field_payment_id Column -->
        <ng-container matColumnDef="field_payment_id">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Payment ID">
                Payment ID
            </th>
            <td mat-cell *matCellDef="let element"> {{element.field_payment_id}}
            </td>
        </ng-container>

        <!-- field_date_and_time Column -->
        <ng-container matColumnDef="field_date_and_time">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Date">
                Date
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_date_and_time | date
                }} </td>
        </ng-container>

        <!-- field_gross_tuition Column -->
        <ng-container matColumnDef="field_gross_tuition">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Payment Amount">
                Payment Amount
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_gross_tuition }}
            </td>
        </ng-container>

        <!-- field_enrollment_name Column -->
        <ng-container matColumnDef="field_enrollment_name">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Enrollment Name">
                Enrollment Name
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_enrollment_name }} </td>
        </ng-container>

        <!-- field_payment_type Column -->
        <ng-container matColumnDef="field_payment_type">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Payment Type">
                Payment Type
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_payment_type }} </td>
        </ng-container>

        <!-- field_status Column -->
        <ng-container matColumnDef="field_status">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Status">
                Status
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.field_status }} </td>
        </ng-container>

        <!-- nothing Column -->
        <ng-container matColumnDef="nothing">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Assign">
                Assign
            </th>
            <td mat-cell *matCellDef="let element"> <!--{{ element.nothing
                }} --></td>
        </ng-container>

        <!-- field_teacher Column -->
        <ng-container matColumnDef="field_teacher">
            <th mat-header-cell *matHeaderCellDef
                sortActionDescription="Sort by Teacher">
                Teacher
            </th>
            <td mat-cell *matCellDef="let element"> {{
                element.AllScheduledPaymentInstructors }} </td>
        </ng-container>

        <!-- Edit  -->
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Edit</th>
            <td mat-cell *matCellDef="let element">
                <!-- <button mat-stroked-button
                    (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', element.id)"
                    mat-button>
                    Edit
                </button> -->

                <mat-icon
                class="cursor-pointer"
                (click)="openEntityComponent(PaymentsEntityComponent, 'payments', 'payment', 'edit', element.id)">
                    edit_square
                </mat-icon>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <mat-paginator
        (page)="pageChanged($event)"
        [pageSizeOptions]="onPageSizeOptions()"
        [length]="30"
        [pageSize]="paginationTotalPages"
        [pageIndex]="paginationIndex"
        aria-label="Select page of view"></mat-paginator>

</div>
